import React, { useState, useEffect } from "react";
import "./ImportContact.scss";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import PopupConfirmation from "../reuseableComponent/popupConfirmation/PopupConfirmation";
import { FiPlus } from "react-icons/fi";
import iconAlertTriangle from '../../assets/img/icon-alert-triangle.svg';
import { FiDownload, FiEye } from "react-icons/fi";
import Pagination from '../reuseableComponent/pagination/Pagination'
import ServiceOrganization from '../../services/organization/ServiceOrganization'
import ImportContactServices from '../../services/newServices/ImportContactServices'
import { checkID, doToast, convertFileToCsv } from '../../helper/HelperGeneral';
import HelperDate from '../../helper/HelperDate'
import PopupRemove from '../reuseableComponent/popupRemove/PopupRemove';
import ButtonWithLoadingOrIcon from '../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

let ImportContacts = props => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [listPage, setListPage] = useState(20)
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [isLoadingTableUser, setIsLoadingTableUser] = useState(false)
  let [dataImportContact, setDataImportContact] = useState([])
  let [totalData, setTotalData] = useState(0)
  let [pageDisplay, setPageDisplay] = useState(1)
  let [isShowModalSaveChanges, setIsShowModalSaveChanges] = useState(false)
  let [isShowModalDiscard, setIsShowModalDiscard] = useState(false)
  let [fileInfo, setFileInfo] = useState({
    name: "",
    row: 0
  });
  let [showModalInvalid, setShowModalInvalid] = useState(false);
  let [showModalBeforeUpload, setShowModalBeforeUpload] = useState(false);
  let [isLoadingDownloadTemplate, setIsLoadingDownloadTemplate] = useState(false);
  let [isLoadingImportContact, setIsLoadingImportContact] = useState(false);
  let [tempFile, setTempFile] = useState(null);

  let toggleModalInvalid = () => {
    setShowModalInvalid(!showModalInvalid);
  }

  let toggleModalBeforeUpload = () => {
    setShowModalBeforeUpload(!showModalBeforeUpload);
  }

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getImportList(paramID);
      setListPage(20)
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getImportList = (orgID, page = false) => {
    let _retriveDataError = { ...retriveDataError };
    setIsLoadingTableUser(true);

    let data = {
      "pageNumber": page ? page : pageDisplay,
      "pageSize": 20
    }

    ImportContactServices.getList(orgID, data, (response) => {
      if (response.dataResult.error.message === "") {
        setTotalData(response.dataResult.data.totalItems);
        setDataImportContact(response.dataResult.data.imports);
        setIsLoadingTableUser(false);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        doToast(response.dataResult.error.message, 'fail');
        setIsLoadingTableUser(false);
      }
    });
  };

  useEffect(() => {
    doMount();
  }, []);

  let downloadReport = (data) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `import-contact-template-${HelperDate.formatToString(new Date(), "dd-MM-yyyy")}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  let actionDownloadReport = () => {
    setIsLoadingDownloadTemplate(true);

    ImportContactServices.downloadTemplate(orgID,
      (response) => {
        setIsLoadingDownloadTemplate(false);

        if (!response) {
          // setShowModalFailed(true);
          doToast("Download failed", "fail");
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  let importContactHandler = (e) => {
    let _file = e.target.files[0];
    let fileName = _file.name;
    let fileFormat = fileName.split(".")[fileName.split(".").length - 1];

    console.log(_file);
    console.log("filename", fileName);
    console.log("fileFormat", fileFormat);

    if (fileFormat !== "csv") {
      toggleModalInvalid();
    } else {
      convertFileToCsv(_file, {
        onSuccessConvertCsv: (result) => {
          setFileInfo({
            name: fileName,
            row: result.length - 1
          });
        }
      });

      toggleModalBeforeUpload();
      setTempFile(_file);
    }

    e.target.value = null;
  }

  let runImport = () => {
    toggleModalBeforeUpload();
    setIsLoadingImportContact(true);

    let _data = new FormData();

    _data.append("file", tempFile);

    ImportContactServices.importContact(orgID, _data, (res) => {
      let dataResult = res.dataResult;

      if (dataResult.error.code !== "") {
        setIsLoadingImportContact(false);
        doToast(dataResult.error.message, "fail");
      } else {
        doToast(dataResult.data.message, dataResult.data.success ? "" : "fail")
        if (dataResult.data.success) {
          setTimeout(() => {
            props.history.push(`/o/${orgID}/operational/import-contacts/${dataResult.data.id}/details`)
          }, 3000)
        } else {
          doToast(dataResult.data.message, "fail");
        }
      }
    })
  }

  let handleChangePage = (page) => {
    // let paramID = checkID(match);
    getImportList(orgID, page);
    setPageDisplay(page)
  }

  let toggleModalSaveChanges = () => {
    setIsShowModalSaveChanges(!isShowModalSaveChanges)
  }

  let saveChanges = () => {
    // console.log('save changes')
  }

  let toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard)
  }

  let discardProgress = () => {
    // console.log('discard progress')
  }

  let goToDetailContact = (id) => {
    history.push(`/o/${orgID}/operational/import-contacts/${id}/details`)
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="sectionWrap import-contact-main-wrapper">
        <input type="file" onChange={(e) => importContactHandler(e)} id="input-file-import-contact" />

        <PopupConfirmation
          isOpen={showModalBeforeUpload}
          toggle={toggleModalBeforeUpload}
          titleIcon={iconAlertTriangle}
          title={`Upload File “${fileInfo.name}”?`}
          description={`You are uploading “${fileInfo.name}” with total of ${fileInfo.row} row${fileInfo.row > 1 ? "s" : ""}. Are you sure you want to upload this file?`}
          className="modal-confirmation-upload-contact"
        >
          <ButtonWithLoadingOrIcon
            text="Upload"
            className="main-button-48 orange-button"
            onClickAction={runImport}
          />
        </PopupConfirmation>

        <PopupConfirmation
          isOpen={false}
          toggle={() => { }}
          title={`You have no access to Contact Segment`}
          description={`If the file you upload contains contact segment column, it will be ignored. Are you sure you want to continue?`}
          className="modal-confirmation-upload-contact"
        >
          <ButtonWithLoadingOrIcon
            text="Continue"
            className="main-button-48 orange-button"
            isLoading
            isDisabled
            loadingColor="gray"
            position="left"
          />
        </PopupConfirmation>

        <PopupConfirmation
          isOpen={showModalInvalid}
          toggle={toggleModalInvalid}
          title={`Invalid CSV Format`}
          description={`The file you choose has an invalid CSV format, check and try again or choose another file.`}
          className="modal-confirmation-upload-contact"
        >
          <ButtonWithLoadingOrIcon
            text="Choose File"
            className="main-button-48 orange-button"
            onClickAction={() => {
              let target = document.querySelector("#input-file-import-contact");

              if (target) {
                target.click();
              }

              toggleModalInvalid();
            }}
          />
        </PopupConfirmation>

        <>
          <div className="topContent import-contact-header">
            <div className="title">Import Contacts</div>

            {!retriveDataError.code &&
              <div className="topContent-button-wrapper">
                {isLoadingDownloadTemplate ?
                  <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40"
                    text="Download Template"
                    position="left"
                    isLoading
                    loadingColor="gray"
                    isDisabled
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    className="no-fill-button main-button-40"
                    text="Download Template"
                    position="left"
                    onClickAction={actionDownloadReport}
                    icon={{
                      type: "svg",
                      src: FiDownload
                    }}
                  />
                }

                {!isLoadingImportContact ?
                  <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40"
                    text="New Import"
                    position="left"
                    onClickAction={() => {
                      let target = document.querySelector("#input-file-import-contact");

                      if (target) {
                        target.click();
                      }
                    }}
                    icon={{
                      type: "svg",
                      src: FiPlus
                    }}
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40"
                    isLoading
                    isDisabled
                    loadingColor="gray"
                    text="New Import"
                    position="left"
                  />
                }
              </div>
            }
          </div>

          {
            (isLoadingTableUser || retriveDataError.code) ?
              <RetrieveDataLoading
                isLoading={isLoadingTableUser}
                errorMessage={retriveDataError.message}
              />
              :
              <React.Fragment>
                <div className="import-contact-list-table-wrapper">
                  <div className="custom-table">
                    <table className="custom-table-content table-user-contact-list">
                      <thead>
                        <tr>
                          <td className="">File Name</td>
                          <td className="">Total Rows</td>
                          <td className="">Submitted by</td>
                          <td className="">Status</td>
                          <td className="">Started Time</td>
                          <td className="">Finished Time</td>
                          <td>

                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          dataImportContact.length > 0 ?

                            dataImportContact.map(item => {
                              return (
                                <tr key={`user-contact-${item.userID}`}>
                                  <td className="col_name">
                                    <p className="">{item.filename}</p>
                                  </td>
                                  <td className="col_name">
                                    <p className="">{item.totalRows}</p>
                                  </td>
                                  <td className="col_email">
                                    <p>{item.createdByAccountName}</p>
                                  </td>
                                  <td className="col_phone">
                                    {item.status === "pending" &&
                                      <div className="yellow-background badge-text text-capitalize">
                                        {item.status}
                                      </div>
                                    }

                                    {item.status === "running" &&
                                      <div className="blue-background badge-text text-capitalize">
                                        {item.status}
                                      </div>
                                    }

                                    {item.status === "finished" &&
                                      <div className="green-background badge-text text-capitalize">
                                        {item.status}
                                      </div>
                                    }

                                    {item.status === "failed" &&
                                      <div className="red-background badge-text text-capitalize">
                                        {item.status}
                                      </div>
                                    }
                                  </td>
                                  <td className="col-origin">
                                    {item.startTime === 0 ? "-" : HelperDate.formatToString(new Date(item.startTime), "dd MMMM yyyy, HH:mm")}
                                  </td>
                                  <td className="col_created">
                                    {item.endTime === 0 ? "-" : HelperDate.formatToString(new Date(item.endTime), "dd MMMM yyyy, HH:mm")}
                                  </td>
                                  <td className="col_view">
                                    <Link
                                      to={`/o/${orgID}/operational/import-contacts/${item.id}/details`}
                                    >
                                      <FiEye />
                                      <b>View</b>
                                    </Link>
                                  </td>
                                </tr>
                              )
                            })
                            :
                            <tr>
                              <td colSpan="7" className="no-data-column"><b className="no-result-found">No imports found. Start by clicking “+New Import”.</b></td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-wrapper">
                    {
                      dataImportContact.length > 0 &&
                      <Pagination
                        total={totalData}
                        page={pageDisplay}
                        listPerPage={listPage}
                        step={1}
                        onChangePage={handleChangePage}
                      />
                    }
                  </div>
                </div>
              </React.Fragment>
          }
        </>

        <PopupRemove
          removeTitle={`This Contact Has Open Cases from WhatsApp`}
          removeDescription={`To save changes to this contact’s phone number, you must resolve all ongoing cases from WhatsApp.`}
          onClickCancel={toggleModalSaveChanges}
          onClickRemove={saveChanges}
          // waitForResponse={waitForResponseSaveChanges}
          showModalProps={isShowModalSaveChanges}
          submitText="Resolve and Save Changes"
        // colorButton="red-button"
        />
        <PopupRemove
          removeTitle={`Discard Changes?`}
          removeDescription={`You have unsaved progress on this page. Are you sure you want to discard your progress?`}
          onClickCancel={toggleModalDiscard}
          onClickRemove={discardProgress}
          // waitForResponse={waitForResponseDiscard}
          showModalProps={isShowModalDiscard}
          submitText="Resolve and Save Changes"
        // colorButton="red-button"
        />
      </div>
    </SectionWrap >
  );
};

export default ImportContacts;