import React, { useState, useEffect } from 'react';
import './IntegrationMiitel.scss';
import { Link } from 'react-router-dom';
import { FiArrowUpRight, FiPlus } from 'react-icons/fi';
import MiitelIcon from '../../../assets/img/integration/logo-miitel-side-menu.svg';
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap';
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupWarning from "../../reuseableComponent/popupWarning/PopupWarning";
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import { checkID } from '../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import IntegrationMiitelServices from '../../../services/integration/IntegrationMiitelServices';
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";

let IntegrationMiitel = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = React.useState(false);
  let [isLoadingAccount, setIsLoadingAccount] = useState(true);
  let [miitelAccountData, setMiitelAccountData] = useState([]);
  let [showPopupWarning, setShowPopupWarning] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  let toggleShowWarning = () => {
    setShowPopupWarning(!showPopupWarning);
  }

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getAccount(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getAccount = (orgID) => {
    let _retriveDataError = { ...retriveDataError };

    setIsLoadingAccount(true);

    IntegrationMiitelServices.getList(orgID, (response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.status === 200) {
        setMiitelAccountData(response.dataResult.data.channels);
      } else {
        setMiitelAccountData([]);
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingAccount(false);
    });
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (orgID) {
      if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] MiiTel Integration",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: orgID.split("-")[0]
          }
        )
      }
    }
  }, [orgID])

  let goToAdd = () => {
    if (miitelAccountData.length > 0) {
      toggleShowWarning();
    } else {
      history.push(`/o/${orgID}/integration/miitel/add`);
    }
  };

  return (
    <div className="integration-outer-wrapper">
      <PopupWarning
        noIcon
        isShow={showPopupWarning}
        toggle={toggleShowWarning}
        title="Channel Limit Reached"
        text="You can only add 1 channel for MiiTel. Please remove the current channel to add a new one."
        _className="modal-warning-limit modal-warning-limit-miitel"
      />

      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        {/* tier blocking */}
        {retriveDataError.code === "49901" &&
          <RetrieveDataLoading
            isLoading={isLoadingAccount}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
          />
        }
        {/* tier blocking */}

        <div className="sectionWrap integration-main-container integration-miitel-list text-left">
          <>
            <div className="integration-list-header">
              <img
                src={MiitelIcon}
                alt=""
                className="integration-list-header-icon"
              />

              <div className="integration-list-header-text-wrapper">
                <b>MiiTel</b>
                {/* <p>
                    To integrate with Google,
                    <a href="/"> please read the documentation.</a>
                  </p> */}
              </div>

              {(!isLoadingAccount && !retriveDataError.code) &&
                <ButtonWithLoadingOrIcon
                  icon={{
                    type: "svg",
                    src: FiPlus
                  }}
                  className="no-fill-button integration-list-header-button main-button-40"
                  text="Add Channel"
                  position="left"
                  onClickAction={goToAdd}
                />
              }
            </div>

            {isLoadingAccount || retriveDataError.code ?
              <RetrieveDataLoading
                isLoading={isLoadingAccount}
                errorMessage={retriveDataError.message}
              />
              :
              (miitelAccountData.length > 0 ?
                <div className="integration-list-outer-wrapper">
                  {miitelAccountData.map((value, index) => {
                    return (
                      <Link
                        key={`miitel-account-${index}`}
                        to={`/o/${orgID}/integration/miitel/detail/${value.id}`}
                        className="link-google-account-list"
                      >
                        <div className="integration-list-inner-wrapper">
                          <FiArrowUpRight />
                          <p>
                            <b>{value.channelName}</b>
                          </p>

                          <span>{value.stringID}</span>
                        </div>
                      </Link>
                    );
                  })}
                </div>
                :
                <div className="integration-list-no-data">
                  <b>No Channel</b>
                  <p>
                    To add a channel, simply click the "+ Add Channel" button on the top right.
                  </p>
                </div>
              )
            }
          </>
        </div>

      </SectionWrap>
    </div>
  );
};

let mapStateToProps = state => ({

});

let mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationMiitel);
