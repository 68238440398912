import React, { useState, useEffect, useLayoutEffect } from 'react';
import { NavLink } from "react-router-dom";
import './accountPanel.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FiVolume2, FiVolumeX, FiExternalLink, FiCheck, FiSearch } from "react-icons/fi";
import { connect } from 'react-redux';
import { taptalk } from '@taptalk.io/web-sdk';
import mixpanel from "mixpanel-browser";
import iconMiitel from "../../../assets/img/integration/logo-miitel-side-menu.svg";
import { setGlobalSearch } from "../../../redux/actions/reduxActionGlobalSearch";
import { setCaseListChecked } from "../../../redux/actions/reduxActionCaseListChecked";
import { doToast } from "../../../helper/HelperGeneral";
import Helper from '../../../helper/HelperChat';
import ClockService from "../../../services/chat/clockServices";

const MODAL_CLOCK_CONFIRMATION = {
    resume: 1,
    pause: 2,
    stop: 3
};

const CLOCK_STATE = {
    isOff: "off",
    isStarted: "started",
    isPaused: "paused"
}

var AccountPanel = (props) => {
    let [isSoundNotificationEnable, setIsSoundNotificationEnable] = useState(true);
    let [showPopupAccountPanel, setShowPopupAccountPanel] = useState(false);
    let [showModalConfirmationClock, setShowModalConfirmationClock] = useState(false);
    let [stateModalConfirmation, setStateModalConfirmation] = useState('');
    let [isLoadingClockStatusSubmit, setIsLoadingClockStatusSubmit] = useState(false);

    let showModalConfirmationClockAction = (state) => {
        setStateModalConfirmation(state);
        setShowModalConfirmationClock(true);
    }

    let hideModalConfirmationClockAction = () => {
        setShowModalConfirmationClock(false);
    }

    let onClickConfirmPause = () => {
        let orgID = props.match.params.orgID;

        setIsLoadingClockStatusSubmit(true);

        ClockService.postAgentClockPause(orgID, (response) => {
            setIsLoadingClockStatusSubmit(false);

            let dataResult = response.dataResult;

            if (dataResult.error.code === "") {
                if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                    mixpanel.track(
                        "[Action] Pause Timer",
                        {
                            userFullName: props.myAgentData.account.fullName,
                            userID: props.myAgentData.account.id,
                            organizationID: orgID.split("-")[0]
                        }
                    );
                }

                setShowModalConfirmationClock(false);
                props.setClockState(CLOCK_STATE.isPaused);
            } else {
                doToast(dataResult.error.message, 'fail');
            }
        })
    }

    let onClickConfirmResume = () => {
        let orgID = props.match.params.orgID;

        setIsLoadingClockStatusSubmit(true);

        ClockService.postAgentClockResume(orgID, (response) => {
            setIsLoadingClockStatusSubmit(false);

            let dataResult = response.dataResult;

            if (dataResult.error.code === "") {
                if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                    mixpanel.track(
                        "[Action] Resume Timer",
                        {
                            userFullName: props.myAgentData.account.fullName,
                            userID: props.myAgentData.account.id,
                            organizationID: orgID.split("-")[0]
                        }
                    );
                }

                setShowModalConfirmationClock(false);
                props.setClockState(CLOCK_STATE.isStarted);
            } else {
                doToast(dataResult.error.message, 'fail');
            }
        })
    }

    let onClickConfirmStop = () => {
        let orgID = props.match.params.orgID;

        setIsLoadingClockStatusSubmit(true);

        ClockService.postAgentClockOut(orgID, (response) => {
            setIsLoadingClockStatusSubmit(false);

            let dataResult = response.dataResult;

            if (dataResult.error.code === "") {
                if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                    mixpanel.track(
                        "[Action] Stop Timer",
                        {
                            userFullName: props.myAgentData.account.fullName,
                            userID: props.myAgentData.account.id,
                            organizationID: orgID.split("-")[0]
                        }
                    );
                }
                setShowModalConfirmationClock(false);
                props.setClockState(CLOCK_STATE.isOff);
                props.setActiveRoom(null);
            } else {
                doToast(dataResult.error.message, 'fail');
            }
        })
    }

    let modalConfirmationClock = () => {
        return (
            <div>
                <Modal isOpen={showModalConfirmationClock} toggle={hideModalConfirmationClockAction} className="modal-confirmation-clock">
                    <ModalBody>
                        {stateModalConfirmation === MODAL_CLOCK_CONFIRMATION.resume &&
                            <React.Fragment>
                                <b>You will be online</b>

                                <p>
                                    You will be able to reply messages and resolve all incoming cases.
                                </p>
                            </React.Fragment>
                        }

                        {stateModalConfirmation === MODAL_CLOCK_CONFIRMATION.pause &&
                            <React.Fragment>
                                <b>You will be away</b>

                                <p>
                                    You will still receive messages from customers, but might not be able to respond until you set your status as online. Are you sure you want to continue?
                                </p>
                            </React.Fragment>
                        }

                        {stateModalConfirmation === MODAL_CLOCK_CONFIRMATION.stop &&
                            <React.Fragment>
                                <b>You will be offline</b>

                                <p>
                                    You won’t be able to view your inbox and respond to your customers.
                                </p>
                            </React.Fragment>
                        }
                    </ModalBody>

                    <ModalFooter>
                        {stateModalConfirmation === MODAL_CLOCK_CONFIRMATION.resume &&
                            <React.Fragment>
                                {!isLoadingClockStatusSubmit ?
                                    <React.Fragment>
                                        <button className="no-fill-button" onClick={() => hideModalConfirmationClockAction()}><b>Cancel</b></button>
                                        <button className="green-button" onClick={() => onClickConfirmResume()}><b>Go Online</b></button>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <button className="no-fill-button"><b>Cancel</b></button>
                                        <button className="green-button" disabled>
                                            <div className="lds-ring">
                                                <div /><div /><div /><div />
                                            </div>
                                        </button>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }

                        {stateModalConfirmation === MODAL_CLOCK_CONFIRMATION.pause &&
                            <React.Fragment>
                                {!isLoadingClockStatusSubmit ?
                                    <React.Fragment>
                                        <button className="no-fill-button" onClick={() => hideModalConfirmationClockAction()}><b>Cancel</b></button>
                                        <button className="yellow-button" onClick={() => onClickConfirmPause()}><b>Away</b></button>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <button className="no-fill-button"><b>Cancel</b></button>
                                        <button className="yellow-button" disabled>
                                            <div className="lds-ring">
                                                <div /><div /><div /><div />
                                            </div>
                                        </button>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }

                        {stateModalConfirmation === MODAL_CLOCK_CONFIRMATION.stop &&
                            <React.Fragment>
                                {!isLoadingClockStatusSubmit ?
                                    <React.Fragment>
                                        <button className="no-fill-button" onClick={() => hideModalConfirmationClockAction()}><b>Cancel</b></button>
                                        <button className="red-button" onClick={() => onClickConfirmStop()}><b>Go Offline</b></button>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <button className="no-fill-button"><b>Cancel</b></button>
                                        <button className="red-button" disabled>
                                            <div className="lds-ring">
                                                <div /><div /><div /><div />
                                            </div>
                                        </button>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    let toogleSoundNotification = async () => {
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Action] Turn Sound Notification",
                {
                    sound: isSoundNotificationEnable ? "off" : "on",
                    userFullName: props.myAgentData.account.fullName,
                    userID: props.myAgentData.account.id,
                    organizationID: props.match.params.orgID.split("-")[0]
                }
            );
        }

        let myAgentID = props.myAgentData.account.id;

        let _soundNotif = JSON.parse(localStorage.getItem("OneTalk.soundNotif"));

        _soundNotif[myAgentID] = !_soundNotif[myAgentID];

        localStorage.setItem("OneTalk.soundNotif", JSON.stringify(_soundNotif));

        setIsSoundNotificationEnable(!isSoundNotificationEnable);
    }

    let toogleGlobalSearch = () => {
        let _globalSearch = { ...props.globalSearch };
        _globalSearch.show = !_globalSearch.show;
        props.setCaseListChecked({
            globalSearch: {
                isShow: false,
                data: {},
                checkedAll: false
            },
            bot: {
                isShow: false,
                data: {},
                checkedAll: false
            },
            mine: {
                isShow: false,
                data: {},
                checkedAll: false
            },
            unassigned: {
                isShow: false,
                data: {},
                checkedAll: false
            },
            other: {
                isShow: false,
                data: {},
                checkedAll: false
            },
            solved: {
                isShow: false,
                data: {},
                checkedAll: false
            },
        })
        props.setGlobalSearch(_globalSearch);
    }

    useEffect(() => {
        let myAgentID = props.myAgentData.account.id;

        if (localStorage.getItem("OneTalk.soundNotif") === null) {
            let newPreference = {
                [myAgentID]: true
            }

            localStorage.setItem("OneTalk.soundNotif", JSON.stringify(newPreference));
            setIsSoundNotificationEnable(true);
        } else {
            let _soundNotif = JSON.parse(localStorage.getItem("OneTalk.soundNotif"));

            if (_soundNotif[myAgentID] === undefined) {
                _soundNotif[myAgentID] = true;
                localStorage.setItem("OneTalk.soundNotif", JSON.stringify(_soundNotif));
                setIsSoundNotificationEnable(true);
            } else {
                setIsSoundNotificationEnable(_soundNotif[myAgentID]);
            }
        }

        let bodyClick = document.querySelector('body');

        bodyClick.addEventListener('click', function () {
            setShowPopupAccountPanel(false);
        });
    }, [])

    useLayoutEffect(() => {
        console.log(document.querySelector("#miitelPhoneIFrameOuter"))
    }, [])

    useEffect(() => {
        if (props.miitelKey.accessKey !== "") {
            //init miitel
            window.miitelWidget = window.miitelWidget || function (k, v) { window.miitelWidget.conf = window.miitelWidget.conf || {}; window.miitelWidget.conf[k] = v; }
            window.miitelWidget("company_id", props.miitelKey.companyID);
            window.miitelWidget("access_key", props.miitelKey.accessKey);
            window.miitelWidget("onReceiveCall", function (e) {
                let currentStyle = document.querySelector("#miitelPhoneIFrameOuter").style.cssText;
                
                document.querySelector("#miitelPhoneIFrameOuter").style.cssText = currentStyle + "display: block ! important";
            });

            if (!document.getElementById("api")) {
                document.body.appendChild(
                    Object.assign(document.createElement("script"), {
                        type: "text/javascript",
                        async: true,
                        src: "https://api.miitel.jp/static/widget/v1.js",
                        id: "api",
                    })
                );
            }

            let setMinimizeListener = () => {
                let elMinimize = document.querySelector("#miitelPhoneIFrameOuter");

                if (elMinimize) {
                    document.querySelector("#miitelPhoneIFrameOuter").querySelector("img").addEventListener("click", function () {
                        setTimeout(() => {
                            closeMiiTel();
                        }, 100);
                    });
                } else {
                    setTimeout(() => {
                        setMinimizeListener();
                    }, 100)
                }
            }

            setMinimizeListener();
        }
    }, [props.miitelKey])

    let onClickMiitel = () => {
        toggleMiiTel();
    }

    let toggleMiiTel = () => {
        if (window.miitelWidget.openState) {
            closeMiiTel();
        } else {
            openMiiTel();
        }
    }

    let openMiiTel = () => {
        let currentStyle = document.querySelector("#miitelPhoneIFrameOuter").style.cssText;

        document.querySelector("#miitelPhoneIFrameOuter").style.cssText = currentStyle + "display: block ! important";
        setTimeout(() => {
            window.miitelWidget.openFrame();
        }, 1);
    }

    let closeMiiTel = () => {
        window.miitelWidget.closeFrame();
        setTimeout(() => {
            let currentStyle = document.querySelector("#miitelPhoneIFrameOuter").style.cssText;

            document.querySelector("#miitelPhoneIFrameOuter").style.cssText = currentStyle + "display: none ! important";
        }, 100);
    }

    return (
        <div className="side-account-panel-wrapper">
            {modalConfirmationClock()}
            <div className="side-account-button-wrapper side-panel-account-button" onClick={() => {
                setShowPopupAccountPanel(!showPopupAccountPanel)
            }}>
                <div className="account-avatar" style={{ background: taptalk.getRandomColor(props.agentDetailProps.accountName) }}>
                    {props.agentDetailProps.imageURL.thumbnail === "" ?
                        <b>{Helper.renderUserAvatarWord(props.agentDetailProps.accountName)}</b>
                        :
                        <img className="account-avatar-image" src={props.agentDetailProps.imageURL.thumbnail} alt="" />
                    }

                    <div className="account-avatar-status-dot">
                        <div className={`account-avatar-status-dot-inside ${props.clockState === CLOCK_STATE.isOff ? 'dot-red' : props.clockState === CLOCK_STATE.isPaused ? 'dot-yellow' : 'dot-green'}`} />
                    </div>
                </div>
                <br />
                <p>
                    {/* <b>{props.agentDetailProps.accountName}</b> */}
                    <b>Account</b>
                </p>
            </div>

            <div className={`popup-account-panel ${!showPopupAccountPanel ? 'deactive-popup-account-panel' : ''}`}>
                <div className="account-panel-name-company">
                    <div className="account-avatar" style={{ background: taptalk.getRandomColor(props.agentDetailProps.accountName) }}>
                        {props.agentDetailProps.imageURL.thumbnail === "" ?
                            <b>{Helper.renderUserAvatarWord(props.agentDetailProps.accountName)}</b>
                            :
                            <img className="account-avatar-image" src={props.agentDetailProps.imageURL.thumbnail} alt="" />
                        }

                        <div className="account-avatar-status-dot">
                            <div className={`account-avatar-status-dot-inside ${props.clockState === CLOCK_STATE.isOff ? 'dot-red' : props.clockState === CLOCK_STATE.isPaused ? 'dot-yellow' : 'dot-green'}`} />
                        </div>
                    </div>

                    <div className="account-avatar-name-company-content">
                        <p>
                            <b>{props.agentDetailProps.accountName}</b>
                        </p>

                        <p>{props.currentOrganizationProps.name}</p>
                    </div>
                </div>

                <div className={`account-panel-status-bar ${props.clockState === CLOCK_STATE.isStarted ? 'active-status-bar' : ''}`} onClick={() => props.clockState !== CLOCK_STATE.isStarted && showModalConfirmationClockAction(MODAL_CLOCK_CONFIRMATION.resume)}>
                    <div className="account-avatar-status-dot">
                        <div className="account-avatar-status-dot-inside dot-green" />
                    </div>
                    <b>Online</b>

                    {props.clockState === CLOCK_STATE.isStarted && <FiCheck />}
                </div>

                <div className={`account-panel-status-bar ${props.clockState === CLOCK_STATE.isPaused ? 'active-status-bar' : ''}`} onClick={() => props.clockState !== CLOCK_STATE.isPaused && showModalConfirmationClockAction(MODAL_CLOCK_CONFIRMATION.pause)}>
                    <div className="account-avatar-status-dot">
                        <div className="account-avatar-status-dot-inside dot-yellow" />
                    </div>
                    <b>Away</b>

                    {props.clockState === CLOCK_STATE.isPaused && <FiCheck />}
                </div>

                <div className={`account-panel-status-bar ${props.clockState === CLOCK_STATE.isOff ? 'active-status-bar' : ''}`} onClick={() => props.clockState !== CLOCK_STATE.isOff && showModalConfirmationClockAction(MODAL_CLOCK_CONFIRMATION.stop)}>
                    <div className="account-avatar-status-dot">
                        <div className="account-avatar-status-dot-inside dot-red" />
                    </div>
                    <b>Offline</b>

                    {props.clockState === CLOCK_STATE.isOff && <FiCheck />}
                </div>
            </div>

            <div className="side-account-button-wrapper side-panel-account-button" onClick={toogleSoundNotification}>
                {isSoundNotificationEnable ?
                    <FiVolume2 />
                    :
                    <FiVolumeX />
                }
                <br />

                <p>
                    <b>Sound {isSoundNotificationEnable ? "ON" : "OFF"}</b>
                </p>
            </div>

            <div className="side-account-button-wrapper side-panel-account-button" onClick={toogleGlobalSearch}>
                <FiSearch />

                <br />

                <p>
                    <b>Search</b>
                </p>
            </div>

            <div className="side-account-button-wrapper side-panel-account-button" onClick={onClickMiitel}>
                <img src={iconMiitel} alt="" />

                <br />

                <p>
                    <b>MiiTel</b>
                </p>
            </div>

            <NavLink to={`/o/${props.match.params.orgID}/home`} target="_blank">
                <div className="side-account-button-wrapper button-dashboard side-panel-account-button">
                    <FiExternalLink />
                    <br />
                    <p>
                        <b>Dashboard</b>
                    </p>
                </div>
            </NavLink>
        </div>
    )
}

const mapStateToProps = state => ({
    // clockState state.clockState
    globalSearch: state.globalSearch,
    myAgentData: state.myAgentData,
    miitelKey: state.miitelKey
});

const mapDispatchToProps = {
    // setActiveRoom,
    // setClockState
    setGlobalSearch,
    setCaseListChecked
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPanel);
