// import axios from "axios";
// import getHeaderServices from "./headerServices";
import BaseService from "../BaseService";
import { API_KEY } from "../../config";

// const setUrl = `${process.env.REACT_APP_ENVIRONMENT_API}/project/secret_key/`;
// const getHeader = getHeaderServices;

const IntegrationMiitelServices = {
  create(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.CREATE, data, headers, callback);
  },
  getList(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET, {}, headers, callback);
  },
  getDetail(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DETAIL, data, headers, callback);
  },
  delete(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE, data, headers, callback);
  },
  editDetail(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_DETAIL, data, headers, callback);
  }
};

const API = {
  CREATE: "/organization/channel/miitel/create",
  GET: "/organization/channel/miitel/get_list",
  DETAIL: "/organization/channel/miitel/get_details",
  DELETE: "/organization/channel/miitel/delete",
  EDIT_DETAIL: '/organization/channel/miitel/edit',
}

export default IntegrationMiitelServices;
