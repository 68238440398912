import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetupBrandProfile.scss";
import { connect } from 'react-redux';
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { FiAlertCircle, FiInfo, FiSave } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { doToast } from "../../../../helper/HelperGeneral";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { BRAND_NAME_MAX_LENGTH, BRAND_DESCRIPTION_MAX_LENGTH, ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT } from "../IntegrationChatGPTSetup2";

const IntegrationChatGPTSetupBrandProfile = (props) => {

    const fetchOptionsAndSavedData = () => {
        setIsLoadingOptions(true);
        props.getBrandProfileData((response) => {
            let dataResult = response.dataResult;
            if (dataResult.error.message) {
                doToast(dataResult.error.message, 'fail');
            }
            setIsLoadingOptions(false);
        });
    }
 
    const onChangeBrandName = (e) => {
        let _brandProfile = { ...props.brandProfile };
        _brandProfile.brandName = e.target.value;
        props.setBrandProfile(_brandProfile);

        let _errorMessageData = { ...props.brandProfileError };
        if (_brandProfile.brandName.length > BRAND_NAME_MAX_LENGTH) {
            _errorMessageData.brandName = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
        }
        else {
            _errorMessageData.brandName = "";
        }
        props.setBrandProfileError(_errorMessageData);
    };

    const onSelectIndustry = (industry) => {
        let _brandProfile = { ...props.brandProfile };
        if (_brandProfile.industry.value === industry.value) {
            return;
        }
        _brandProfile.industry = industry;
        props.setBrandProfile(_brandProfile);

        let _errorMessageData = { ...props.brandProfileError };
        _errorMessageData.industry = "";
        props.setBrandProfileError(_errorMessageData);
    }
 
    const onChangeWebsite = (e) => {
        let _brandProfile = { ...props.brandProfile };
        _brandProfile.website = e.target.value;
        props.setBrandProfile(_brandProfile);

        let _errorMessageData = { ...props.brandProfileError };
        _errorMessageData.website = "";
        props.setBrandProfileError(_errorMessageData);
    };

    const onChangeBrandDescription = (e) => {
        let _brandProfile = { ...props.brandProfile };
        _brandProfile.brandDescription = e.target.value;
        props.setBrandProfile(_brandProfile);

        let _errorMessageData = { ...props.brandProfileError };
        // if (_brandProfile.brandDescription.length > BRAND_DESCRIPTION_MAX_LENGTH) {
        //     _errorMessageData.brandDescription = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
        // }
        // else {
            _errorMessageData.brandDescription = "";
        // }
        props.setBrandProfileError(_errorMessageData);
        
        // resizeBrandDescriptionTextArea();
    }

    const resizeBrandDescriptionTextArea = (scroll = true) => {
        const elements = document.getElementsByClassName("input-brand-description");
        if (!elements) {
            return;
        }
        const textarea = elements[0];
        if (!textarea) {
            return;
        }
        if (textarea.offsetHeight === textarea.scrollHeight) {
            return;
        }
        textarea.style.height = "1px"; // Reset height
        if (textarea.scrollHeight <= 96) {
            textarea.style.height = "96px";
        }
        else {
            textarea.style.height = "" + textarea.scrollHeight + "px";
            if (scroll) {
                textarea.scrollIntoView();
            }
        }
    }

    let [isLoadingOptions, setIsLoadingOptions] = useState(false);

    useEffect(() => {
        fetchOptionsAndSavedData();
    }, [props.match.params.orgID]);

    // useEffect(() => {
    //     setTimeout(() => {
    //         resizeBrandDescriptionTextArea(false);
    //     }, 0);
    // }, [props.brandProfile]);

    return (
        <>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
                noWrap
                dataOrigin={props.brandProfileOrigin}
                dataAfterChange={JSON.stringify(props.brandProfile)}
            >

                <p className="text-info-top">
                    <FiInfo />
                    Describe your brand with a standout profile and engage your audience.
                </p>

                <div>
                    {
                        isLoadingOptions
                        ?
                        <RetrieveDataLoading
                            isLoading={isLoadingOptions}
                            errorMessage=""
                            errorCode=""
                        />
                        :
                        <>
                            <div className="form-content-wrapper brand-name-wrapper">
                                <label>
                                    <b>Brand Name</b>
                                    <span className={`input-counter grey-font ${props.brandProfile.brandName.length > BRAND_NAME_MAX_LENGTH ? "red-text" : ""}`}>
                                        {props.brandProfile.brandName.length}/{BRAND_NAME_MAX_LENGTH} characters
                                    </span>
                                </label>
                                <input
                                    type="text"
                                    id="brandName"
                                    onChange={(e) => onChangeBrandName(e)}
                                    value={props.brandProfile.brandName}
                                    placeholder="Insert brand name"
                                    className={`input-brand-name ${props.brandProfileError.brandName ? "border-red" : ""}`}
                                />
                                {
                                    props.brandProfileError.brandName &&
                                    <ErrorOrInfoComp
                                        text={props.brandProfileError.brandName}
                                        _className={"font-red"}
                                        icon={<FiAlertCircle />}
                                    />
                                }
                            </div>

                            <div className="form-content-wrapper industry-wrapper">
                                <div>
                                    <label><b>Industry</b></label>
                                    <CustomSelectOption
                                        optionListProps={props.brandProfileOptions.industries}
                                        valueDropdownProps={props.brandProfile.industry}
                                        placeholderProps={"Select industry"}
                                        onClickDropDownListOptionProps={onSelectIndustry}
                                        isDisabled={props.isLoadingSave}
                                        _className={`dropdown-industry ${props.brandProfileError.industry ? "border-red" : ""}`}
                                    />
                                    {
                                        props.brandProfileError.industry &&
                                        <ErrorOrInfoComp
                                            text={props.brandProfileError.industry}
                                            _className={"font-red"}
                                            icon={<FiAlertCircle />}
                                        />
                                    }
                                </div>
                            </div>
                            
                            <div className="form-content-wrapper website-wrapper">
                                <label>
                                    <b>Website</b>
                                    <span className="optional"> (Optional)</span>
                                </label>
                                <input
                                    type="text"
                                    id="website"
                                    onChange={(e) => onChangeWebsite(e)}
                                    value={props.brandProfile.website}
                                    placeholder="Insert brand website"
                                    className={`input-website ${props.brandProfileError.website ? "border-red" : ""}`}
                                />
                                {
                                    props.brandProfileError.website &&
                                    <ErrorOrInfoComp
                                        text={props.brandProfileError.website}
                                        _className={"font-red"}
                                        icon={<FiAlertCircle />}
                                    />
                                }
                            </div>

                            <div className="form-content-wrapper brand-description-wrapper">
                                <div className="brand-description-wrapper">
                                    <label>
                                        <b>Brand Description</b>
                                        <span className="optional"> (Optional)</span>
                                        {/* <span className={`input-counter grey-font ${props.brandProfile.brandDescription.length > BRAND_DESCRIPTION_MAX_LENGTH ? "red-text" : ""}`}>
                                            {props.brandProfile.brandDescription.length}/{BRAND_DESCRIPTION_MAX_LENGTH} characters
                                        </span> */}
                                    </label>
                                    <textarea
                                        disabled={props.isLoadingSave}
                                        onChange={(e) => onChangeBrandDescription(e)}
                                        value={props.brandProfile.brandDescription}
                                        placeholder="More details about your brand"
                                        className={`input-brand-description ${props.brandProfileError.brandDescription ? "border-red" : ""}`}
                                    />
                                </div>

                                <ButtonWithLoadingOrIcon
                                    className="no-fill-button main-button-40 button-save"
                                    text="Save Changes"
                                    icon={{
                                        type: "svg",
                                        src: FiSave
                                    }}
                                    position="right"
                                    onClickAction={() => {
                                        props.validateSaveBrandProfile();
                                        props.setPendingTab(-1);
                                    }}
                                />
                            </div>
                        </>
                    }
                </div>
            </SectionWrap>
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetupBrandProfile)
