import React, { useState, useEffect } from 'react';
import './IntegrationMiitelDetail.scss';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import IntegrationMiitelServices from '../../../../services/integration/IntegrationMiitelServices';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import PopupRemove from '../../../reuseableComponent/popupRemove/PopupRemove';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import { FiCopy, FiEdit } from 'react-icons/fi';
import iconDelete from '../../../../assets/img/integration/icon-delete.svg';
import { connect } from 'react-redux';

let IntegrationMiitelDetail = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [accountDetail, setAccountDetail] = useState(null);
  let [isShowModalDeleteChannel, setIsShowModalDeleteChannel] = useState(false);
  let [waitForResponseDeleteChannel, setWaitForResponseDeleteChannel] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  useEffect(() => {
    doMount();
  }, []);

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getAccountDetail(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  let getAccountDetail = (orgID) => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: parseInt(props.match.params.accountID)
    }

    IntegrationMiitelServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        setAccountDetail(response.dataResult.data);
      } else {
        setAccountDetail(null);
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let toggleModalDeleteChannel = () => {
    setIsShowModalDeleteChannel(!isShowModalDeleteChannel);
  };

  let deleteChannel = () => {
    setWaitForResponseDeleteChannel(true);

    let data = {
      id: accountDetail.channel.id,
      createdTime: accountDetail.channel.createdTime
    }

    IntegrationMiitelServices.delete(orgID, data, (response) => {
        if (response.dataResult.error.code === "") {
          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message, "fail");
            setWaitForResponseDeleteChannel(false);
          } else {
            doToast(response.dataResult.data.message);

            setTimeout(() => {
              back();
            }, 3000)
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
          setWaitForResponseDeleteChannel(false);
        }
      }
    );
  };

  let back = () => history.push(`/o/${orgID}/integration/miitel`);

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        <div className="sectionWrap integration-main-container integration-miitel-detail-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
          ) : (
            <>
              <BackButton text="Back to MiiTel" onClick={back} />

              <div className="integration-miitel-detail-account-box-1">
                <div className="integration-miitel-detail-account-box-1-top">
                  <b>{accountDetail.channel.channelName}</b>

                  <div className="integration-miitel-detail-account-box-1-top-button">
                    <ButtonWithLoadingOrIcon
                      icon={{
                        type: "img",
                        src: iconDelete
                      }}
                      className="red-button main-button-40"
                      text="Delete"
                      position="left"
                      onClickAction={() => toggleModalDeleteChannel()}
                    />

                    <ButtonWithLoadingOrIcon
                      icon={{
                        type: "svg",
                        src: FiEdit
                      }}
                      className="no-fill-button main-button-40"
                      text="Edit"
                      position="left"
                      onClickAction={() => {
                        props.history.push(`/o/${orgID}/integration/miitel/edit/${props.match.params.accountID}`)
                      }}
                    />
                  </div>
                </div>

                <div className="form-content-wrapper">
                  <label>
                    <b>Channel ID</b>
                  </label>
                  <div className="disabled-form">
                    <input type="text"
                      value={accountDetail.channel.stringID}
                      // onClick={copyToClip}
                      id="copy-string-id"
                      readOnly
                      style={{ paddingRight: "40px" }}
                    />

                    <FiCopy
                      onClick={() => {
                        copyToClip("copy-string-id", "Channel ID copied")
                      }}
                    />
                  </div>

                  <label>
                    <b>Company ID</b>
                  </label>
                  <div className="disabled-form">
                    <input type="text"
                      value={accountDetail.channel.companyID}
                      // onClick={copyToClip}
                      id="copy-company-id"
                      readOnly
                      style={{ paddingRight: "40px" }}
                    />

                    <FiCopy
                      onClick={() => {
                        copyToClip("copy-company-id", "Company ID copied")
                      }}
                    />
                  </div>

                  <label>
                    <b>Access Key</b>
                  </label>
                  <div className="disabled-form width-100">
                    <input type="text"
                      value={accountDetail.channel.accessKey}
                      // onClick={copyToClip}
                      id="copy-access-id"
                      readOnly
                      className='width-100'
                      style={{ paddingRight: "40px" }}
                    />

                    <FiCopy
                      onClick={() => {
                        copyToClip("copy-access-id", "Access Key copied")
                      }}
                    />
                  </div>
                </div>

                <PopupRemove
                  removeTitle={`Delete Channel?`}
                  removeDescription={`This channel will be deleted and your will not be able to recieve and send messages from this channel anymore.`}
                  onClickCancel={toggleModalDeleteChannel}
                  onClickRemove={deleteChannel}
                  waitForResponse={waitForResponseDeleteChannel}
                  showModalProps={isShowModalDeleteChannel}
                  submitText="Delete"
                  colorButton="red-button"
                />
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

let mapStateToProps = state => ({

});

let mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationMiitelDetail);
