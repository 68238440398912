import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetupKnowledgeBase.scss";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import { connect } from 'react-redux';
import { FiAlertCircle, FiDownload, FiEdit, FiEye, FiHelpCircle, FiInfo, FiRotateCcw, FiTrash2, FiUpload, FiX } from "react-icons/fi";
import { Modal, ModalBody, ModalHeader, Nav, NavItem, TabContent, Tooltip } from "reactstrap";
import SourceDocument from "./SourceDocument/SourceDocument";
import SourceText from "./SourceText/SourceText";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { doToast } from "../../../../helper/HelperGeneral";
import PopupLoading from "../../../reuseableComponent/popupLoading/PopUpLoading";
import PopUpErrorUpload from "../../../reuseableComponent/popUpErrorUpload/PopUpErrorUpload";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ChatbotServices from "../../../../services/newServices/ChatbotService";
import IconArrowCurved from "../../../../assets/img/integration/icon-arrow-left-curved.svg";
import PopupDiscard from "../../../reuseableComponent/popupDiscard/PopupDiscard";
import { KNOWLEDGE_SOURCES } from "../IntegrationChatGPTSetup2";

const IntegrationChatGPTSetupKnowledgeBase = (props) => {

    const toggle = (tab) => {
        if (props.knowledgeBaseTab !== tab) {
            props.setKnowledgeBaseTab(tab);
        }
    }

    const toggleTooltipPendingChanges = () => {
      setShowTooltipPendingChanges(!showTooltipPendingChanges);
    }

    const toggleTooltipSourceType = () => {
      setShowTooltipSourceType(!showTooltipSourceType);
    }

    const fetchPendingChanges = (updateOrigin = false) => {
        setIsLoadingGetPendingChanges(true);
        let data = {
            "chatbotID": Number(props.match.params.id)
        };
        ChatbotServices.getChatGptSavedKnowledgeBase(props.match.params.orgID, data, (response) => {
            let dataResult = response.dataResult;
            if (dataResult.error.message === "") {
                let knowledgeBase = { ...props.knowledgeBase };
                let data = dataResult.data;
                if (data.pendingChanges) {
                    knowledgeBase.pendingChanges = data.pendingChanges;
                }
                if (data.trainedSources) {
                    knowledgeBase.trainedSources = data.trainedSources;
                }
                props.setKnowledgeBase(knowledgeBase);
                if (updateOrigin) {
                    setKnowledgeBaseOrigin(JSON.stringify(knowledgeBase));
                }
            }
            else {
                doToast(`Failed to get saved knowledge base: ${dataResult.error.message}`, 'fail');
            }
            setIsLoadingGetPendingChanges(false);
        });
    }

    const printPendingChangesList = (list) => {
        return (
            list.map((value, index) => {
                const fileName = value.name;
                const fileNameWithoutExtension = fileName.replace(/\.[^/.]+$/, "");
                const fileExtension = fileName.includes(".") ? fileName.split(".")[fileName.split(".").length - 1] : "";
                return (
                    <div className="pending-change-list">
                        {
                            value.isNew ?
                            <div className="pending-change-list-new">NEW</div>
                            :
                            (value.isRemove || value.isRemoved) ?
                            <div className="pending-change-list-remove">REMOVE</div>
                            :
                            <div className="trained-source-list-default" />
                        }
                        <div className={`pending-change-list-text-container ${value.isNew ? "width-new" : (value.isRemove || value.isRemoved) ? "width-remove" : "width-default"}`}>
                            <div className="pending-change-list-name">{fileNameWithoutExtension}</div>
                            { 
                                fileExtension &&
                                <div className="pending-change-list-extension">.{fileExtension}</div>
                            }
                        </div>
                        <div className="pending-change-list-button-wrapper">
                            {
                                (value.isRemove || value.isRemoved) ?
                                <img 
                                    src={IconArrowCurved}
                                    alt=""
                                    className="color-black"
                                    onClick={() => { removePendingFile(value) }}
                                />
                                :
                                <FiTrash2
                                    className="color-red"
                                    onClick={() => { 
                                        if (value.isNew) {
                                            removePendingFile(value);
                                        }
                                        else {
                                            removeTrainedSource(value);
                                        }
                                    }}
                                />
                            }
                            {
                                value.type === "document" ?
                                <FiDownload
                                    className="color-orange"
                                    onClick={() => { downloadKnowledgeSourceFile(value) }} 
                                />
                                :
                                value.type === "text" ?
                                <FiEye
                                    className="color-orange"
                                    onClick={() => { viewKnowledgeSourceText(value) }} 
                                />
                                :
                                <></>
                            }
                        </div>
                    </div>
                )
            })
        )
    }

    const onFileSelected = (file) => {
        if (!file) {
            return;
        }
        if (checkFileNameExists(file.name)) {
            // doToast("Source name already exists.", 'fail');
            let _duplicateFiles = {...duplicateFiles};
            _duplicateFiles.files.push(file);
            setDuplicateFiles(_duplicateFiles);
            setIsShowModalDuplicate(true);
            return;
        }
        const fileName = file.name;
        const fileFormat = fileName.split(".")[fileName.split(".").length - 1];
    
        if (fileFormat === "csv" ||
            fileFormat === "pdf" ||
            fileFormat === "doc" ||
            fileFormat === "docx" ||
            fileFormat === "txt"
        ) {
            addFileToUploadQueue(file);
        }
        else {
            doToast("Allowed file formats are CSV, PDF, DOC, DOCX, TXT", 'fail');
        }
    }

    // Deprecated, upload flow moved to SourceText
    const onSubmitText = (name, content) => {
        if (checkFileNameExists(name)) {
            doToast("Source name already exists.", 'fail');
            return;
        }
        let item = {
            "type": "text",
            "name": name,
            "content": content
        };
        addFileToUploadQueue(item);
    }

    const addFileToUploadQueue = (file) => {
        let _uploadQueue = {...uploadQueue};
        _uploadQueue.files.push(file);
        setUploadQueue(_uploadQueue);
        setCurrentQueueSize(_uploadQueue.files.length);
    }

    const clearUploadQueue = () => {
        let _uploadQueue = {...uploadQueue};
        _uploadQueue.files.length = 0;
        setUploadQueue(_uploadQueue);
        fetchPendingChanges();
    }

    const downloadKnowledgeSourceFile = (file) => {
        if (file.document.fileURL) {
            window.open(file.document.fileURL, "_blank");
        }
        else {
            doToast("Document URL not found.", "fail");
        }
    }

    const viewKnowledgeSourceText = (file) => {
        setViewedTextSource(file);
    }

    const removePendingFile = (pendingFile) => {
        if (pendingFile && pendingFile.removeChangeID) {
            removePendingChanges([pendingFile.removeChangeID]);
        }
        else if (pendingFile && pendingFile.id) {
            removePendingChanges([pendingFile.id]);
        }
    }

    const clearPendingFiles = () => {
        let knowledgeBase = {...props.knowledgeBase};
        if (knowledgeBase.pendingChanges && knowledgeBase.pendingChanges.length > 0) {
            let changeIDs = [];
            knowledgeBase.pendingChanges.map((pendingFile) => {
                if (pendingFile && pendingFile.id) {
                    changeIDs.push(pendingFile.id);
                }
                return null;
            });
            if (changeIDs.length > 0) {
                removePendingChanges(changeIDs);
            }
        }
    }

    const removeTrainedSource = (trainedSource) => {
        if (trainedSource && trainedSource.id) {
            removeTrainedSources([trainedSource.id]);
        }
    }

    const removeAllTrainedSources = () => {
        let knowledgeBase = {...props.knowledgeBase};
        if (knowledgeBase.trainedSources && knowledgeBase.trainedSources.length > 0) {
            let sourceIDs = [];
            knowledgeBase.trainedSources.map((source) => {
                if (source && source.id) {
                    sourceIDs.push(source.id);
                }
                return null;
            });
            if (sourceIDs.length > 0) {
                removeTrainedSources(sourceIDs);
            }
        }
    }

    const clearFailedUploads = () => {
        let _failedUploads = {...failedUploads};
        _failedUploads.files.length = 0;
        setFailedUploads(_failedUploads);
    }

    const retryFailedUploads = () => {
        let _failedUploads = {...failedUploads};
        let files = _failedUploads.files;
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                onFileSelected(files[i]);
            }
        }
        _failedUploads.files.length = 0;
        setFailedUploads(_failedUploads);
        setIsShowModalError(false);
        fetchPendingChanges();
    }

    const clearDuplicateFiles = () => {
        let _duplicateFiles = {...duplicateFiles};
        _duplicateFiles.files.length = 0;
        setDuplicateFiles(_duplicateFiles);
    }

    const replaceFiles = () => {
        clearFailedUploads();
        clearDuplicateFiles();
        setIsShowModalError(false);
        setIsShowModalDuplicate(false);
        fetchPendingChanges();
        let el = document.querySelector("#source-document-input");
        if (el) {
            el.click();
            el.value = null;
        }
    }

    const checkFileNameExists = (name) => {
        return false; // FIXME: REMOVED LOCAL CHECK
        // if (!name) {
        //     return false;
        // }
        // let nameExists = false;
        // props.knowledgeBase.pendingChanges.map((value) => {
        //     if (value.name.toLowerCase() === name.toLowerCase()) {
        //         nameExists = true;
        //     }
        //     return null;
        // });
        // return nameExists;
    }

    const removePendingChanges = (changeIDs) => {
        if (!changeIDs || changeIDs.length <= 0) {
            return;
        }
        setIsShowModalRemoving(true);
        let data = {
            "chatbotID": Number(props.match.params.id),
            "changeIDs": changeIDs
        };
        ChatbotServices.removeKnowledgeBasePendingChanges(props.match.params.orgID, data, (response) => {
            let dataResult = response.dataResult;
            if (dataResult.data.success) {
                doToast(`Pending change${changeIDs.length > 1 ? "s" : ""} has been removed`, "info");
            }
            else {
                doToast(`Failed to remove pending change${changeIDs.length > 1 ? "s" : ""}: ${dataResult.data.message ? dataResult.data.message : dataResult.error.message}`, 'fail');
            }
            fetchPendingChanges(true);
            setIsShowModalRemoving(false);
        });
    }

    const removeTrainedSources = (sourceIDs) => {
        if (!sourceIDs || sourceIDs.length <= 0) {
            return;
        }
        setIsShowModalRemoving(true);
        let data = {
            "chatbotID": Number(props.match.params.id),
            "sourceIDs": sourceIDs
        };
        ChatbotServices.removeKnowledgeBaseTrainedSources(props.match.params.orgID, data, (response) => {
            let dataResult = response.dataResult;
            if (dataResult.data.success) {
                doToast(`Trained source${sourceIDs.length > 1 ? "s" : ""} has been removed`, "info");
            }
            else {
                doToast(`Failed to remove trained source${sourceIDs.length > 1 ? "s" : ""}: ${dataResult.data.message ? dataResult.data.message : dataResult.error.message}`, 'fail');
            }
            fetchPendingChanges(true);
            setIsShowModalRemoving(false);
        });
    }

    const toggleModalDiscardSourceText = () => {
        setIsShowModalDiscardSourceText(!isShowModalDiscardSourceText);
    }

    const toggleModalLoading = () => {
        setIsShowModalUploading(!isShowModalUploading);
    }

    const toggleModalRemoving = () => {
        setIsShowModalRemoving(!isShowModalRemoving);
    }

    const toggleModalError = () => {
        if (isShowModalError) {
            clearFailedUploads();
            fetchPendingChanges();
        }
        setIsShowModalError(!isShowModalError);
    }

    const toggleModalDuplicate = () => {
        if (isShowModalDuplicate) {
            clearDuplicateFiles();
            fetchPendingChanges();
        }
        setIsShowModalDuplicate(!isShowModalDuplicate);
    }

    const toggleModalViewTextSource = () => {
        if (isShowModalViewTextSource) {
            setViewedTextSource(null);
        }
        setIsShowModalViewTextSource(!isShowModalViewTextSource);
    }

    let [pendingTab, setPendingTab] = useState(null);
    let [showTooltipPendingChanges, setShowTooltipPendingChanges] = useState(false);
    let [showTooltipSourceType, setShowTooltipSourceType] = useState(false);
    let [knowledgeBaseOrigin, setKnowledgeBaseOrigin] = useState("");
    let [errorPendingChanges, setErrorPendingChanges] = useState("");
    let [uploadQueue, setUploadQueue] = useState({files: []});
    let [currentQueueSize, setCurrentQueueSize] = useState(0);
    let [failedUploads, setFailedUploads] = useState({files: []});
    let [duplicateFiles, setDuplicateFiles] = useState({files: []});
    let [duplicateMessage, setDuplicateMessage] = useState("Documents with the same name already exists in your source list, please select other documents.");
    let [viewedTextSource, setViewedTextSource] = useState(null);
    let [isLoadingGetPendingChanges, setIsLoadingGetPendingChanges] = useState(false);
    let [isShowModalDiscardSourceText, setIsShowModalDiscardSourceText] = useState(false);
    let [isShowModalUploading, setIsShowModalUploading] = useState(false);
    let [isShowModalRemoving, setIsShowModalRemoving] = useState(false);
    let [isShowModalError, setIsShowModalError] = useState(false);
    let [isShowModalDuplicate, setIsShowModalDuplicate] = useState(false);
    let [isShowModalViewTextSource, setIsShowModalViewTextSource] = useState(false);

    useEffect(() => {
        fetchPendingChanges();
    }, [props.match.params.orgID]);

    useEffect(() => {
        if (pendingTab && pendingTab.id === props.knowledgeBaseTab.id) {
            setPendingTab(null);
        }
    }, [props.knowledgeBaseTab]);

    useEffect(() => {
        let _uploadQueue = {...uploadQueue};
        if (_uploadQueue.files && _uploadQueue.files.length > 0) {
            setIsShowModalUploading(true);
            let file = _uploadQueue.files[0];
            if ("text" === file.type) {
                // Add text source
                let data = {
                    "chatbotID": Number(props.match.params.id),
                    "type": "text",
                    "text": file
                };
                ChatbotServices.addKnowledgeBaseItem(props.match.params.orgID, data, (response) => {
                    let dataResult = response.dataResult;
                    if (dataResult.data.success) {
                        onUploadSuccess();
                    }
                    else if (dataResult.error.message === "") {
                        setDuplicateMessage(dataResult.data.message);
                        onUploadFailed(true);
                    }
                    else {
                        onUploadFailed();
                    }
                });
                return;
            }

            // Upload document
            var formData = new FormData();
            formData.append("chatbotID", Number(props.match.params.id));
            formData.append("file", file);
            ChatbotServices.uploadKnowledgeBaseDocument(props.match.params.orgID, formData, (response) => {
                let dataResult = response.dataResult;
                if (dataResult.data.success) {
                    onUploadSuccess();
                }
                else if (dataResult.error.message === "") {
                    setDuplicateMessage(dataResult.data.message);
                    onUploadFailed(true);
                }
                else {
                    onUploadFailed();
                }
            });
        }
        else {
            setIsShowModalUploading(false);
        }
    }, [uploadQueue]);

    useEffect(() => {
        setIsShowModalViewTextSource(viewedTextSource && viewedTextSource.text && viewedTextSource.text.content);
    }, [viewedTextSource]);

    const onUploadSuccess = () => {
        let _uploadQueue = {...uploadQueue};
        if (_uploadQueue.files && _uploadQueue.files.length > 0) {
            let uploadedFile = _uploadQueue.files[0];
            let _knowledgeBase = {...props.knowledgeBase};
            _knowledgeBase.pendingChanges.push(uploadedFile);
            props.setKnowledgeBase(_knowledgeBase);
            _uploadQueue.files.splice(0, 1);
            setUploadQueue(_uploadQueue);
            setErrorPendingChanges("");
            
            if (_uploadQueue.files.length === 0) {
                let _failedUploads = {...failedUploads};
                let _duplicateFiles = {...duplicateFiles};
                if (_failedUploads.files.length <= 0 && _duplicateFiles.files.length <= 0) {
                    doToast(`Source${currentQueueSize > 1 ? "s" : ""} has been successfully uploaded`);
                }
                else if (_failedUploads.files.length > 0) {
                    setIsShowModalError(true);
                }
                else {
                    setIsShowModalDuplicate(true);
                }
                fetchPendingChanges(true);
            }
        }
    }

    const onUploadFailed = (isDuplicate = false) => {
        let _uploadQueue = {...uploadQueue};
        if (_uploadQueue.files && _uploadQueue.files.length > 0) {
            let uploadedFile = _uploadQueue.files[0];
            _uploadQueue.files.splice(0, 1);
            setUploadQueue(_uploadQueue);

            let _duplicateFiles = {...duplicateFiles};
            let _failedUploads = {...failedUploads};
            if (isDuplicate) {
                _duplicateFiles.files.push(uploadedFile);
                setDuplicateFiles(_duplicateFiles);
            }
            else {
                _failedUploads.files.push(uploadedFile);
                setFailedUploads(_failedUploads);
            }

            if (_uploadQueue.files.length === 0) {
                if (_failedUploads.files.length <= 0 && _duplicateFiles.files.length <= 0) {
                    doToast(`Source${currentQueueSize > 1 ? "s" : ""} has been successfully uploaded`);
                }
                else if (_failedUploads.files.length > 0) {
                    setIsShowModalError(true);
                }
                else {
                    setIsShowModalDuplicate(true);
                }
            }
        }
    }

    return (
        <>
            <SectionWrap
                {...props}
                orgID={props.match.params.orgID}
                withHeader
                withSideMenu
                noWrap
                dataOrigin={knowledgeBaseOrigin}
                dataAfterChange={JSON.stringify(props.knowledgeBase)}
            >
                <p className="text-info-top">
                    <FiInfo />
                    Import data from multiple sources and manage trained knowledge for this chatbot.
                </p>

                <div className="chatbot-setup-page-wrapper">
                    <div className="knowledge-source">
                        <div className="knowledge-source-input-wrapper">
                            <FiHelpCircle className="tooltip-icon" id="tooltip-source-type" />
                            <div>
                                <Tooltip
                                    className="tooltip-source-type"
                                    placement="right"
                                    isOpen={showTooltipSourceType}
                                    target="tooltip-source-type"
                                    toggle={toggleTooltipSourceType}
                                    opacity={1.0}
                                >
                                    <ul>
                                        <li>{`Document: allowed formats are .txt, .pdf, .csv, .doc, docx`}</li>
                                        <li>{`Text: upload free text knowledge (e.g. company profile, FAQ, etc.)`}</li>
                                    </ul>
                                </Tooltip>
                            </div>
                            <Nav tabs>
                                {KNOWLEDGE_SOURCES.map((value, index) => {
                                    return (
                                        <NavItem
                                            key={`tab-setup-${index}`}
                                            className={`
                                                ${props.knowledgeBaseTab.id === KNOWLEDGE_SOURCES[index].id ?
                                                    'active-setup-tab'
                                                    :
                                                    ''
                                                }
                                            `}
                                            onClick={() => { toggle(KNOWLEDGE_SOURCES[index]); }}
                                        >
                                            <div className="setup-tab-wrapper">
                                                <b>{KNOWLEDGE_SOURCES[index].tab}</b>
                                            </div>
                                        </NavItem>
                                    )
                                })}
                            </Nav>

                            <TabContent>
                                {
                                    props.knowledgeBaseTab.id === "document"
                                    ?
                                    <SourceDocument
                                        {...props}
                                        tab={"document"}
                                        onFileSelected={onFileSelected}
                                    />
                                    :
                                    props.knowledgeBaseTab.id === "text"
                                    ?
                                    <SourceText
                                        {...props}
                                        tab={"text"}
                                        onSubmitText={onSubmitText}
                                        checkFileNameExists={checkFileNameExists}
                                        fetchPendingChanges={fetchPendingChanges}
                                    />
                                    // :
                                    // props.knowledgeBaseTab.id === "website"
                                    // ?
                                    // <SourceWebsite
                                    //     {...props}
                                    //     tab={"website"}
                                    // />
                                    // :
                                    // props.knowledgeBaseTab.id === "qna"
                                    // ?
                                    // <SourceQnA
                                    //     {...props}
                                    //     tab={"qna"}
                                    // />
                                    :
                                    <></>
                                }
                            </TabContent>
                        </div>
                    </div>

                    <div className="pending-changes-wrapper">
                        <div className={`pending-changes sectionWrap ${errorPendingChanges ? "border-red" : ""}`}>
                            <span>
                                <b>Pending Changes</b>
                                <FiInfo className="tooltip-icon" id="tooltip-pending-changes" />
                                <div>
                                    <Tooltip
                                        className="tooltip-pending-changes"
                                        placement="bottom"
                                        isOpen={showTooltipPendingChanges}
                                        target="tooltip-pending-changes"
                                        toggle={toggleTooltipPendingChanges}
                                        opacity={1.0}
                                    >
                                        {
                                            (props.knowledgeBase.trainedSources && props.knowledgeBase.trainedSources.length > 0) ?
                                            `Every changes made for your trained source during this session will be saved permanently once you submit changes in submission page.`
                                            :
                                            `Every uploads and deletes you made during this session will be saved permanently to Trained Source List once you submit changes in submission page.`
                                        }
                                    </Tooltip>
                                </div>
                                {
                                    (props.knowledgeBase.pendingChanges && props.knowledgeBase.pendingChanges.length > 0 && !isLoadingGetPendingChanges) &&
                                    <span className="pending-changes-discard" onClick={clearPendingFiles}>
                                        <FiTrash2 />
                                        <b>Discard Changes</b>
                                    </span>
                                }
                            </span>
                            {
                                isLoadingGetPendingChanges ?
                                <RetrieveDataLoading
                                    isLoading={isLoadingGetPendingChanges}
                                    errorMessage=""
                                    errorCode=""
                                />
                                :
                                (props.knowledgeBase.pendingChanges && props.knowledgeBase.pendingChanges.length > 0) ?
                                <div>
                                    { printPendingChangesList(props.knowledgeBase.pendingChanges) }
                                </div>
                                :
                                <div className="pending-changes-empty">
                                    No uploaded source yet
                                </div>
                            }
                        </div>
                        {
                            errorPendingChanges &&
                            <ErrorOrInfoComp
                                text={errorPendingChanges}
                                _className={"font-red"}
                                icon={<FiAlertCircle />}
                            />
                        }

                        {
                            (props.knowledgeBase.trainedSources && props.knowledgeBase.trainedSources.length > 0) && 
                            <div className={`pending-changes trained-source-list sectionWrap`}>
                                <span>
                                    <b>Trained Source List</b>
                                    {
                                        !isLoadingGetPendingChanges &&
                                        <span className="trained-sources-discard" onClick={removeAllTrainedSources}>
                                            <FiTrash2 />
                                            <b>Delete All</b>
                                        </span>
                                    }
                                </span>
                                {
                                    isLoadingGetPendingChanges ?
                                    <RetrieveDataLoading
                                        isLoading={isLoadingGetPendingChanges}
                                        errorMessage=""
                                        errorCode=""
                                    />
                                    :
                                    <div>
                                        { printPendingChangesList(props.knowledgeBase.trainedSources) }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </SectionWrap>

            {/* Deprecated */}
            <PopupDiscard
                dataOrigin={JSON.stringify("")}
                dataAfterChange={JSON.stringify("")}
                isOpenDiscard={isShowModalDiscardSourceText}
                toggleDiscard={toggleModalDiscardSourceText}
                discardProgress={() => {
                    setIsShowModalDiscardSourceText(false);
                    if (pendingTab) {
                        props.setKnowledgeBaseTab(pendingTab);
                    }
                }}
                discardDesc={props.discardDesc}
            />

            <PopupLoading
                isOpen={isShowModalUploading}
                toggle={toggleModalLoading}
                title={currentQueueSize > 1 ? `Uploading ${Math.min(currentQueueSize - uploadQueue.files.length + 1, currentQueueSize)}/${currentQueueSize}` : "Uploading..."}
                description="This may take a while, please wait"
                // buttonText={uploadQueue.files.length > 1 ? "Cancel" : ""}
                buttonText="Cancel"
                buttonAction={clearUploadQueue}
            />

            <PopupLoading
                isOpen={isShowModalRemoving}
                toggle={toggleModalRemoving}
                title="Removing..."
            />

            <PopUpErrorUpload
                isOpen={isShowModalError && !isShowModalUploading}
                toggle={toggleModalError}
                title="Something Went Wrong"
                description="File upload was interrupted, please check your network and try again or select other documents."
                errorFiles={failedUploads.files}
                buttonText="Try Again"
                buttonIcon={FiRotateCcw}
                buttonAction={retryFailedUploads}
                secondButtonText="Replace Documents"
                secondButtonIcon={FiUpload}
                secondButtonAction={replaceFiles}
            />

            <PopUpErrorUpload
                isOpen={isShowModalDuplicate && !isShowModalUploading && !isShowModalError}
                toggle={toggleModalDuplicate}
                title="Something Went Wrong"
                description={duplicateMessage}
                errorFiles={duplicateFiles.files}
                buttonText={(duplicateFiles.files && duplicateFiles.files.length > 0 && "text" === duplicateFiles.files[0].type) ? "Edit Name" : "Replace Documents"}
                buttonIcon={(duplicateFiles.files && duplicateFiles.files.length > 0 && "text" === duplicateFiles.files[0].type) ? FiEdit : FiUpload}
                buttonAction={(duplicateFiles.files && duplicateFiles.files.length > 0 && "text" === duplicateFiles.files[0].type) ? toggleModalDuplicate : replaceFiles}
                buttonClass="no-fill-button main-button-48"
                secondButtonText={(duplicateFiles.files && duplicateFiles.files.length > 0 && "text" === duplicateFiles.files[0].type) ? "" : "Cancel"}
                secondButtonAction={toggleModalDuplicate}
                secondButtonClass="main-button-26 no-fill-button-no-border"
            />

            <Modal
                isOpen={isShowModalViewTextSource} 
                toggle={toggleModalViewTextSource}
                className="modal-view-text-source"
            >
                <ModalBody>
                    <FiX
                        className="close-button"
                        onClick={toggleModalViewTextSource}
                    />
                    <div className="modal-view-text-source-field">
                        <b className="modal-view-text-source-field-title">Name</b>
                        <p className="modal-view-text-source-field-content">{viewedTextSource ? viewedTextSource.name : ""}</p>                        
                        <ErrorOrInfoComp
                                text="Name will only be used for labeling and does not affect chatbot"
                                icon={<FiInfo />}
                            />
                    </div>
                    <div className="modal-view-text-source-field">
                        <b className="modal-view-text-source-field-title">Content</b>
                        <p className="modal-view-text-source-field-content">{(viewedTextSource && viewedTextSource.text) ? viewedTextSource.text.content : ""}</p>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetupKnowledgeBase)
