import React, { useState, useEffect } from 'react';
import './IntegrationMiitelEditAccount.scss';
import 'react-toastify/dist/ReactToastify.css';
import { FiInfo } from 'react-icons/fi';
import { checkID, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import IntegrationMiitelServices from '../../../../services/integration/IntegrationMiitelServices';
import { connect } from 'react-redux';

let IntegrationMiitelEditAccount = (props) => {
  let { match, history } = props;
  let [isLoadingEditAccount, setIsLoadingEditAccount] = useState(false);
  let [orgID, setOrgID] = useState(false);
  let [data, setData] = useState({
    channelName: "",
    companyID: "",
    accessKey: ""
  });
  let [dataOrigin, setDataOrigin] = useState({
    channelName: "",
    companyID: "",
    accessKey: ""
  });
  let [errorMessageData, setErrorMessageData] = useState({
    channelName: "",
    companyID: "",
    accessKey: ""
  });
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: "",
    message: ""
  })


  useEffect(() => {
    doMount();
    getAccountDetail();
  }, []);

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let back = () => {
    if (!isLoadingEditAccount) {
      history.push(`/o/${orgID}/integration/miitel/detail/${props.match.params.accountID}`);
    }
  }

  let editChannel = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = { ...errorMessageData };

    if (data.channelName === "") {
      _errorMessageData.channelName = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-channel-name" }
    }

    if (data.channelName.length > 50) {
      _errorMessageData.channelName = "Characters exceed limit";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-channel-name" }
    }

    if (data.companyID === "") {
      _errorMessageData.companyID = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-company-id" }
    }

    if (data.accessKey === "") {
      _errorMessageData.accessKey = "This field is required";
      errorCount = errorCount + 1;

      if (errorClass === "") { errorClass = "input-access-key" }
    }

    if (errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    } else {
      setIsLoadingEditAccount(true);

      let dataEdit = {
        id: parseInt(props.match.params.accountID),
        channelName: data.channelName,
        companyID: data.companyID,
        accessKey: data.accessKey
      }

      IntegrationMiitelServices.editDetail(orgID, dataEdit, (res) => {
        let dataRes = res.dataResult;

        if (dataRes.error.code === "") {
          if (dataRes.data.success) {
            doToast(dataRes.data.message);
            setDataOrigin(data);

            setTimeout(() => {
              props.history.push(`/o/${orgID}/integration/miitel/detail/${props.match.params.accountID}`)
            }, 3000)
          } else {
            doToast(dataRes.data.message, "fail");
            setIsLoadingEditAccount(false);

          }
        } else {
          doToast(dataRes.error.message, "fail");
          setIsLoadingEditAccount(false);
        }


      });
    }
  };

  let onChange = (e) => {
    let _data = { ...data };
    let _errorMessageData = { ...errorMessageData };

    _data[e.target.id] = e.target.value;
    _errorMessageData[e.target.id] = "";

    if (e.target.id === "channelName" && e.target.value.length > 50) {
      _errorMessageData["channelName"] = "Characters exceed limit";
    }

    setData(_data);
    setErrorMessageData(_errorMessageData);
  }

  let getAccountDetail = () => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: parseInt(props.match.params.accountID)
    }

    IntegrationMiitelServices.getDetail(props.match.params.orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        let dataVal = {
          channelName: response.dataResult.data.channel.channelName,
          companyID: response.dataResult.data.channel.companyID,
          accessKey: response.dataResult.data.channel.accessKey,
        };

        setData({ ...dataVal });
        setDataOrigin({ ...dataVal });
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingGetDetailData(false);
    });
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={JSON.stringify(dataOrigin)}
        dataAfterChange={JSON.stringify(data)}
      >

        {isLoadingGetDetailData ?
          <RetrieveDataLoading
            isLoading={isLoadingGetDetailData}
            errorMessage={retriveDataError.message}
          />
          :
          <div className="sectionWrap integration-main-container integration-miitel-add-account text-left">
            <BackButton text="Back to MiiTel" onClick={back} />

            <div className="integration-miitel-add-account-box">
              <div className="integration-miitel-add-account-box-top">
                <b>Edit Channel Details</b>

                {!isLoadingEditAccount ? (
                  <ButtonWithLoadingOrIcon
                    // isDisabled={!inputIdentifier}
                    onClickAction={editChannel}
                    className="orange-button main-button-40"
                    text="Save Changes"
                    position="left"
                  />
                ) : (
                  <ButtonWithLoadingOrIcon
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    className="orange-button main-button-40"
                    text="Save Changes"
                    position="left"
                  />
                )}
              </div>

              <div className="integration-miitel-add-account-box-form form-content-wrapper">
                <label>
                  <b>
                    Channel Name
                  </b>

                  <span className={`grey-text ${errorMessageData.channelName !== "" ? "red-text" : ""}`}>
                    {data.channelName.length}/50
                  </span>
                </label>
                <input
                  type="text"
                  onChange={(e) => { onChange(e) }}
                  value={data.channelName}
                  isDisabled={false}
                  className={`input-channel-name ${errorMessageData.channelName === "" ? "" : "border-red"}`}
                  id="channelName"
                  placeholder='Type your channel name here'
                />
                {errorMessageData.channelName !== "" &&
                  <ErrorOrInfoComp
                    icon={<FiInfo />}
                    text={errorMessageData.channelName}
                    _className={"red-text"}
                  />
                }

                <label>
                  <b>
                    Company ID
                  </b>
                </label>
                <input
                  type="text"
                  onChange={(e) => { onChange(e) }}
                  value={data.companyID}
                  isDisabled={false}
                  className={`input-company-id ${errorMessageData.companyID === "" ? "" : "border-red"}`}
                  id="companyID"
                  placeholder='Type your Company ID here'
                />
                <ErrorOrInfoComp
                  icon={<FiInfo />}
                  text={errorMessageData.companyID !== "" ? errorMessageData.companyID : "Get your Company ID details from the MiiTel dashboard."}
                  _className={errorMessageData.companyID !== "" ? "red-text" : ""}
                />

                <label>
                  <b>
                    Access Key
                  </b>
                </label>
                <input
                  type="text"
                  onChange={(e) => { onChange(e) }}
                  value={data.accessKey}
                  isDisabled={false}
                  className={`width-100 input-access-key ${errorMessageData.accessKey === "" ? "" : "border-red"}`}
                  id="accessKey"
                  placeholder='Type your access key here'
                />
                <ErrorOrInfoComp
                  icon={<FiInfo />}
                  text={errorMessageData.accessKey !== "" ? errorMessageData.accessKey : "Get your Access Key details from the MiiTel dashboard."}
                  _className={errorMessageData.companyID !== "" ? "red-text" : ""}
                />
              </div>
            </div>
          </div>
        }
      </SectionWrap>
    </div>
  );
};

let mapStateToProps = state => ({

});

let mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationMiitelEditAccount);
