import React, { useEffect, useState } from "react";
import "./CaseListAction.scss";
import { FiCheckCircle, FiAlertTriangle, FiChevronDown, FiUser, FiRefreshCcw, FiInfo } from "react-icons/fi";
import { Modal, ModalBody } from "reactstrap";
import { doToast } from "../../../../helper/HelperGeneral";
import CaseService from '../../../../services/chat/caseServices';
import topicService from "../../../../services/newServices/TopicServices";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import PopupRemove from "../../../reuseableComponent/popupRemove/PopupRemove";
import CustomSelect2 from "../../../reuseableComponent/CustomSelect2/CustomSelect2";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { setCaseListChecked } from "../../../../redux/actions/reduxActionCaseListChecked";
import { connect } from 'react-redux';

let CaseListAction = (props) => {
    let { data, type, caseListChecked,
        setCaseListChecked, orgID, _setLastBulkJunk,
        _setLastBulkSolve, toogleCaseListAction, caseLength,
        _setLastBulkHandover
    } = props;

    let [showPopupJunk, setShowPopupJunk] = useState(false);
    let [showPopupSolved, setShowPopupSolved] = useState(false);
    let [showPopupHandover, setShowPopupHandOver] = useState(false);
    let [showPopupTakeOver, setShowPopupTakeOver] = useState(false);
    let [showPopupTakeOverTopic, setShowPopupTakeOverTopic] = useState(false);
    let [takeOverSelectedTopic, setTakeOverSelectedTopic] = useState({
        label: "",
        value: ""
    })
    let [handoverData, setHandoverData] = useState({
        agent: {
            label: "",
            value: ""
        },
        topic: {
            label: "",
            value: ""
        }
    })
    let [isSetMarkAsResolved, setIsSetMarkAsResolved] = useState(false);
    let [isLoadingMarkCase, setIsLoadingMarkCase] = useState(false);
    let [dropdownCaseActionOpen, setDropdownCaseActionOpen] = useState(false);
    let [isSendClosingMessage, setIsSendClosingMessage] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        errorTopicTakeover: "",
        errorTopicHandover: "",
        errorAgentHandover: ""
    })
    let [agentList, setAgentList] = useState([]);
    let [autoHandoverTopic, setAutoHandoverTopic] = useState({
        value: "",
        label: ""
    });

    useEffect(() => {
        setIsSendClosingMessage(false);
    }, [showPopupSolved])

    let getAgents = (topicID, _handoverData, _autoHandoverTopic) => {
        topicService.getTopic(orgID, "details", topicID, (response) => {
            if (response.dataResult.error.code === "") {
                let dataResult = response.dataResult;
                let arr = [];

                if (
                    (
                        type === "globalSearch" ||
                        type === "mine" ||
                        type === "other"
                    ) &&
                    (
                        (_handoverData.topic.value !== _autoHandoverTopic.value) || _autoHandoverTopic.value === ""
                    )
                ) {
                    arr.push({
                        value: 0,
                        label: "Unassigned"
                    })
                }

                dataResult.data.agents.map((v, i) => {
                    if (v.accountName !== "" && props.myAgentData.account.id !== v.accountID) {
                        arr.push({
                            value: v.accountID,
                            label: `${v.accountName}${v.accountAlias === "" ? "" : ` (${v.accountAlias})`} `
                        })
                    }

                    return null;
                })

                setAgentList(arr);
            } else {
                doToast(response.dataResult.error.message, 'fail');
            }
        })
    }

    let toggleMarkAsResolved = () => {
        setIsSetMarkAsResolved(!isSetMarkAsResolved);
    }

    let toggleJunk = () => {
        if (!isLoadingMarkCase) {
            setShowPopupJunk(!showPopupJunk);
            setIsSetMarkAsResolved(false);
        }
    }

    let toggleSolved = () => {
        if (!isLoadingMarkCase) {
            setShowPopupSolved(!showPopupSolved);
        }
    }

    let toogleHandover = () => {
        if (!isLoadingMarkCase) {
            setAgentList([]);

            setAutoHandoverTopic({
                value: "",
                label: ""
            });

            setErrorMessageData({
                errorTopicTakeover: "",
                errorTopicHandover: "",
                errorAgentHandover: ""
            });

            let _handoverData = { ...handoverData };

            if (showPopupHandover === true) {
                setHandoverData({
                    agent: {
                        label: "",
                        value: ""
                    },
                    topic: {
                        label: "",
                        value: ""
                    }
                })
            } else {
                let isDiff = false;
                let topicDef = {
                    label: caseListChecked[type].data[Object.keys(caseListChecked[type].data)[0]].topicName,
                    value: caseListChecked[type].data[Object.keys(caseListChecked[type].data)[0]].topicID,
                }

                Object.keys(caseListChecked[type].data).map((v) => {
                    if (caseListChecked[type].data[v].topicID !== topicDef.value) {
                        isDiff = true;
                    }

                    return null;
                })

                if (!isDiff) {
                    _handoverData.topic = topicDef;

                    setAutoHandoverTopic(topicDef);
                    setHandoverData(_handoverData);
                }

                if (_handoverData.topic.value !== "" && _handoverData.topic.value !== 0) {
                    getAgents(_handoverData.topic.value, _handoverData, topicDef);
                }
            }

            setShowPopupHandOver(!showPopupHandover);
        }
    }

    let toggleTakeover = () => {
        if (!isLoadingMarkCase) {
            setErrorMessageData({
                errorTopicTakeover: "",
                errorTopicHandover: "",
                errorAgentHandover: ""
            });

            setShowPopupTakeOver(!showPopupTakeOver);
        }
    }

    let toggleTakeOverTopic = () => {
        if (!isLoadingMarkCase) {
            setErrorMessageData({
                errorTopicTakeover: "",
                errorTopicHandover: "",
                errorAgentHandover: ""
            });

            // if (showPopupTakeOverTopic === true) {
            //     setTakeOverSelectedTopic({
            //         label: "",
            //         value: ""
            //     })
            if (showPopupTakeOverTopic === false) {
                let isDiff = false;
                let topicDef = {
                    label: caseListChecked[type].data[Object.keys(caseListChecked[type].data)[0]].topicName,
                    value: caseListChecked[type].data[Object.keys(caseListChecked[type].data)[0]].topicID,
                }

                Object.keys(caseListChecked[type].data).map((v) => {
                    if (caseListChecked[type].data[v].topicID !== topicDef.value) {
                        isDiff = true;
                    }

                    return null;
                })

                if (!isDiff) {
                    setTakeOverSelectedTopic(topicDef);
                } else {
                    setTakeOverSelectedTopic({
                        label: "",
                        value: ""
                    })
                }
            }

            setShowPopupTakeOverTopic(!showPopupTakeOverTopic);
        }
    }

    let toggleDropdownCaseAction = () => {
        if (Object.keys(caseListChecked[type].data).length > 0) {
            setDropdownCaseActionOpen(!dropdownCaseActionOpen);
        }
    }

    let markCase = (_type) => {
        setIsLoadingMarkCase(true);
        let _caseListChecked = { ...caseListChecked };

        if (_type === "close") {
            let ids = [];
            let roomIDs = [];

            Object.keys(_caseListChecked[type].data).map(v => {
                ids.push(_caseListChecked[type].data[v].id);

                if (type === "unassigned" || type === "solved" || type === "bot") {
                    roomIDs.push(_caseListChecked[type].data[v].tapTalkXCRoomID);
                } else {
                    roomIDs.push(_caseListChecked[type].data[v].tapTalkRoom.lastMessage.room.roomID);
                }

                return null;
            })

            let data = {
                "ids": ids,
                "sendClosingMessage": isSendClosingMessage,
            };

            _setLastBulkSolve({
                time: new Date().valueOf(),
                arrayOfRoomIDs: roomIDs
            })

            setTimeout(() => {
                CaseService.postCloseCaseMulti(orgID, data, (response) => {
                    if (response.dataResult.error.code === "") {
                        _caseListChecked[type].data = {};
                        _caseListChecked[type].checkedAll = false;
                        setCaseListChecked(_caseListChecked)
                        doToast(response.dataResult.data.message);
                        toogleCaseListAction();
                    } else {
                        doToast(response.dataResult.error.message, "fail");
                    }

                    toggleSolved();
                    setIsLoadingMarkCase(false);
                });
            }, 1000)
        } else {
            let cases = [];
            let roomIDs = [];

            Object.keys(_caseListChecked[type].data).map(v => {
                cases.push({
                    id: _caseListChecked[type].data[v].id,
                    createdTime: _caseListChecked[type].data[v].createdTime
                });

                if (type === "unassigned" || type === "solved" || type === "bot") {
                    roomIDs.push(_caseListChecked[type].data[v].tapTalkXCRoomID);
                } else {
                    roomIDs.push(_caseListChecked[type].data[v].tapTalkRoom.lastMessage.room.roomID);
                }

                return null;
            })

            let data = {
                "cases": cases,
                "isJunk": _type === "unjunk" ? false : true,
                "closeSelectedCases": isSetMarkAsResolved,
            };

            if (isSetMarkAsResolved) {
                _setLastBulkSolve({
                    time: new Date().valueOf(),
                    arrayOfRoomIDs: roomIDs
                })
            } else {
                _setLastBulkJunk({
                    time: new Date().valueOf(),
                    arrayOfRoomIDs: roomIDs
                })
            }

            setTimeout(() => {
                CaseService.updateJunkStatusMulti(orgID, data, (response) => {
                    if (response.dataResult.error.code === "") {
                        _caseListChecked[type].data = {};
                        _caseListChecked[type].checkedAll = false;
                        setCaseListChecked(_caseListChecked)
                        doToast(response.dataResult.data.message);
                        toogleCaseListAction();
                    } else {
                        doToast(response.dataResult.error.message, "fail");
                    }

                    toggleJunk();
                    setIsLoadingMarkCase(false);
                });
            }, 1000)
        }
    }

    let multiTakeover = (isSubmit = false) => {
        let errCount = 0;
        let _errorMessageData = { ...errorMessageData };

        if (type === "bot" && (takeOverSelectedTopic.value === "" || takeOverSelectedTopic.value === 0)) {
            _errorMessageData.errorTopicTakeover = "This field is required";
            errCount = errCount + 1;
        }


        if (errCount > 0) {
            setErrorMessageData(_errorMessageData);
        } else {
            if (isSubmit === false) {
                toggleTakeOverTopic();
                toggleTakeover();
            } else {
                setIsLoadingMarkCase(true);

                let _caseListChecked = { ...caseListChecked };

                let ids = [];
                let roomIDs = [];

                Object.keys(_caseListChecked[type].data).map(v => {
                    ids.push(_caseListChecked[type].data[v].id);

                    if (type === "unassigned" || type === "solved" || type === "bot") {
                        roomIDs.push(_caseListChecked[type].data[v].tapTalkXCRoomID);
                    } else {
                        roomIDs.push(_caseListChecked[type].data[v].tapTalkRoom.lastMessage.room.roomID);
                    }

                    return null;
                })

                let data = {
                    "ids": ids,
                };


                if (takeOverSelectedTopic.value !== "") {
                    data.topicID = takeOverSelectedTopic.value;
                }

                CaseService.postTakeCaseAsAgentMulti(orgID, data, (response) => {
                    if (response.dataResult.error.code === "") {
                        _caseListChecked[type].data = {};
                        _caseListChecked[type].checkedAll = false;
                        setCaseListChecked(_caseListChecked)
                        doToast(response.dataResult.data.message);
                        toogleCaseListAction();

                        if (type === "bot") {
                            setShowPopupTakeOverTopic(false);
                        } else {
                            setShowPopupTakeOver(false);
                        }
                    } else {
                        doToast(response.dataResult.error.message, "fail");
                    }

                    setIsLoadingMarkCase(false);
                });
            }
        }
    }

    let multiHandover = () => {
        let errCount = 0;
        let _errorMessageData = { ...errorMessageData };

        if (handoverData.topic.value === "") {
            _errorMessageData.errorTopicHandover = "This field is required";
            errCount = errCount + 1;
        }

        if (handoverData.agent.value === "") {
            _errorMessageData.errorAgentHandover = "This field is required";
            errCount = errCount + 1;
        }

        if (errCount > 0) {
            setErrorMessageData(_errorMessageData);
        } else {
            setIsLoadingMarkCase(true);

            let _caseListChecked = { ...caseListChecked };

            let dataHandover = {
                ids: [],
                roomIDs: []
            }

            let roomIDsBulkHandover = [];

            Object.keys(_caseListChecked[type].data).map(v => {
                dataHandover.ids.push(_caseListChecked[type].data[v].id);
                // dataHandover.roomIDs.push(_caseListChecked[type].data[v].tapTalkRoom.lastMessage.room.roomID);

                if (type === "unassigned" || type === "solved" || type === "bot") {
                    dataHandover.roomIDs.push(_caseListChecked[type].data[v].tapTalkXCRoomID);
                } else {
                    dataHandover.roomIDs.push(_caseListChecked[type].data[v].tapTalkRoom.lastMessage.room.roomID);
                }

                return null;
            })

            let data = {
                "ids": dataHandover.ids,
                "topicID": handoverData.topic.value,
                "agentAccountID": handoverData.agent.value
            };

            if (takeOverSelectedTopic.value !== "") {
                data.topicID = takeOverSelectedTopic.value;
            }

            CaseService.handoverCaseMulti(orgID, data, (response) => {
                if (response.dataResult.error.code === "") {
                    _caseListChecked[type].data = {};
                    _caseListChecked[type].checkedAll = false;
                    setCaseListChecked(_caseListChecked)
                    doToast(response.dataResult.data.message);
                    toogleCaseListAction();
                    toogleHandover();

                    let _updatedIDs = response.dataResult.data.updatedIDs
                    if (type === "other" && _updatedIDs.length > 0 && data.agentAccountID === 0) {
                        _updatedIDs.map((v) => {
                            let idxRoom = dataHandover.ids.findIndex(_v => _v === v);

                            if (idxRoom !== -1) {
                                roomIDsBulkHandover.push(dataHandover.roomIDs[idxRoom]);
                            }

                            return null;
                        })

                        _setLastBulkHandover({
                            time: new Date().valueOf(),
                            arrayOfRoomIDs: roomIDsBulkHandover
                        })
                    }
                } else {
                    doToast(response.dataResult.error.message, "fail");
                }

                setIsLoadingMarkCase(false);
            });
        }
    }

    let clickCheckedAllCase = (e) => {
        let _checked = e.target.checked;
        let _caseListChecked = { ...caseListChecked };

        _caseListChecked[type].data = {};

        if (_checked) {
            _caseListChecked[type].data = { ...data };
        }

        _caseListChecked[type].checkedAll = _checked;
        setCaseListChecked(_caseListChecked);
    }

    let onChangeTakeoverTopic = (e) => {
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.errorTopicTakeover = "";

        setErrorMessageData(_errorMessageData);
        setTakeOverSelectedTopic(e);
    }

    let onChangeHandover = (dataType, e) => {
        let _errorMessageData = { ...errorMessageData };
        let _handoverData = { ...handoverData };
        // _errorMessageData.errorTopicTakeover = "";

        _handoverData[dataType] = e;

        _errorMessageData[dataType === "topic" ? "errorTopicHandover" : "errorAgentHandover"] = "";

        if (dataType === "topic") {
            getAgents(_handoverData.topic.value, _handoverData, autoHandoverTopic);
        }

        setErrorMessageData(_errorMessageData);
        setHandoverData(_handoverData);
    }

    return (
        <>
            <PopupRemove
                removeTitle={`Mark as Resolved?`}
                removeDescription={`These ${Object.keys(caseListChecked[type].data).length} selected cases will be marked as Resolved. Are you sure you want to continue?`}
                onClickCancel={toggleSolved}
                onClickRemove={() => markCase("close")}
                additionalContent={
                    <div
                        className="set-send-closing-message-wrapper"
                        style={{
                            display: "flex",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: 14,
                            lineHeight: "21px",
                            letterSpacing: "-0.2px",
                            color: "rgba(25, 25, 25, 0.6)",
                            padding: "0 8px 8px",
                            background: "rgba(25, 25, 25, 0.05)",
                            borderRadius: 8,
                            marginTop: 8,
                        }}
                    >
                        <div className="custom-checkbox">
                            <input
                                type="checkbox"
                                id="set-send-closing-message"
                                onClick={() => setIsSendClosingMessage(!isSendClosingMessage)}
                                checked={isSendClosingMessage}
                                disabled={isLoadingMarkCase}
                                readOnly
                            />
                            <label htmlFor="set-send-closing-message" />
                        </div>
                        <p style={{ marginTop: 7 }}>Send closing message</p>
                    </div>
                }
                withoutBorder
                loadingButtonGray
                waitForResponse={isLoadingMarkCase}
                showModalProps={showPopupSolved}
                submitText={'Mark as Resolved'}
            />

            <PopupRemove
                removeTitle={`Mark as Junk?`}
                removeDescription={`These ${Object.keys(caseListChecked[type].data).length} selected cases will be marked as junk. Are you sure you want to continue?`}
                onClickCancel={toggleJunk}
                onClickRemove={() => markCase("junk")}
                additionalContent={
                    <div
                        className="set-send-closing-message-wrapper"
                        style={{
                            display: "flex",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: 14,
                            lineHeight: "21px",
                            letterSpacing: "-0.2px",
                            color: "rgba(25, 25, 25, 0.6)",
                            padding: "0px 8px 8px",
                            background: "rgba(25, 25, 25, 0.05)",
                            borderRadius: 8,
                            marginTop: 8,
                        }}
                    >
                        <div className="custom-checkbox">
                            <input
                                type="checkbox"
                                id="set-send-closing-message"
                                onClick={toggleMarkAsResolved}
                                checked={isSetMarkAsResolved}
                                disabled={isLoadingMarkCase}
                                readOnly
                            />
                            <label htmlFor="set-send-closing-message" />
                        </div>

                        <p style={{ marginTop: 7 }}>Mark as Resolved</p>
                    </div>
                }
                withoutBorder
                loadingButtonGray
                waitForResponse={isLoadingMarkCase}
                showModalProps={showPopupJunk}
                submitText={'Mark as Junk'}
            />

            <PopupRemove
                removeTitle={`Take Over Case?`}
                removeDescription={`You are about to take over ${Object.keys(caseListChecked[type].data).length} cases. Are you sure you want to take over the case?`}
                onClickCancel={toggleTakeover}
                onClickRemove={() => { multiTakeover(true) }}
                withoutBorder
                loadingButtonGray
                waitForResponse={isLoadingMarkCase}
                showModalProps={showPopupTakeOver}
                submitText={'Take Over'}
            />

            <Modal
                isOpen={showPopupTakeOverTopic}
                className="modal-handover-case"
                toggle={toggleTakeOverTopic}
            >
                <ModalBody>
                    <p className="modal-handover-case-title">
                        <b>Take Over Case</b>
                    </p>

                    <label><b>Topic</b></label>

                    <div className="custom-select2-wrapper">
                        <CustomSelect2
                            options={props.myTopics}
                            placeholder="Select topic"
                            onChange={(e) => onChangeTakeoverTopic(e)}
                            val={props.myTopics.filter(obj => obj.value === takeOverSelectedTopic.value)}
                            _disabled={isLoadingMarkCase}
                            isBorderRed={errorMessageData.errorTopicTakeover !== ""}
                        />
                    </div>

                    {errorMessageData.errorTopicTakeover !== "" &&
                        <ErrorOrInfoComp
                            icon={<FiInfo />}
                            text={errorMessageData.errorTopicTakeover}
                            _className={"red-text"}
                        />
                    }

                    <div className="handover-case-button-wrapper case-list-action-handover-case-button-wrapper">
                        <ButtonWithLoadingOrIcon
                            text="Cancel"
                            className="main-button-48 no-fill-button"
                            onClickAction={toggleTakeOverTopic}
                        />

                        {isLoadingMarkCase ?
                            <ButtonWithLoadingOrIcon
                                text={`Take Over`}
                                className="main-button-48 orange-button"
                                loadingColor="gray"
                                isDisabled
                                isLoading
                                position="left"
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                text={`Take Over`}
                                className="main-button-48 orange-button"
                                onClickAction={multiTakeover}
                                isDisabled={isLoadingMarkCase}
                            />
                        }
                    </div>
                </ModalBody>
            </Modal>

            <Modal
                isOpen={showPopupHandover}
                className="modal-handover-case"
                toggle={toogleHandover}
            >
                <ModalBody>
                    <p className="modal-handover-case-title">
                        <b>Hand Over Case</b>
                    </p>

                    <label><b>Topic</b></label>

                    <div className="custom-select2-wrapper">
                        <CustomSelect2
                            options={props.allTopicListHandover}
                            placeholder="Select topic"
                            onChange={(e) => onChangeHandover("topic", e)}
                            val={props.allTopicListHandover.filter(obj => obj.value === handoverData.topic.value)}
                            _disabled={isLoadingMarkCase}
                            isBorderRed={errorMessageData.errorTopicHandover !== ""}
                        />
                    </div>

                    {errorMessageData.errorTopicHandover !== "" &&
                        <ErrorOrInfoComp
                            icon={<FiInfo />}
                            text={errorMessageData.errorTopicHandover}
                            _className={"red-text"}
                        />
                    }

                    <label><b>Agent</b></label>

                    <div className="custom-select2-wrapper">
                        <CustomSelect2
                            options={agentList}
                            placeholder="Select agent"
                            onChange={(e) => onChangeHandover("agent", e)}
                            val={agentList.filter(obj => obj.value === handoverData.agent.value)}
                            _disabled={isLoadingMarkCase}
                            isBorderRed={errorMessageData.errorAgentHandover !== ""}
                        />
                    </div>

                    {errorMessageData.errorAgentHandover !== "" &&
                        <ErrorOrInfoComp
                            icon={<FiInfo />}
                            text={errorMessageData.errorAgentHandover}
                            _className={"red-text"}
                        />
                    }

                    <div className="handover-case-button-wrapper case-list-action-handover-case-button-wrapper">
                        <ButtonWithLoadingOrIcon
                            text="Cancel"
                            className="main-button-48 no-fill-button"
                            onClickAction={toogleHandover}
                        />

                        {isLoadingMarkCase ?
                            <ButtonWithLoadingOrIcon
                                text={`Hand Over`}
                                className="main-button-48 orange-button"
                                loadingColor="gray"
                                isDisabled
                                isLoading
                                position="left"
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                text={`Hand Over`}
                                className="main-button-48 orange-button"
                                onClickAction={multiHandover}
                                isDisabled={isLoadingMarkCase}
                            />
                        }
                    </div>
                </ModalBody>
            </Modal>

            <div className="case-list-action-wrapper">
                <div className="case-list-action-left">
                    <Dropdown
                        isOpen={dropdownCaseActionOpen}
                        toggle={toggleDropdownCaseAction}
                        direction={"down"}
                        className={`${Object.keys(caseListChecked[type].data).length === 0 ? "dropdown-disabled" : ""}`}
                    >
                        <DropdownToggle>
                            <b>Select Action</b>
                            <FiChevronDown className={`${dropdownCaseActionOpen ? "dropdown-case-action-active" : ""}`} />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={toggleSolved}>
                                <FiCheckCircle />
                                Mark as Resolved
                            </DropdownItem>

                            <DropdownItem onClick={toggleJunk}>
                                <FiAlertTriangle />
                                Mark as Junk
                            </DropdownItem>

                            {/* {type === "mine" && */}
                            {props.allowHandover &&
                                <DropdownItem onClick={toogleHandover}>
                                    <FiRefreshCcw />
                                    Hand Over Case
                                </DropdownItem>
                            }
                            {/* } */}

                            {type !== "mine" &&
                                <DropdownItem onClick={type === "bot" ? toggleTakeOverTopic : toggleTakeover}>
                                    <FiUser />
                                    Take Over Case
                                </DropdownItem>
                            }
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div className="case-list-action-right">
                    <b>
                        {Object.keys(caseListChecked[type].data).length} Selected
                    </b>

                    {caseLength === Object.keys(caseListChecked[type].data).length &&
                        <div className={"custom-checkbox"}>
                            <input
                                type="checkbox"
                                id="check-all-case"
                                onChange={(e) => clickCheckedAllCase(e)}
                                checked={true}
                            />
                            <label htmlFor="check-all-case"></label>
                        </div>
                    }

                    {(Object.keys(caseListChecked[type].data).length > 0 && caseLength !== Object.keys(caseListChecked[type].data).length) &&
                        <div className={"custom-checkbox-stripe a"}>
                            <input
                                type="checkbox"
                                id="check-all-case"
                                onChange={(e) => clickCheckedAllCase(e)}
                                checked={true}
                            />
                            <label htmlFor="check-all-case"></label>
                        </div>
                    }

                    {Object.keys(caseListChecked[type].data).length === 0 &&
                        <div className={"custom-checkbox"}>
                            <input
                                type="checkbox"
                                id="check-all-case"
                                onChange={(e) => clickCheckedAllCase(e, true)}
                                checked={caseListChecked[type].checkedAll}
                            />
                            <label htmlFor="check-all-case"></label>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    caseListChecked: state.caseListChecked,
    myTopics: state.myTopics,
    allTopicListHandover: state.allTopicListHandover,
    myAgentData: state.myAgentData,
    allowHandover: state.allowHandover
});

const mapDispatchToProps = {
    setCaseListChecked
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseListAction);