import React, { useState, useEffect } from 'react';
import './ModalStartConversation.scss';
import { tapCoreRoomListManager } from "@taptalk.io/web-sdk";
import { connect } from 'react-redux';
import { taptalk } from "@taptalk.io/web-sdk";
import { Scrollbars } from 'react-custom-scrollbars';
import Airplane from '../../../../../assets/img/icon-airplane.svg';
import IconPencilNew from "../../../../../assets/img/icon-pencil-new-message.svg";
import { doToast } from '../../../../../helper/HelperGeneral';
import HelperCustomEncryptor from '../../../../../helper/HelperCustomEncryptor';
import HelperGetLocalStorage from '../../../../../helper/HelperGetLocalStorage';
import { runImageCompress, scrollToClass } from "../../../../../helper/HelperGeneral";
import HelperUpload from "../../../../../helper/HelperUpload";
import HelperChat from "../../../../../helper/HelperChat";
import HelperInput from "../../../../../helper/HelperInput";
import { WHATSAPP_FILE_SIZE_LIMIT } from "../../../../../constants/taplive";
import { FiX, FiImage, FiAlertCircle, FiSearch, FiPlus, FiFileText, FiInfo, FiPhone, FiUser } from 'react-icons/fi';
import BroadcastMessageFormMediaMessage from "../../../../broadcastMessage/broadcastMessageForm/broadcastMessageFormMediaMessage/BroadcastMessageFormMediaMessage";
import BroadcastMessageFormFileStartNewMessage from "../../../../broadcastMessage/broadcastMessageForm/broadcastMessageFormFileStartNewMessage/BroadcastMessageFormFileStartNewMessage";
// import IntegrationWhatsappServices from "../../../../../services/integration/IntegrationWhatsappServices";
import BroadcastMessageServices from '../../../../../services/newServices/BroadcastMessageServices';
import UserContactServices from "../../../../../services/newServices/UserContactServices";
import StartConversationServices from "../../../../../services/chat/startConversationServices";
import ErrorOrInfoComp from "../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import PhoneCodePicker from '../../../../reuseableComponent/phoneCodePicker/PhoneCodePicker';
import BackButton from "../../../../reuseableComponent/BackButton/BackButton";
import CustomSelectOption from '../../../../reuseableComponent/customSelectOption/CustomSelectOption';
import CustomReactSelect from '../../../../reuseableComponent/CustomReactSelect/CustomReactSelect';
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
// import CustomSelect2 from '../../../../reuseableComponent/CustomSelect2/CustomSelect2';
import { clearStartConversation, setStartConversation } from '../../../../../redux/actions/reduxActionStartConversation';
import { clearStartConversationBa } from '../../../../../redux/actions/reduxActionStartConversationBa';
import { setActiveCasePanel } from '../../../../../redux/actions/reduxActionActiveCasePanel';
import { setToggleMineCase } from "../../../../../redux/actions/reduxActionToggleMineCase";
import { setShowOtherCase } from "../../../../../redux/actions/reduxActionShowOtherCase";
import { setModalStartConversationBalance, clearModalStartConversationBalance } from "../../../../../redux/actions/reduxActionModalStartConversationBalance";
import { setCloseCaseNewMessageRecipient } from "../../../../../redux/actions/reduxActionCloseCaseNewMessageRecipient";
import { FAIL_SEND_MESSAGE_REASON } from "../../../../../constants/message";
import { TAPLIVE_MEDIUM_LOGO } from "../../../../../constants/taplive";

var style = {
    scrollStyle: {
        position: "relative",
        backgroundColor: "#ff7d00",
        right: "-2px",
        width: "5px",
        borderRadius: "8px"
    }
};

const REG_NUMERIC = /^[0-9]*$/;

const ERROR_MESSAGE_NUMBER = {
    length: "The combined country code and phone number must be between 7-15 digits",
    isNumeric: "Only numerics are allowed",
    isStartZero: "Phone number cannot start from 0"
}

const MESSAGE_TYPE = {
    text: "text",
    image: "image",
    file: "file",
    video: "video"
}

const LIST_CH_TYPE = [
    {
        label: "WhatsApp SME",
        value: "whatsapp",
        logo: TAPLIVE_MEDIUM_LOGO["whatsapp"]
    },
    {
        label: "WhatsApp Business API",
        value: "whatsappba",
        logo: TAPLIVE_MEDIUM_LOGO["whatsappba"]
    }
]

let ModalStartConversation = (props) => {
    let [isLoadingWhatsappStatus, setIsLoadingWhatsappStatus] = useState(false);
    let [channelList, setChannelList] = useState([]);
    let [conversationData, setConversationData] = useState({
        channelMedium: LIST_CH_TYPE[0],
        channel: {
            label: "",
            value: "",
        },
        recipient: {
            country: {
                id: "0",
                countryCodeNumber: "62",
                countryCode: "IDN",
                countryName: "Indonesia",
                countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
            },
            number: "",
            contact: {
                name: "",
                alias: "",
                number: ""
            }
        },
        message: "",
        messageType: MESSAGE_TYPE.text,
        fileAsset: {
            fileURL: "",
            fileName: "",
            fileData: false,
            caption: ""
        },
        topic: {
            value: "",
            label: ""
        },
        type: ""
    });
    let [contactSearchVal, setContactSearchVal] = useState("");
    let [temporaryContactList, setTemporaryContactList] = useState({
        search: "",
        list: [],
        time: 0
    })
    let [contactList, setContactList] = useState([]);

    let [temporaryFileValue, setTemporaryFileValue] = useState({
        fileAsset: {},
        time: false
    })

    let [errorFieldNumber, setErrorFieldNumber] = useState("");

    let [countryList, setCountryList] = useState(false);
    let [toggleAddPhoneForm, setToggleAddPhoneForm] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        channel: "",
        recipient: "",
        topic: "",
        message: ""
    })
    let [errorMaxFileSize, setErrorMaxFileSize] = useState("");

    useEffect(() => {
        if (temporaryContactList.search === contactSearchVal) {
            setContactList(temporaryContactList.list);
        }
    }, [temporaryContactList])

    // useEffect(() => {
    //     let _conversationData = {...conversationData};

    //     if(props.history.location.state) {
    //         let _startConversation = props.history.location.state.startConversation; 
    //         if(_startConversation) {
    //             _conversationData.recipient = _startConversation.recipient;
    //         }

    //         setConversationData(_conversationData);
    //     }
    // }, [props.history])

    useEffect(() => {
        let _conversationData = { ...conversationData };

        let _localnm = localStorage.getItem("nm");

        if (_localnm && props.isShowModalStartConversationProps) {
            let _startConversation = JSON.parse(_localnm);

            if (_startConversation) {
                _conversationData.recipient = _startConversation.recipient;
                _conversationData.isFromContact = true;
            }

            setConversationData(_conversationData);

            setTimeout(() => {
                localStorage.removeItem("nm");
            }, 5000)
        }

        if (props.closeCaseNewMessageRecipient.lastClick) {
            _conversationData.recipient = { ...props.closeCaseNewMessageRecipient.recipient };
            _conversationData.isFromContact = true;

            setConversationData(_conversationData);
        }

        if (props.isShowModalStartConversationProps) {
            getChannelList();
        }
    }, [props.isShowModalStartConversationProps])

    useEffect(() => {
        if (temporaryFileValue.time) {
            let _temporaryFileValue = { ...temporaryFileValue };
            let _conversationData = { ...conversationData };

            _conversationData.fileAsset.fileName = _temporaryFileValue.fileAsset.fileName;
            _conversationData.fileAsset.fileURL = _temporaryFileValue.fileAsset.fileURL;
            _conversationData.fileAsset.fileData = _temporaryFileValue.fileAsset.fileData;
            setConversationData(_conversationData);
        }
    }, [temporaryFileValue])

    useEffect(() => {
        if (props.countryList) {
            let newCountryList = [];

            props.countryList.map((value) => {
                let country = {
                    id: value.id,
                    countryCodeNumber: value.callingCode,
                    countryCode: value.iso3Code,
                    countryName: value.commonName,
                    countryFlag: value.flagIconURL,
                    countryLabel: `${value.commonName} (+${value.callingCode})`
                };

                newCountryList.push(country);

                return null;
            })

            setCountryList(newCountryList)
        }
    }, [props.countcryList])

    let resetValue = () => {
        setIsLoadingWhatsappStatus(false);
        setConversationData({
            channelMedium: LIST_CH_TYPE[0],
            channel: {
                label: "",
                value: ""
            },
            recipient: {
                country: {
                    id: "0",
                    countryCodeNumber: "62",
                    countryCode: "IDN",
                    countryName: "Indonesia",
                    countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
                },
                number: "",
                contact: {
                    name: "",
                    number: ""
                }
            },
            message: "",
            messageType: MESSAGE_TYPE.text,
            fileAsset: {
                fileURL: "",
                fileName: "",
                fileData: false,
                caption: ""
            },
            topic: {
                value: "",
                label: ""
            },
            type: ""
        });

        setTemporaryFileValue({
            fileAsset: {},
            time: false
        })

        setErrorFieldNumber("");
    }

    let onSelectingMedia = (e) => {
        let files = [];
        files.push(e.target.files[0]);

        HelperUpload.checkFileAllowed(files, {
            onAllowed: () => {
                HelperUpload.checkFileLimit(files, {
                    onAllowed: () => {
                        HelperUpload.checkFileSizeLimitForMedia([], files, {
                            onAllowed: (arrayOfMedia) => {
                                let _errorMessageData = { ...errorMessageData };
                                let _conversationData = { ...conversationData };

                                if (arrayOfMedia[0].type.split("/")[0] === "video") {
                                    _conversationData.messageType = MESSAGE_TYPE.video;
                                    _conversationData.fileAsset.fileData = arrayOfMedia;
                                    _conversationData.type = MESSAGE_TYPE.video;
                                    setConversationData(_conversationData);
                                } else {
                                    runImageCompress(arrayOfMedia[0], (res) => {
                                        arrayOfMedia[0] = res;
                                        _conversationData.messageType = MESSAGE_TYPE.image;
                                        _conversationData.fileAsset.fileData = arrayOfMedia;
                                        _conversationData.type = MESSAGE_TYPE.image;
                                        setConversationData(_conversationData);
                                    })
                                }

                                _errorMessageData.message = "";
                                setErrorMessageData(_errorMessageData);
                                setErrorMaxFileSize("");
                            },
                            onReachLimit: (message) => {
                                setErrorMaxFileSize("Error max size")
                                doToast("Upload Failed", "fail");
                            }
                        }, WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize))
                    },
                    onReachLimit: (message) => {
                        doToast(message, "fail");
                    }
                })
            },
            onFileTypeDidntMatch: (message) => {
                doToast(message, "fail");
            }
        }, "image or video")

        e.target.value = null;
    }

    let onSelectDocument = (e) => {
        let files = [];
        files.push(e.target.files[0]);

        HelperUpload.checkFileLimit(files, {
            onAllowed: () => {
                HelperUpload.checkFileSizeLimitForMedia([], files, {
                    onAllowed: (arrayOfMedia) => {
                        let _errorMessageData = { ...errorMessageData };
                        let _conversationData = { ...conversationData };

                        _conversationData.messageType = MESSAGE_TYPE.file;
                        _conversationData.type = MESSAGE_TYPE.file;
                        _conversationData.fileAsset.fileData = arrayOfMedia;
                        setConversationData(_conversationData);
                        _errorMessageData.message = "";
                        setErrorMessageData(_errorMessageData);
                        setErrorMaxFileSize("");
                    },
                    onReachLimit: (message) => {
                        setErrorMaxFileSize("Error max size")
                        doToast("Upload Failed", "fail");
                    }
                }, WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize))
            },
            onReachLimit: (message) => {
                doToast(message, "fail");
            }
        })

        e.target.value = null;
    }

    let onChangeAssetFile = (fileData) => {
        let _temporaryFileValue = { ...temporaryFileValue };
        _temporaryFileValue.fileAsset.fileURL = fileData.fileURL;
        _temporaryFileValue.fileAsset.fileName = fileData.fileName;
        _temporaryFileValue.fileAsset.fileData = fileData.fileData;
        _temporaryFileValue.time = new Date().valueOf();

        setTemporaryFileValue(_temporaryFileValue);
    }

    let onChangeConversationMessage = (e) => {
        let _conversationData = { ...conversationData };
        let _errorMessageData = { ...errorMessageData };

        if (e.target.id === "phoneNumber") {
            let val = e.target.value.replaceAll(" ", "");
            if (HelperInput.numberOnly(val)) {
                _conversationData.recipient.number = HelperInput.phoneBeautify(val, true);
                setErrorFieldNumber("");

                if ((_conversationData.recipient.number.replaceAll(" ", "").length + _conversationData.recipient.country.countryCodeNumber.length) > 15) {
                    setErrorFieldNumber("Characters exceed limit");
                }
            }
        } else if (e.target.id === "caption") {
            _conversationData.fileAsset.caption = e.target.value;
        } else {
            _errorMessageData[e.target.id] = "";
            _conversationData[e.target.id] = e.target.value;
        }

        setErrorMessageData(_errorMessageData);
        setConversationData(_conversationData);
        setErrorMaxFileSize("");
    };

    let onCloseClick = () => {
        if (!isLoadingWhatsappStatus) {
            setErrorMessageData({
                channel: "",
                topic: "",
                recipient: "",
                message: ""
            })
            resetValue();

            props.toggleModalStartConversationProps("sme");

            // let _history = { ...props.history.location.state };
            // delete _history.startConversation;

            // props.history.replace({
            //     state: _history
            // });
        }

        setToggleAddPhoneForm(false);
    }

    let onBlurAction = () => {
        let _errorFieldNumber = errorFieldNumber.slice();
        let errorMessage = "";

        // let phoneLength = (conversationData.recipient.country.countryCodeNumber + conversationData.recipient.number).replaceAll(" ", "").length;

        if (conversationData.recipient.number[0] === "0") {
            errorMessage = ERROR_MESSAGE_NUMBER.isStartZero;
        }

        // if(phoneLength > conversationData.recipient.country.countryCodeNumber.length && (phoneLength < 7 || phoneLength > 15)) {
        //     errorMessage = ERROR_MESSAGE_NUMBER.length;
        // }

        if (!REG_NUMERIC.test(conversationData.recipient.number.replaceAll(" ", ""))) {
            errorMessage = ERROR_MESSAGE_NUMBER.isNumeric;
        }

        _errorFieldNumber = errorMessage;
        setErrorFieldNumber(_errorFieldNumber);
    }

    let getChannelList = () => {
        let isTopicExist = (chTopics) => {
            let exist = false;

            if (props.topicListProps) {
                chTopics.map((v) => {
                    if (props.topicListProps[v.id]) {
                        exist = true;
                    }

                    return null;
                })
            }

            return exist;
        }


        BroadcastMessageServices.getAllSmeWabaChannelList(props.orgIDProps, (response) => {
            // IntegrationWhatsappServices.getList(props.orgIDProps, {search: "", channelStatus: ""}, (response) => {
            let dataResult = response.dataResult;

            if (response.dataResult.error.message === "") {
                let _channelList = [];
                let channels = dataResult.data.whatsapp.slice();
                let newList = [];

                if (channels.length > 0) {
                    channels.map((value) => {
                        if (isTopicExist(value.topics)) {
                            _channelList.push({
                                value: value.id,
                                label: value.accountName,
                                channelStatus: value.channelStatus,
                                channelStatusText: value.channelStatusText,
                                isEnabled: value.isEnabled,
                                //topicID: value.topicID,
                                topics: value.topics
                            })
                        }

                        return null;
                    })

                    _channelList.map((v, i) => {
                        if (v.channelStatus === "active" || v.channelStatus === "authenticated") {
                            newList.push(v);
                            _channelList.splice(i, 1);
                        }

                        return null;
                    })

                    setChannelList(newList.concat(_channelList));
                }

            } else {
                doToast(dataResult.error.message, "fail");
            }
        })
    }

    let onChangeChannel = (e) => {
        let _conversationData = { ...conversationData };
        _conversationData.channel = e;
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.channel = "";
        setErrorMessageData(_errorMessageData);
        setConversationData(_conversationData);
    }

    let onChangeChannelMedium = (e) => {
        if (e.value === "whatsappba") {
            props.changeToMedium("whatsappba");
        }
    }

    let onChangeCountryCode = (data) => {
        let _conversationData = { ...conversationData };
        _conversationData.recipient.country = data;
        setConversationData(_conversationData);
    }

    let messageType = () => {
        let _conversationData = { ...conversationData };

        // let _messageType = MESSAGE_TYPE.text;

        // if(_conversationData.fileAsset.fileURL !== "") {
        //     _messageType = MESSAGE_TYPE.image;
        // }

        return _conversationData.messageType;
    }

    let getWhatsappPriceList = () => {
        setIsLoadingWhatsappStatus(true);

        StartConversationServices.postGetPriceList(props.orgIDProps, (response) => {
            let dataResult = response.dataResult;
            let dataResultData = dataResult.data;

            if (dataResult.status === 200) {
                let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                _modalStartConversationBalance.confirmation.show = true;
                _modalStartConversationBalance.confirmation.participant = 1;
                _modalStartConversationBalance.confirmation.isLoading = false;
                _modalStartConversationBalance.confirmation.priceList = dataResultData;
                _modalStartConversationBalance.confirmation.messageType = messageType();
                _modalStartConversationBalance.confirmation.confirmAction = () => {
                    onSubmitNewConversation("send");
                };

                props.setModalStartConversationBalance(_modalStartConversationBalance);
                setIsLoadingWhatsappStatus(false);
                onCloseClick();
            } else {
                doToast(dataResult.error.message, "fail")
                setIsLoadingWhatsappStatus(false);
            }
        })
    }

    let runSendMessage = (forceText = false, callback = false) => {
        let _conversationData = { ...conversationData };

        let dataChat = {
            channelID: _conversationData.channel.value,
            phone: _conversationData.recipient.contact.number.replace("+", ""),
            messageType: forceText ? "text" : messageType(),
            body: (forceText ? _conversationData.fileAsset.caption : (messageType() === MESSAGE_TYPE.video || messageType() === MESSAGE_TYPE.image || messageType() === MESSAGE_TYPE.file) ? _conversationData.fileAsset.fileURL : _conversationData.message),
            filename: forceText ? "" : _conversationData.fileAsset.fileName,
            caption: messageType() === MESSAGE_TYPE.file ? "" : _conversationData.fileAsset.caption,
            topicID: parseInt(_conversationData.topic.value)
        }

        StartConversationServices.postSendMessageWhatsapp(props.orgIDProps, dataChat, (response) => {
            let dataResult = response.dataResult;
            let dataResultData = dataResult.data;

            if (dataResult.status === 200) {
                if (dataResultData.success) {
                    if (!forceText) {
                        let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
                        _closeCaseNewMessage.lastClick = false;

                        props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);

                        tapCoreRoomListManager.getRoomByXcID(dataResultData.case.tapTalkXCRoomID, {
                            onSuccess: (response) => {
                                props.setToggleMineCase(true);

                                if (props.activeRoom === null) {
                                    props.onClickRoomListWithTaptalkRoomProps(response.room, dataResultData.case);
                                } else {
                                    if (props.activeRoom.roomID !== response.room.roomID) {
                                        props.onClickRoomListWithTaptalkRoomProps(response.room, dataResultData.case);
                                    }
                                }
                                let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                                _modalStartConversationBalance.confirmation.show = false;
                                props.setModalStartConversationBalance(_modalStartConversationBalance);

                                doToast("Successfully send the message");

                                if (messageType() !== MESSAGE_TYPE.file) {
                                    resetValue();
                                }

                                if (messageType() === MESSAGE_TYPE.file && !forceText && _conversationData.fileAsset.caption !== "") {
                                    runSendMessage(true, () => {
                                        resetValue();
                                    })
                                }

                                if (_conversationData.fileAsset.caption === "") {
                                    resetValue();
                                }

                                props.toggleModalSendingProps(false);
                            },
                            onError: (errorCode, errorMessage) => {
                                let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                                _modalStartConversationBalance.confirmation.show = false;
                                props.setModalStartConversationBalance(_modalStartConversationBalance);

                                console.log(errorCode, errorMessage);
                                doToast(errorMessage, "fail")
                                props.toggleModalSendingProps(false);
                            }
                        })
                    }

                    if (callback) {
                        callback();
                    }
                } else {
                    if (dataResultData.reason === FAIL_SEND_MESSAGE_REASON.insufficient_balance) {
                        let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                        _modalStartConversationBalance.confirmation.show = false;
                        _modalStartConversationBalance.confirmation.isLoading = false;
                        _modalStartConversationBalance.topupBalance.show = true;

                        props.setModalStartConversationBalance(_modalStartConversationBalance);
                    } else {
                        doToast(dataResultData.message, "fail");
                    }

                    props.toggleModalSendingProps(false);
                }

                props.clearStartConversation();
            } else {
                doToast(dataResult.error.message, "fail");
                props.toggleModalSendingProps(false);
            }

            setIsLoadingWhatsappStatus(false);
        })
    }

    let onSubmitNewConversation = (isSendNow = "no") => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = { ...errorMessageData };

        if (conversationData.channel.value === "") {
            _errorMessageData.channel = "This field is required";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-channel" }
        }

        if (conversationData.recipient.number === "" && conversationData.recipient.contact.number === "") {
            _errorMessageData.recipient = "Please input recipient";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-recipient" }
        }

        if (conversationData.topic.value === "") {
            _errorMessageData.topic = "Please select topic";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-topic" }
        }

        if (conversationData.message === "" && conversationData.fileAsset.fileURL === "") {
            _errorMessageData.message = "Please input message content";
            errorCount = errorCount + 1;

            if (errorClass === "") { errorClass = "input-message" }
        }

        if (errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        } else {
            setIsLoadingWhatsappStatus(true);
            let _conversationData = { ...conversationData };

            let data = {
                channelID: _conversationData.channel.value,
                phone: _conversationData.recipient.contact.number.replace("+", ""),
            }

            StartConversationServices.postCheckStatusWhatsapp(props.orgIDProps, data, (response) => {
                let dataResult = response.dataResult;
                let dataResultData = dataResult.data;
                let _startConversation = { ...props.startConversation };
                let myAgentAccountID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;

                if (dataResult.status === 200) {
                    if (!dataResultData.canSend && !dataResultData.isValidNumber) {
                        let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                        _modalStartConversationBalance.confirmation.show = false;
                        _modalStartConversationBalance.confirmation.isLoading = false;

                        props.setModalStartConversationBalance(_modalStartConversationBalance);

                        doToast("Can't send, phone number is not valid", "fail");
                        setIsLoadingWhatsappStatus(false);
                    }

                    props.clearStartConversationBa();

                    if (
                        dataResultData.openCase.id === 0 &&
                        (!props.topicListProps[dataResultData.openCase.topicID] && dataResultData.openCase.topicID !== 0) && dataResultData.canSend
                    ) {
                        let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                        _modalStartConversationBalance.confirmation.show = false;
                        _modalStartConversationBalance.confirmation.isLoading = false;

                        props.setModalStartConversationBalance(_modalStartConversationBalance);

                        _startConversation.openCase = {
                            topicID: conversationData.topic.value,
                            agentAccountID: ""
                        };
                        _startConversation.conversation = conversationData;
                        _startConversation.conversation.type = "";
                        onCloseClick();
                        props.setStartConversation(_startConversation);
                    }

                    if (dataResultData.openCase.id !== 0 || isSendNow === "no") {
                        // doToast("Recipient has ongoing case", "fail");
                        if (!props.topicListProps[dataResultData.openCase.topicID]) {
                            let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                            _modalStartConversationBalance.confirmation.show = false;
                            _modalStartConversationBalance.confirmation.isLoading = false;

                            props.setModalStartConversationBalance(_modalStartConversationBalance);

                            _startConversation.openCase = dataResultData.openCase;
                            _startConversation.conversation = conversationData;
                            props.setStartConversation(_startConversation);
                            onCloseClick();
                            setIsLoadingWhatsappStatus(false);
                        } else {
                            let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
                            _closeCaseNewMessage.lastClick = false;

                            props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);

                            tapCoreRoomListManager.getRoomByXcID(dataResultData.openCase.tapTalkXCRoomID, {
                                onSuccess: (response) => {
                                    let goToChatRoom = () => {
                                        if (props.activeRoom === null) {
                                            props.onClickRoomListWithTaptalkRoomProps(response.room, dataResultData.openCase, false);
                                        } else {
                                            if (props.activeRoom.roomID !== response.room.roomID) {
                                                props.onClickRoomListWithTaptalkRoomProps(response.room, dataResultData.openCase, false);
                                            }
                                        }
                                    }

                                    _startConversation.conversation = conversationData;
                                    _startConversation.openCase = dataResultData.openCase;
                                    props.setStartConversation(_startConversation);

                                    if (dataResultData.openCase.agentAccountID === 0) {
                                        props.setActiveCasePanel("open");
                                        props.setToggleMineCase(false);
                                        goToChatRoom();
                                    } else if (dataResultData.openCase.agentAccountID === myAgentAccountID) {
                                        props.setActiveCasePanel("open");
                                        props.setToggleMineCase(true);
                                        goToChatRoom();
                                    } else {
                                        if (props.showOtherCase.configShow) {
                                            props.setActiveCasePanel("other");
                                            goToChatRoom();
                                        } else {
                                            let _showOther = { ...props.showOtherCase };
                                            _showOther.modalWarningHideOther = true;
                                            _showOther.case = dataResultData.openCase;
                                            props.setShowOtherCase(_showOther);
                                        }
                                    }

                                    let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                                    _modalStartConversationBalance.confirmation.show = false;
                                    _modalStartConversationBalance.confirmation.isLoading = false;

                                    props.setModalStartConversationBalance(_modalStartConversationBalance);

                                    onCloseClick();
                                    setIsLoadingWhatsappStatus(false);
                                },
                                onError: (errorCode, errorMessage) => {
                                    let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                                    _modalStartConversationBalance.confirmation.show = false;
                                    _modalStartConversationBalance.confirmation.isLoading = false;

                                    props.setModalStartConversationBalance(_modalStartConversationBalance);

                                    console.log(errorCode, errorMessage);
                                    doToast(errorMessage, "fail");
                                    setIsLoadingWhatsappStatus(false);
                                }
                            })
                        }
                    }

                    if (
                        props.topicListProps[conversationData.topic.value] &&
                        dataResultData.canSend &&
                        dataResultData.isValidNumber &&
                        dataResultData.openCase.id === 0
                    ) {
                        onCloseClick();
                        if (isSendNow === "check") {
                            getWhatsappPriceList();
                        } else {
                            props.toggleModalSendingProps(true);
                            runSendMessage();
                        }
                    }
                } else {
                    let _modalStartConversationBalance = { ...props.modalStartConversationBalance };
                    _modalStartConversationBalance.confirmation.show = false;

                    props.setModalStartConversationBalance(_modalStartConversationBalance);

                    doToast(dataResult.error.message, "fail");

                    setIsLoadingWhatsappStatus(false);
                }
            })
        }
    }

    let onRemoveFile = () => {
        let _conversationData = { ...conversationData };

        let newFile = {
            fileURL: "",
            fileName: "",
            fileData: false,
            caption: "",
        };

        _conversationData.message = "";
        _conversationData.messageType = MESSAGE_TYPE.text;
        _conversationData.fileAsset = newFile;
        _conversationData.type = "";
        setConversationData(_conversationData);
    }

    // let isDisabledSubmit = () => {
    //     let _conversationData = {...conversationData};
    //     let isDisabled = false;
    //     // let phoneLength = (conversationData.recipient.country.countryCodeNumber + conversationData.recipient.number).length;

    //     if(_conversationData.channel.value === "") {
    //         isDisabled = true;
    //     }

    //     if(conversationData.recipient.contact.number === "") {
    //         isDisabled = true;
    //     }

    //     if(conversationData.topic.value === "") {
    //         isDisabled = true;
    //     }

    //     if(_conversationData.fileAsset.fileData) {
    //         if(_conversationData.fileAsset.fileURL === "") {
    //             isDisabled = true;
    //         }
    //     }else {
    //         if(_conversationData.message === "") {
    //             isDisabled = true;
    //         }
    //     }

    //     return isDisabled;
    // }

    // let disabledAddPhoneNumber = () => {
    //     let isDisabled = false;
    //     let phoneLength = (conversationData.recipient.country.countryCodeNumber + conversationData.recipient.number).replaceAll(" ", "").length;

    //     if(conversationData.recipient.number[0] === "0") {
    //         isDisabled = true;
    //     }

    //     if(phoneLength < 7 || phoneLength > 15) {
    //         isDisabled = true;
    //     }

    //     if(!REG_NUMERIC.test(conversationData.recipient.number.replaceAll(" ", ""))) {
    //         isDisabled = true;
    //     }

    //     return isDisabled;
    // }

    let onChangeSearchContact = (e) => {
        runGetContactList(e.target.value)
        setContactSearchVal(e.target.value);
    }

    let runToggleAddPhoneForm = () => {
        let _conversationData = { ...conversationData };
        setContactSearchVal("");
        setToggleAddPhoneForm(!toggleAddPhoneForm);
        _conversationData.recipient = {
            "country": {
                "id": 1,
                "countryCodeNumber": "62",
                "countryCode": "IDN",
                "countryName": "Indonesia",
                "countryFlag": "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png",
                "countryLabel": "Indonesia (+62)"
            },
            "number": "",
            "contact": {
                "name": "",
                "number": ""
            }
        };

        setConversationData(_conversationData);
        setErrorFieldNumber("");
    }

    let submitAddPhoneNumber = () => {
        let errorCount = 0;

        if (conversationData.recipient.number === "") {
            setErrorFieldNumber("This field is required");
            errorCount = errorCount + 1;
        }

        if ((conversationData.recipient.number.replaceAll(" ", "").length + conversationData.recipient.country.countryCodeNumber.length) > 15) {
            setErrorFieldNumber("Characters exceed limit");
            errorCount = errorCount + 1;
        }

        if (errorCount === 0 && errorFieldNumber === "") {
            let _conversationData = { ...conversationData };
            let _errorMessageData = { ...errorMessageData };
            _errorMessageData.recipient = "";
            setErrorMessageData(_errorMessageData);

            _conversationData.recipient.contact = {
                name: "",
                alias: "",
                number: `+${_conversationData.recipient.country.countryCodeNumber}${_conversationData.recipient.number.replaceAll(" ", "")}`,
                maskedPhone: "",
                isFromAdd: true
            }

            setConversationData(_conversationData);
            // runToggleAddPhoneForm();
            setToggleAddPhoneForm(!toggleAddPhoneForm);
        }
    }

    let clearContactNumber = () => {
        let _conversationData = { ...conversationData };
        _conversationData.recipient.contact = {
            name: "",
            number: ""
        }

        setConversationData(_conversationData);
    }

    let runGetContactList = (search) => {
        let data = {
            search: search,
            pageNumber: 1,
            pageSize: 1000
        }

        UserContactServices.getContactWhatsapp(props.orgIDProps, data, (response) => {
            if (response.dataResult.status === 200) {
                let dataResult = response.dataResult;
                let newData = {
                    search: search,
                    list: dataResult.data.users,
                    time: new Date().valueOf()
                }

                setTemporaryContactList(newData);
                // setContactList(dataResult.data.users);
            }
        });
    }

    let onClickContact = (contact) => {
        let _conversationData = { ...conversationData };
        _conversationData.recipient.contact.name = contact.fullName;
        _conversationData.recipient.contact.alias = contact.alias;
        _conversationData.recipient.contact.number = "+" + contact.phone;
        _conversationData.recipient.contact.maskedPhone = "+" + (contact.maskedPhone ? contact.maskedPhone : contact.phone);
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.recipient = "";
        setErrorMessageData(_errorMessageData);

        setContactList([]);
        setContactSearchVal("");
        setConversationData(_conversationData);
    }

    let onSelectTopic = (value) => {
        let _conversationData = { ...conversationData };
        _conversationData.topic = value;
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData.topic = "";
        setErrorMessageData(_errorMessageData);
        setConversationData(_conversationData);
    };

    let getListTopic = () => {
        let list = [];
        let _conversationData = { ...conversationData };
        if (_conversationData.channel.topics) {
            Object.keys(props.topicListProps).map(v => {
                if (_conversationData.channel.topics.findIndex(e => e.id === parseInt(v)) !== -1) {
                    list.push(props.topicListProps[v]);
                }

                return null;
            })
        }

        return list;
    }

    return (
        // <Modal className="modal-new-conversation" isOpen={props.isShowModalStartConversationProps} toggle={onCloseClick}>
        //     <ModalBody> 

        <>
            {!toggleAddPhoneForm ?
                // main form
                <>
                    <div className={`new-conversation-wrapper ${!props.isShowModalStartConversation ? "force-hide" : ""}`}>
                        <Scrollbars autoHideTimeout={500}
                            renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
                        >
                            <div className="new-conversation-container-1">
                                <div className="new-conversation-title">
                                    <img src={IconPencilNew} alt="" />

                                    <b>New Message</b>
                                    <p>
                                        Send a new message to a single recipient through one of your active channels.
                                    </p>
                                </div>

                                <div className="">
                                    <label className='margin-top-24'><b>Channel Type</b></label>

                                    {/* <p className='channel-type'>
                                    <img src={TAPLIVE_MEDIUM_LOGO[TAPLIVE_MEDIUM.whatsapp]} alt="" />
                                    WhatsApp SME
                                </p> */}

                                    <CustomSelectOption
                                        optionListProps={LIST_CH_TYPE}
                                        valueDropdownProps={conversationData.channelMedium}
                                        placeholderProps={"Select Channel"}
                                        onClickDropDownListOptionProps={onChangeChannelMedium}
                                        specificClassNameProps="custom-select-channel"
                                        isDisabled={isLoadingWhatsappStatus}
                                    />
                                </div>

                                <div className="form-content-wrapper">
                                    <label className={`input-channel`}><b>Channel</b></label>
                                </div>


                                {/* <CustomSelectOption
                                    optionListProps={channelList}
                                    valueDropdownProps={conversationData.channel}
                                    placeholderProps={"Select Channel"}
                                    onClickDropDownListOptionProps={onChangeChannel}
                                    specificClassNameProps="custom-select-channel"
                                    isDisabled={isLoadingWhatsappStatus}
                                    _className={errorMessageData.channel !== "" ? "border-red" : ""}
                                /> */}

                                <CustomReactSelect
                                    isWabaOrSmeChannel
                                    options={channelList}
                                    placeholder="Select Channel"
                                    onChange={onChangeChannel}
                                    classNamePrefix="custom-react-select"
                                    isDisabled={isLoadingWhatsappStatus}
                                    value={channelList.filter(
                                        (obj) => obj.value === conversationData.channel.value
                                    )}
                                    blurInputOnSelect={true}
                                    _className={errorMessageData.channel !== "" ? "border-red" : ""}
                                />

                                {errorMessageData.channel !== "" &&
                                    <ErrorOrInfoComp
                                        text={errorMessageData.channel}
                                        _className={"font-red"}
                                        icon={<FiInfo />}
                                    />
                                }

                                {/* <div className="form-content-wrapper">
                                        <div className="form-label">
                                            <p><b>Country</b></p>
                                            <p><b>Phone Number</b></p>
                                        </div>
                                    </div> */}

                                {/* <div className="phone-number-form">
                                        <PhoneCodePicker 
                                            countryListProps={countryList}
                                            indexPhoneCodePickerProps={0}
                                            countryProps={conversationData.recipient.country}
                                            onChangeCountryCodeProps={onChangeCountryCode}
                                            isMultipleProps={false}
                                        />
                                                                    
                                        <div className="phone-wrapper">
                                            <input 
                                                type="text" 
                                                placeholder="ex: 1234 567 890" 
                                                onChange={(e) => onChangeConversationMessage(e)} 
                                                value={conversationData.recipient.number}
                                                onBlur={() => onBlurAction()}
                                                id="phoneNumber"
                                                maxLength="15"
                                            />

                                            {errorFieldNumber !== "" &&
                                                <p>
                                                    <FiAlertCircle />
                                                    <b>{errorFieldNumber}</b>
                                                </p>
                                            }
                                        </div>
                                    </div> */}

                                <div className="start-conversation-contact-wrapper">
                                    <div className="form-content-wrapper">
                                        <label className={`input-recipient`}><b>Recipient</b></label>

                                        {conversationData.recipient.contact.number === "" &&
                                            <div className="light-orange-button add-by-phone-number" onClick={() => runToggleAddPhoneForm()}>
                                                <FiPlus />
                                                Add by Phone Number
                                            </div>
                                        }
                                    </div>

                                    <>
                                        {conversationData.isFromContact ?
                                            <div className='conversation-data-contact-wrapper'>
                                                <p>{conversationData.recipient.contact.name}</p>
                                            </div>
                                            :
                                            <>
                                                <div className="search-contact-wrapper">
                                                    {conversationData.recipient.contact.number === "" &&
                                                        <>
                                                            <input
                                                                type="text"
                                                                onChange={(e) => onChangeSearchContact(e)}
                                                                value={contactSearchVal}
                                                                placeholder="Search for a contact"
                                                                className={errorMessageData.recipient !== "" ? "border-red" : ""}
                                                            />
                                                            <FiSearch />
                                                        </>
                                                    }

                                                    {contactSearchVal !== "" &&
                                                        <div className="dropdown-contact-list">
                                                            {contactList.length === 0 ?
                                                                <div className="option-contact no-contact-found">
                                                                    No Contact Found
                                                                </div>
                                                                :
                                                                contactList.map((value, index) => {
                                                                    return (
                                                                        <div
                                                                            className={`option-contact ${conversationData.recipient.contact.number === value.phone ? "selected-number-active" : ""}`}
                                                                            onClick={() => onClickContact(value)}
                                                                            key={`contact-${index}`}
                                                                        >
                                                                            {value.alias === "" ? value.fullName : `${value.alias}(${value.fullName})`}
                                                                            <span>+{HelperInput.phoneBeautify(value.maskedPhone ? value.maskedPhone : value.phone)}</span>
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                                {conversationData.recipient.contact.number !== "" &&
                                                    <div className="selected-contact">
                                                        {conversationData.recipient.contact.isFromAdd ?
                                                            <>
                                                                <FiPhone />
                                                                <b>{conversationData.recipient.contact.alias === "" ? conversationData.recipient.contact.name : `${conversationData.recipient.contact.alias}(${conversationData.recipient.contact.name})`} +{HelperInput.phoneBeautify(conversationData.recipient.contact.maskedPhone ? conversationData.recipient.contact.maskedPhone : conversationData.recipient.contact.number)}</b>
                                                            </>
                                                            :
                                                            <>
                                                                <FiUser />
                                                                <b>
                                                                    {conversationData.recipient.contact.alias === "" ?
                                                                        conversationData.recipient.contact.name
                                                                        :
                                                                        `${conversationData.recipient.contact.alias}(${conversationData.recipient.contact.name})`
                                                                    }
                                                                </b>
                                                            </>
                                                        }

                                                        <div className="remove-contact" onClick={clearContactNumber}>
                                                            <FiX />
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </>

                                    {errorMessageData.recipient !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.recipient}
                                            _className={"font-red"}
                                            icon={<FiInfo />}
                                        />
                                    }
                                    {/* 
                                        <div className="add-phone-number-info">
                                            <FiAlertCircle />
                                            <b>User contact’s with case history in the selected channel will be displayed.</b>
                                        </div> 
                                        */}
                                </div>

                                <div className="form-content-wrapper">
                                    <label className={`input-topic`}>
                                        <b>Topic</b>
                                    </label>

                                    <CustomReactSelect
                                        options={getListTopic()}
                                        placeholder={conversationData.channel.value !== "" ? 'Select topic' : 'Select channel first'}
                                        onChange={onSelectTopic}
                                        classNamePrefix="custom-react-select"
                                        isDisabled={conversationData.channel.value === "" || isLoadingWhatsappStatus}
                                        value={getListTopic().filter(
                                            (obj) => obj.value === conversationData.topic.value
                                        )}
                                        blurInputOnSelect={true}
                                        _className={errorMessageData.topic !== "" ? "border-red" : ""}
                                    />
                                    
                                    {errorMessageData.topic !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.topic}
                                            _className={"font-red"}
                                            icon={<FiInfo />}
                                        />
                                    }
                                </div>

                                <div className="form-content-wrapper">
                                    <>
                                        <label className={`input-message`}>
                                            <b>Message</b>
                                            {/* <span> (image optional)</span> */}
                                        </label>

                                        {conversationData.messageType === MESSAGE_TYPE.text ?
                                            <>
                                                <label htmlFor="upload-image-1" className="button-upload-image">
                                                    <FiImage />
                                                    <b>Upload Media</b>
                                                </label>
                                                <input type="file" id="upload-image-1" accept="image/*, video/*" onChange={(e) => onSelectingMedia(e)} />

                                                <label htmlFor="upload-file-1" className="button-upload-image">
                                                    <FiFileText />
                                                    <b>Upload Document</b>
                                                </label>
                                                <input type="file" id="upload-file-1" onChange={(e) => onSelectDocument(e)} />
                                            </>
                                            :
                                            <>
                                                <label htmlFor="upload-image-1" className="button-upload-image-dis">
                                                    <FiImage />
                                                    <b>Upload Media</b>
                                                </label>

                                                <label htmlFor="upload-file-1" className="button-upload-image-dis">
                                                    <FiFileText />
                                                    <b>Upload Document</b>
                                                </label>
                                            </>
                                        }

                                        {conversationData.messageType === MESSAGE_TYPE.text &&
                                            <textarea
                                                disabled={isLoadingWhatsappStatus}
                                                value={conversationData.message}
                                                id="message"
                                                onChange={(e) => onChangeConversationMessage(e)}
                                                placeholder="Enter message here..."
                                                className={errorMessageData.message !== "" ? "border-red" : ""}
                                            />
                                        }
                                    </>

                                    {(conversationData.messageType === MESSAGE_TYPE.image || conversationData.messageType === MESSAGE_TYPE.video) &&
                                        <BroadcastMessageFormMediaMessage
                                            orgIDProps={props.orgIDProps}
                                            onRemoveFileAssetProps={onRemoveFile}
                                            broadCastMessageDataProps={conversationData.fileAsset}
                                            onChangeAssetFileProps={onChangeAssetFile}
                                            onChangeBroadcastMessageProps={onChangeConversationMessage}
                                            withoutRequestAuthTicketProps
                                            messageType={conversationData.messageType}
                                            caption={conversationData.fileAsset.caption}
                                            noTitle
                                        />
                                    }

                                    {conversationData.messageType === MESSAGE_TYPE.file &&
                                        <BroadcastMessageFormFileStartNewMessage
                                            orgIDProps={props.orgIDProps}
                                            onRemoveFileAssetProps={onRemoveFile}
                                            broadCastMessageDataProps={conversationData.fileAsset}
                                            onChangeAssetFileProps={onChangeAssetFile}
                                            onChangeBroadcastMessageProps={onChangeConversationMessage}
                                            messageType={conversationData.messageType}
                                            caption={conversationData.fileAsset.caption}
                                            withoutRequestAuthTicketProps
                                            _index={0}
                                        />
                                    }

                                    {errorMaxFileSize === "" ?
                                        <ErrorOrInfoComp
                                            text={errorMessageData.message !== "" ? errorMessageData.message : `Maximum upload media / document size: ${HelperChat.bytesToSize(WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize))}`}
                                            _className={errorMessageData.message !== "" ? "font-red margin-top-0" : "margin-top-0"}
                                            icon={<FiInfo />}
                                        />
                                        :
                                        <ErrorOrInfoComp
                                            text={`Selected media size exceeds ${HelperChat.bytesToSize(WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize))} upload limit`}
                                            _className={"red-text margin-top-0"}
                                            icon={<FiInfo />}
                                        />
                                    }

                                </div>
                            </div>
                        </Scrollbars>
                    </div>
                    <div className="send-message-button">
                        {!isLoadingWhatsappStatus ?
                            <ButtonWithLoadingOrIcon
                                // onClickAction={getWhatsappPriceList} 
                                // onClickAction={() => onSubmitNewConversation("check")}
                                onClickAction={() => onSubmitNewConversation("send")}
                                // isDisabled={isDisabledSubmit()}
                                className="orange-button main-button-48"
                                text="Send Message"
                                position="left"
                                icon={{
                                    type: "img",
                                    src: Airplane
                                }}
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                isLoading
                                loadingColor="gray"
                                isDisabled
                                className="orange-button main-button-48"
                                text="Send Message"
                                position="left"
                            />
                        }
                    </div>
                </>
                // main form
                :
                // add phone form
                <>
                    <div className='new-conversation-wrapper'>
                        <div className="new-conversation-container">
                            <BackButton
                                text="Back to New Message"
                                onClick={runToggleAddPhoneForm}
                            />

                            <div className="new-conversation-title">
                                <b>Add by Phone Number</b>
                            </div>

                            <div className="form-content-wrapper">
                                <div className="form-label form-label-phone">
                                    <p><b>Country</b></p>
                                    <p className='pos-relative'>
                                        <b>Phone Number</b>

                                        <span className={errorFieldNumber === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{conversationData.recipient.country.countryCodeNumber.length + conversationData.recipient.number.replaceAll(" ", "").length}/15</span>
                                    </p>
                                </div>
                            </div>

                            <div className="phone-number-form">
                                <PhoneCodePicker
                                    countryListProps={countryList}
                                    indexPhoneCodePickerProps={0}
                                    countryProps={conversationData.recipient.country}
                                    onChangeCountryCodeProps={onChangeCountryCode}
                                    isMultipleProps={false}
                                />

                                <div className="phone-wrapper">
                                    <input
                                        type="text"
                                        placeholder="ex: 1234 567 890"
                                        onChange={(e) => onChangeConversationMessage(e)}
                                        value={conversationData.recipient.number}
                                        onBlur={() => onBlurAction()}
                                        id="phoneNumber"
                                        className={errorFieldNumber !== "" ? "border-red" : ""}
                                    />

                                    {errorFieldNumber !== "" &&
                                        <p>
                                            <FiAlertCircle />
                                            <b>{errorFieldNumber}</b>
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="send-message-button">
                        <ButtonWithLoadingOrIcon
                            // isDisabled={disabledAddPhoneNumber()} 
                            onClickAction={() => submitAddPhoneNumber()}
                            className="orange-button main-button-48"
                            text="Add Phone Number"
                            position="left"
                        />
                    </div>
                </>
                // add phone form
            }
            {/* </ModalBody>
        </Modal> */}
        </>
    );
}

const mapStateToProps = state => ({
    countryList: state.countryList,
    startConversation: state.startConversation,
    toggleMineCase: state.toggleMineCase,
    activeRoom: state.activeRoom,
    priceList: state.priceList,
    modalStartConversationBalance: state.modalStartConversationBalance,
    showOtherCase: state.showOtherCase,
    closeCaseNewMessageRecipient: state.closeCaseNewMessageRecipient
});

const mapDispatchToProps = {
    setActiveCasePanel,
    setStartConversation,
    clearStartConversation,
    clearStartConversationBa,
    setToggleMineCase,
    setModalStartConversationBalance,
    clearModalStartConversationBalance,
    setShowOtherCase,
    setCloseCaseNewMessageRecipient
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalStartConversation);