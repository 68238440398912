import React, { useState, useEffect } from 'react';
import './RoomListTab.scss';
import { connect } from 'react-redux';
import { setActiveCasePanel } from '../../../redux/actions/reduxActionActiveCasePanel';
import { setQuickReplyList } from "../../../redux/actions/reduxActionQuickReplyList";
import ServiceQuickReply from "../../../services/newServices/QuickReplyService";
import { FiCircle, FiCheckCircle, FiUser } from "react-icons/fi";
import BotCaseTab from './botCaseTab/BotCaseTab';
import OpenCaseTab from './openCaseTab/OpenCaseTab';
import OtherCaseTab from './otherCaseTab/OtherCaseTab';
import SolvedCaseTab from './solvedCaseTab/SolvedCaseTab';
import { clearStartConversation } from '../../../redux/actions/reduxActionStartConversation';
import { clearCaseFilter } from "../../../redux/actions/reduxActionCaseFilter";
import { setCaseListChecked } from "../../../redux/actions/reduxActionCaseListChecked";
import { setCloseCaseNewMessageRecipient } from "../../../redux/actions/reduxActionCloseCaseNewMessageRecipient"
import mixpanel from "mixpanel-browser";
import GreyBot from "../../../assets/img/bot-grey.svg";
import BlackBot from "../../../assets/img/bot-black.svg";
import OrangeBot from "../../../assets/img/bot-orange.svg";

const ACTIVE_CASE_PANEL = {
    bot: 'bot',
    open: 'open',
    solved: 'solved',
    other: 'other'
};

var RoomListTab = (props) => {
    // let [isFetchingRoomListFinish, setIsFetchingRoomListFinish] = useState(true);
    let [mainChatData, setMainChatData] = useState(null);

    let onClickRoomListTab = (tab) => {
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Action] Switch Tab",
                {
                    tab: tab === "bot" ? "bot" : (tab === "open" ? "Me" : (tab === "other" ? "Others" : "Resolved")),
                    userFullName: props.myAgentData.account.fullName,
                    userID: props.myAgentData.account.id,
                    organizationID: props.parentProps.match.params.orgID.split("-")[0]
                }
            );
        }

        props.setActiveCasePanel(tab);
    }

    let setMainChatDataAction = (roomLists) => {
        setMainChatData(roomLists)
    }

    let getQuickReplyList = () => {
        ServiceQuickReply.getQuickReplyList(props.match.params.orgID, { channelType: "all" }, (response) => {
            let dataResult = response.dataResult;
            let quickReplyList = [];

            if (dataResult.error.message === "") {
                quickReplyList = quickReplyList.concat(dataResult.data.general, dataResult.data.personal);
                props.setQuickReplyList(quickReplyList);
            } else {
                // console.log(dataResult.error.message, 'fail');
            }
        })
    }

    useEffect(() => {
        props.clearCaseFilter();
        props.setCaseListChecked({
            globalSearch: {
                isShow: false,
                data: {},
                checkedAll: false
            },
            bot: {
                isShow: false,
                data: {},
                checkedAll: false
            },
            mine: {
                isShow: false,
                data: {},
                checkedAll: false
            },
            unassigned: {
                isShow: false,
                data: {},
                checkedAll: false
            },
            other: {
                isShow: false,
                data: {},
                checkedAll: false
            },
            solved: {
                isShow: false,
                data: {},
                checkedAll: false
            },
        })
    }, [props.activeCasePanel])

    return (
        <React.Fragment>
            {props.activeCasePanel === ACTIVE_CASE_PANEL.bot &&
                <BotCaseTab
                    onClickRoomListProps={props.onClickRoomListProps}
                    onClickRoomListWithTaptalkRoomProps={props.onClickRoomListWithTaptalkRoomProps}
                    parentProps={props.parentProps}
                    messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                    messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                    listenerStartTypingProps={props.listenerStartTypingProps}
                    listenerStopTypingProps={props.listenerStopTypingProps}
                    newEmitMessageProps={props.newEmitMessageProps}
                    topicListProps={props.topicListProps}
                    isFetchingRoomListFinishProps={true}
                    mainChatDataProps={mainChatData}
                    setMainChatDataActionProps={setMainChatDataAction}
                    connectingStatusProps={props.connectingStatusProps}
                    _changeContactInfo={props._changeContactInfo}
                />
            }

            {props.activeCasePanel === ACTIVE_CASE_PANEL.open &&
                <OpenCaseTab
                    {...props}
                    onClickRoomListProps={props.onClickRoomListProps}
                    onClickRoomListWithTaptalkRoomProps={props.onClickRoomListWithTaptalkRoomProps}
                    parentProps={props.parentProps}
                    messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                    messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                    listenerStartTypingProps={props.listenerStartTypingProps}
                    listenerStopTypingProps={props.listenerStopTypingProps}
                    newEmitMessageProps={props.newEmitMessageProps}
                    topicListProps={props.topicListProps}
                    isFetchingRoomListFinishProps={true}
                    mainChatDataProps={mainChatData}
                    setMainChatDataActionProps={setMainChatDataAction}
                    connectingStatusProps={props.connectingStatusProps}
                    _changeContactInfo={props._changeContactInfo}
                    agentDetailProps={props.agentDetailProps}
                    setNewEmitMessageProps={props.setNewEmitMessageProps}
                />
            }

            {props.activeCasePanel === ACTIVE_CASE_PANEL.other &&
                <OtherCaseTab
                    onClickRoomListProps={props.onClickRoomListProps}
                    onClickRoomListWithTaptalkRoomProps={props.onClickRoomListWithTaptalkRoomProps}
                    parentProps={props.parentProps}
                    messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                    messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                    listenerStartTypingProps={props.listenerStartTypingProps}
                    listenerStopTypingProps={props.listenerStopTypingProps}
                    newEmitMessageProps={props.newEmitMessageProps}
                    topicListProps={props.topicListProps}
                    isFetchingRoomListFinishProps={true}
                    mainChatDataProps={mainChatData}
                    setMainChatDataActionProps={setMainChatDataAction}
                    connectingStatusProps={props.connectingStatusProps}
                    _changeContactInfo={props._changeContactInfo}
                />
            }

            {props.activeCasePanel === ACTIVE_CASE_PANEL.solved &&
                <SolvedCaseTab
                    onClickRoomListProps={props.onClickRoomListProps}
                    onClickRoomListWithTaptalkRoomProps={props.onClickRoomListWithTaptalkRoomProps}
                    parentProps={props.parentProps}
                    topicListProps={props.topicListProps}
                    messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                    messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                    listenerStartTypingProps={props.listenerStartTypingProps}
                    listenerStopTypingProps={props.listenerStopTypingProps}
                    newEmitMessageProps={props.newEmitMessageProps}
                    isFetchingRoomListFinishProps={true}
                    mainChatDataProps={mainChatData}
                    setMainChatDataActionProps={setMainChatDataAction}
                    connectingStatusProps={props.connectingStatusProps}
                    _changeContactInfo={props._changeContactInfo}
                />
            }

            <div
                className={`
                    room-list-tab-wrapper 
                    ${!props.showOtherCase.configShow ? "no-other" : ""} 
                    ${!props.showClosedCase.configShow ? "no-other" : ""} 
                    ${(!props.showOtherCase.configShow && !props.showClosedCase.configShow) ? "no-other-and-close" : ""} 
                `}
            >
                <div
                    className={`room-list-tab-content tab-content-with-image ${props.activeCasePanel === ACTIVE_CASE_PANEL.bot ? 'active-room-list-tab' : ''}`}
                    onClick={() => {
                        onClickRoomListTab(ACTIVE_CASE_PANEL.bot);
                        getQuickReplyList();

                        let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
                        _closeCaseNewMessage.lastClick = false;

                        props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);
                    }}
                >

                    {props.activeCasePanel === ACTIVE_CASE_PANEL.bot ?
                        <img src={OrangeBot} alt="" />
                        :
                        <>
                            <img src={GreyBot} alt="" className='grey-tab' />
                            <img src={BlackBot} alt="" className='black-tab' />
                        </>
                    }
                    <br />
                    <b>Bot</b>
                </div>

                <div
                    className={`room-list-tab-content ${props.activeCasePanel === ACTIVE_CASE_PANEL.open ? 'active-room-list-tab' : ''}`}
                    onClick={() => {
                        onClickRoomListTab(ACTIVE_CASE_PANEL.open);
                        getQuickReplyList();

                        let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
                        _closeCaseNewMessage.lastClick = false;

                        props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);
                    }}
                >
                    <FiCircle />
                    <br />
                    <b>Me</b>
                </div>

                {props.showOtherCase.configShow &&
                    <div
                        className={`room-list-tab-content ${props.activeCasePanel === ACTIVE_CASE_PANEL.other ? 'active-room-list-tab' : ''}`}
                        onClick={() => {
                            onClickRoomListTab(ACTIVE_CASE_PANEL.other);
                            getQuickReplyList();

                            let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
                            _closeCaseNewMessage.lastClick = false;

                            props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);
                        }}
                    >
                        <FiUser />
                        <br />
                        <b>Others</b>
                    </div>
                }

                {props.showClosedCase.configShow &&
                    <div
                        className={`room-list-tab-content ${props.activeCasePanel === ACTIVE_CASE_PANEL.solved ? 'active-room-list-tab' : ''}`}
                        onClick={() => {
                            onClickRoomListTab(ACTIVE_CASE_PANEL.solved);
                            getQuickReplyList();

                            let _closeCaseNewMessage = { ...props.closeCaseNewMessageRecipient };
                            _closeCaseNewMessage.lastClick = false;

                            props.setCloseCaseNewMessageRecipient(_closeCaseNewMessage);
                        }}
                    >
                        <FiCheckCircle />
                        <br />
                        <b>Resolved</b>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    activeCasePanel: state.activeCasePanel,
    showOtherCase: state.showOtherCase,
    showClosedCase: state.showClosedCase,
    closeCaseNewMessageRecipient: state.closeCaseNewMessageRecipient,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setActiveCasePanel,
    clearStartConversation,
    setQuickReplyList,
    setCaseListChecked,
    clearCaseFilter,
    setCloseCaseNewMessageRecipient
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomListTab);
