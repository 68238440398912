import React, { useState, useEffect } from "react";
import "./IntegrationChatGPTSetup2.scss";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import IntegrationChatGPTSetupPromptSetting from "./IntegrationChatGPTSetupPromptSetting/IntegrationChatGPTSetupPromptSetting";
import IntegrationChatGPTSetupKnowledgeBase from "./IntegrationChatGPTSetupKnowledgeBase/IntegrationChatGPTSetupKnowledgeBase";
import IntegrationChatGPTSetupHandoverSettings from "./IntegrationChatGPTSetupHandoverSetting/IntegrationChatGPTSetupHandoverSettings";
import { checkID, doToast, isValidURL, scrollToClass } from "../../../helper/HelperGeneral";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import { connect } from 'react-redux';
import IntegrationChatGPTSetupBrandProfile from "./IntegrationChatGPTSetupBrandProfile/IntegrationChatGPTSetupBrandProfile";
import HelperDate from "../../../helper/HelperDate";
import { FiArrowRight } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ChatbotServices from "../../../services/newServices/ChatbotService";
import topicServices from "../../../services/newServices/TopicServices";
import PopupLoading from "../../reuseableComponent/popupLoading/PopUpLoading";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import PopupDiscard from "../../reuseableComponent/popupDiscard/PopupDiscard";

export const BRAND_NAME_MAX_LENGTH = 100;
export const BRAND_DESCRIPTION_MAX_LENGTH = 500;
export const SYSTEM_PROMPT_MAX_LENGTH = 500;
export const ERROR_MESSAGE_FIELD_REQUIRED = "This field is required";
export const ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT = "Characters exceed limit";
export const ERROR_MESSAGE_INVALID_URL = "Business website must be a valid URL";

export const DEFAULT_INDUSTRY_LIST_ARRAY = [
    {
        id: "education",
        code: "education",
        value: "education",
        name: "Education",
        label: "Education",
    },
    {
        id: "finance",
        code: "finance",
        value: "finance",
        name: "Finance",
        label: "Finance",
    },
    {
        id: "health",
        code: "health",
        value: "health",
        name: "Health, Beauty and Wellness",
        label: "Health, Beauty and Wellness",
    },
    {
        id: "hospitality",
        code: "hospitality",
        value: "hospitality",
        name: "Hospitality & Travel",
        label: "Hospitality & Travel",
    },
    {
        id: "technology",
        code: "technology",
        value: "technology",
        name: "Technology & Software Development",
        label: "Technology & Software Development",
    },
    {
        id: "others",
        code: "others",
        value: "others",
        name: "Others",
        label: "Others",
    },
];

export const DEFAULT_ROLE_LIST_ARRAY = [
    {
        id: "account_management",
        code: "account_management",
        value: "account_management",
        name: "Account Management",
        label: "Account Management",
        description: "Assist account managers tracks client interactions, schedule follow-ups, and remind important deadlines or meetings.",
    },
    {
        id: "onboarding_customer_support",
        code: "onboarding_customer_support",
        value: "onboarding_customer_support",
        name: "Onboarding Customer Support",
        label: "Onboarding Customer Support",
        description: "Guide new clients through the onboarding process by providing them with necessary resources.",
    },
    {
        id: "faq",
        code: "faq",
        value: "faq",
        name: "Frequently Asked Questions",
        label: "Frequently Asked Questions",
        description: "The chatbot can access the company’s knowledge base to answer common questions and direct users to relevant documents.",
    },
    {
        id: "help_desk_support",
        code: "help_desk_support",
        value: "help_desk_support",
        name: "Help Desk Support",
        label: "Help Desk Support",
        description: "Assist company handles common technical issues, guiding users through solutions step by step.",
    },
    {
        id: "customer_support",
        code: "customer_support",
        value: "customer_support",
        name: "Customer Support",
        label: "Customer Support",
        description: "Guide user on step by step tutorial, and collect client’s feedback on services.",
    },
    {
        id: "sales_support",
        code: "sales_support",
        value: "sales_support",
        name: "Sales Support",
        label: "Sales Support",
        description: "Assist sales teams in providing informations about product details, pricing, and after-sales services.",
    },
    {
        id: "others",
        code: "others",
        value: "others",
        name: "Others",
        label: "Others",
        description: "Add your own role for this chatbot in the Additional Prompt Settings below.",
    },
];

export const DEFAULT_TONE_LIST_ARRAY = [
    {
        id: "playful",
        code: "playful",
        value: "playful",
        name: "Playful",
        label: "Playful",
        description: "This tone is cheerful and fun, featuring humor, playful expressions, and even emojis. Ideal for informal conversations, or conversations with an upbeat and enjoyable vibe.",
        index: 0,
    },
    {
        id: "friendly",
        code: "friendly",
        value: "friendly",
        name: "Friendly",
        label: "Friendly",
        description: "This tone is warm and inviting, balancing professionalism with a casual charm to create a comfortable environment.",
        index: 1,
    },
    {
        id: "formal",
        code: "formal",
        value: "formal",
        name: "Formal",
        label: "Formal",
        description: "This tone blends politeness with expertise, creating a conversational quality while remaining professional.",
        index: 2,
    },
    {
        id: "professional",
        code: "professional",
        value: "professional",
        name: "Professional",
        label: "Professional",
        description: "This tone prioritizes clarity and precision, using technical terminology and well-organized points.",
        index: 3,
    },
];

export const DEFAULT_LANGUAGE_LIST_ARRAY = [
    {
        id: "en",
        code: "en",
        value: "en",
        name: "English",
        label: "English",
    },
    {
        id: "id",
        code: "id",
        value: "id",
        name: "Indonesian",
        label: "Indonesian",
    },
];

export const KNOWLEDGE_SOURCES = [
    {
        id: "document",
        tab: "Document",
    },
    {
        id: "text",
        tab: "Text",
    },
    // {
    //     id: "website",
    //     tab: "Website",
    // },
    // {
    //     id: "qna",
    //     tab: "Q&A",
    // },
];

const IntegrationChatGPTSetup2 = (props) => {

    let { match, history, location } = props;
    let [orgID, setOrgID] = useState(false);
    let [activeTab, setActiveTab] = useState(0);
    let [pendingTab, setPendingTab] = useState(-1);
    let [isLoadingSave, setIsLoadingSave] = useState(false);
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [isShowModalSubmitConfirmation, setIsShowModalSubmitConfirmation] = useState(false);

    const toggleModalLoadingSave = () => {
        setIsLoadingSave(!isLoadingSave);
    }

    const toggleModalLoadingSubmit = () => {
        setIsLoadingSubmit(!isLoadingSubmit);
    }

    const toggleModalSubmitConfirmation = () => {
        setIsShowModalSubmitConfirmation(!isShowModalSubmitConfirmation);
    }

    // Brand Profile
    let [brandProfileOptions, setBrandProfileOptions] = useState({
        industries: DEFAULT_INDUSTRY_LIST_ARRAY.slice(),
    });
    let [brandProfile, setBrandProfile] = useState({
        brandName: "",
        industry: {
            id: "",
            code: "",
            value: "",
            name: "",
            label: "",
        },
        website: "",
        brandDescription: "",
    });
    let [brandProfileError, setBrandProfileError] = useState({
        brandName: "",
        industry: "",
        website: "",
        brandDescription: "",
    });
    let [brandProfileOrigin, setBrandProfileOrigin] = useState("");
    let [isShowModalDiscardBrandProfile, setIsShowModalDiscardBrandProfile] = useState(false);
    let [isShowModalSaveAndSubmitBrandProfile, setIsShowModalSaveAndSubmitBrandProfile] = useState(false);

    const toggleModalDiscardBrandProfile = () => {
        setIsShowModalDiscardBrandProfile(!isShowModalDiscardBrandProfile);
    }

    const toggleModalSaveAndSubmitBrandProfile = () => {
        setIsShowModalSaveAndSubmitBrandProfile(!isShowModalSaveAndSubmitBrandProfile);
    }

    // Chatbot Profile / Prompt Settings
    let [promptSettingsOptions, setPromptSettingsOptions] = useState({
        models: [],
        roles: DEFAULT_ROLE_LIST_ARRAY.slice(),
        tones: DEFAULT_TONE_LIST_ARRAY.slice(),
        languages: DEFAULT_LANGUAGE_LIST_ARRAY.slice(),
    });
    let [promptSettings, setPromptSettings] = useState({
        personaName: "",
        model: {
            id: "gpt-4o-mini",
            code: "gpt-4o-mini",
            value: "gpt-4o-mini",
            name: "GPT-4o mini",
            label: "GPT-4o mini",
        },
        role: {
            id: "",
            code: "",
            value: "",
            name: "",
            label: "",
            description: "",
        },
        tone: {
            id: "",
            code: "",
            value: "",
            name: "",
            label: "",
            description: "",
            index: -1,
        },
        defaultLanguage: {
            id: "",
            code: "",
            value: "",
            name: "",
            label: "",
        },
        supportedLanguages: [],
        greetings: [""],
        systemPrompt: "",
        allowGlobalKnowledge: true,
    });
    let [promptSettingsError, setPromptSettingsError] = useState({
        personaName: "",
        model: "",
        role: "",
        tone: "",
        greetings: [],
        systemPrompt: "",
    });
    let [promptSettingsOrigin, setPromptSettingsOrigin] = useState("");
    let [isShowModalDiscardPromptSettings, setIsShowModalDiscardPromptSettings] = useState(false);
    let [isShowModalSaveAndSubmitPromptSettings, setIsShowModalSaveAndSubmitPromptSettings] = useState(false);

    const toggleModalDiscardPromptSettings = () => {
        setIsShowModalDiscardPromptSettings(!isShowModalDiscardPromptSettings);
    }

    const toggleModalSaveAndSubmitPromptSettings = () => {
        setIsShowModalSaveAndSubmitPromptSettings(!isShowModalSaveAndSubmitPromptSettings);
    }

    // Knowledge Base
    let [knowledgeBase, setKnowledgeBase] = useState({
        pendingChanges: [],
        trainedSources: []
    });
    let [knowledgeBaseTab, setKnowledgeBaseTab] = useState(KNOWLEDGE_SOURCES[0]);
    let [sourceTextData, setSourceTextData] = useState({
        name: "",
        content: "",
    });
    let [isShowModalDiscardSourceText, setIsShowModalDiscardSourceText] = useState(false);

    const toggleModalDiscardSourceText = () => {
        setIsShowModalDiscardSourceText(!isShowModalDiscardSourceText);
    }

    // Handover Settings
    let [handoverSettings, setHandoverSettings] = useState({
        isEnabled: true,
        criteria: [
            {
                criteria: "",
                topic: {
                    value: "",
                    label: ""
                }
            }
        ]
    });
    let [handoverSettingsError, setHandoverSettingsError] = useState({
        criteria: [
            {
                criteria: "",
                topic: ""
            }
        ]
    });
    let [handoverSettingsOrigin, setHandoverSettingsOrigin] = useState("");
    let [topicList, setTopicList] = useState([]);
    let [isShowModalDiscardHandoverSettings, setIsShowModalDiscardHandoverSettings] = useState(false);
    let [isShowModalSaveAndSubmitHandoverSettings, setIsShowModalSaveAndSubmitHandoverSettings] = useState(false);

    const toggleModalDiscardHandoverSettings = () => {
        setIsShowModalDiscardHandoverSettings(!isShowModalDiscardHandoverSettings);
    }

    const toggleModalSaveAndSubmitHandoverSettings = () => {
        setIsShowModalSaveAndSubmitHandoverSettings(!isShowModalSaveAndSubmitHandoverSettings);
    }
    
    // let [leadsGenerationSettings, setLeadsGenerationSettings] = useState({
    //     isEnable: true,
    //     variable: [
    //         {
    //             variable: {
    //                 label: "",
    //                 value: ""
    //             },
    //             description: "",
    //             question: ""
    //         }
    //     ]
    // });

    const doMount = async () => {
        const paramID = checkID(match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
        getTopics();
        getPromptSettingsData();
    }, [location.pathname]);

    useEffect(() => {
        if (props.history.location.state) {
            if (props.history.location.state.tab) {
                setActiveTab(props.history.location.state.tab);
            }
        }
    }, [props.history]);

    useEffect(() => {
        if (pendingTab === activeTab) {
            setPendingTab(-1);
        }
    }, [activeTab]);

    useEffect(() => {
        if (pendingTab >= 0) {
            switchTab(pendingTab);
        }
        if (isLoadingSubmit) {
            setIsLoadingSubmit(false);
            setIsShowModalSubmitConfirmation(true);
        }
    }, [brandProfileOrigin, promptSettingsOrigin, handoverSettingsOrigin]);

    const backToChatbotDetails = () => {
        props.history.push(`/o/${props.match.params.orgID}/chatbots/detail/${props.match.params.id}`)
    }

    const getTopics = () => {
        topicServices.getTopic(props.match.params.orgID, "list", null, (response) => {
            if (response.dataResult.error.message === "") {
                const data = response.dataResult.data.topics;
                const createList = () => {
                    let newData = [];
                    data.map(val => {
                        let _data = {};
                        _data.value = val.id;
                        _data.label = val.name;
                        newData.push(_data);
                        return null;
                    });

                    setTopicList(newData.slice());
                };

                createList();
            }
        });
    }

    const switchTab = (tab) => {
        if (activeTab === tab || tab < 0) {
            return;
        }
        if (activeTab === 0 && brandProfileOrigin && JSON.stringify(brandProfile) !== brandProfileOrigin) {
            setPendingTab(tab);
            toggleModalDiscardBrandProfile();
        }
        else if (activeTab === 1 && promptSettingsOrigin && JSON.stringify(promptSettings) !== promptSettingsOrigin) {
            setPendingTab(tab);
            toggleModalDiscardPromptSettings();
        }
        else if (activeTab === 3 && handoverSettingsOrigin && JSON.stringify(handoverSettings) !== handoverSettingsOrigin) {
            setPendingTab(tab);
            toggleModalDiscardHandoverSettings();
        }
        else {
            setActiveTab(tab);
        }
    }

    const getBrandProfileData = (callback) => {
        // Fetch options
        ChatbotServices.getChatGptBrandProfileOptions(props.match.params.orgID, (response) => {
            let optionsData = response.dataResult.data;
            if (optionsData) {
                // Industries
                let industries = optionsData.industries;
                let mappedIndustries = [];
                let industryHashMap = {};
                if (industries && industries.length > 0) {
                    industries.map((industry) => {
                        industry.id = industry.code;
                        industry.value = industry.code;
                        industry.label = industry.name;
                        mappedIndustries.push(industry);
                        industryHashMap[industry.code] = industry;
                        return null;
                    });
                    setBrandProfileOptions({
                        industries: mappedIndustries,
                    })
                }

                // Fetch saved data
                let data = {
                    "chatbotID": Number(props.match.params.id)
                };
                ChatbotServices.getChatGptSavedBrandProfile(props.match.params.orgID, data, (response) => {
                    let profileData = response.dataResult.data;
                    if (profileData) {
                        let _brandProfile = { ...brandProfile };
                        if (profileData.name) {
                            _brandProfile.brandName = profileData.name;
                        }
                        if (profileData.industryCode) {
                            if (industryHashMap[profileData.industryCode]) {
                                _brandProfile.industry = industryHashMap[profileData.industryCode];
                            }
                            else {
                                _brandProfile.industry = {
                                    id: "",
                                    code: "",
                                    value: "",
                                    name: "",
                                    label: "",
                                }
                            }
                            _brandProfile.industry.id = profileData.industryCode;
                            _brandProfile.industry.code = profileData.industryCode;
                            _brandProfile.industry.value = profileData.industryCode;
                        }
                        if (profileData.industryName) {
                            _brandProfile.industry.name = profileData.industryName;
                            _brandProfile.industry.label = profileData.industryName;
                        }
                        if (profileData.website) {
                            _brandProfile.website = profileData.website;
                        }
                        if (profileData.description) {
                            _brandProfile.brandDescription = profileData.description;
                        }
                        setBrandProfile(_brandProfile);
                        setBrandProfileOrigin(JSON.stringify(_brandProfile));
                    }
                    if (callback) {
                        callback(response);
                    }
                });
            }
        });
    }

    const validateSaveBrandProfile = (save = true) => {
        let _brandProfile = { ...brandProfile };
        let _errorMessageData = { ...brandProfileError };
        let errorClass = false;

        if (!_brandProfile.brandName) {
            _errorMessageData.brandName = ERROR_MESSAGE_FIELD_REQUIRED;
            if (!errorClass) {
                errorClass = "brand-name-wrapper";
            }
        }
        else if (_brandProfile.brandName.length > BRAND_NAME_MAX_LENGTH) {
            _errorMessageData.brandName = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
            if (!errorClass) {
                errorClass = "brand-name-wrapper";
            }
        }

        if (!_brandProfile.industry.value) {
            _errorMessageData.industry = ERROR_MESSAGE_FIELD_REQUIRED;
            if (!errorClass) {
                errorClass = "industry-wrapper";
            }
        }

        if (_brandProfile.website && !isValidURL(_brandProfile.website)) {
            _errorMessageData.website = ERROR_MESSAGE_INVALID_URL;
            if (!errorClass) {
                errorClass = "website-wrapper";
            }
        }

        // if (_brandProfile.brandDescription.length > BRAND_DESCRIPTION_MAX_LENGTH) {
        //     _errorMessageData.brandDescription = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
        //     if (!errorClass) {
        //         errorClass = "brand-description-wrapper";
        //     }
        // }

        setBrandProfileError(_errorMessageData);
        if (errorClass) {
            scrollToClass(`.${errorClass}`);
            return false;
        }
        else if (save) {
            saveBrandProfile();
        }
        return true;
    }

    const saveBrandProfile = () => {
        setIsLoadingSave(true);
        let data = {
            "chatbotID": Number(props.match.params.id),
            "name": brandProfile.brandName,
            "industryCode": brandProfile.industry.code,
            "website": brandProfile.website,
            "description": brandProfile.brandDescription,
        };
        ChatbotServices.saveChatGptBrandProfile(props.match.params.orgID, data, (response) => {
            let dataResult = response.dataResult;
            if (dataResult.error && dataResult.error.message) {
                doToast(dataResult.error.message, 'fail');
                setIsLoadingSubmit(false);
            }
            else if (dataResult.data && dataResult.data.message) {
                doToast(dataResult.data.message, dataResult.data.success ? "" : "fail");
                if (dataResult.data.success) {
                    setBrandProfileOrigin(JSON.stringify(brandProfile));
                }
            }
            setIsLoadingSave(false);
        });
    }

    const getPromptSettingsData = (callback) => {
        // Fetch options
        ChatbotServices.getChatGptPromptSettingsOptions(props.match.params.orgID, (response) => {
            let optionsData = response.dataResult.data;
            if (optionsData) {
                // Models
                let models = optionsData.models;
                let mappedModels = [];
                let modelHashMap = {};
                if (models && models.length > 0) {
                    models.map((model) => {
                        model.id = model.code;
                        model.value = model.code;
                        model.label = model.name;
                        mappedModels.push(model);
                        modelHashMap[model.code] = model;
                        return null;
                    });
                }

                // Roles
                let roles = optionsData.roles;
                let mappedRoles = [];
                let roleHashMap = {};
                if (roles && roles.length > 0) {
                    roles.map((role) => {
                        role.id = role.code;
                        role.value = role.code;
                        role.label = role.name;
                        mappedRoles.push(role);
                        roleHashMap[role.code] = role;
                        return null;
                    });
                }

                // Tones
                let tones = optionsData.tones;
                let mappedTones = [];
                let toneHashMap = {};
                if (tones && tones.length > 0) {
                    tones.map((tone, index) => {
                        tone.id = tone.code;
                        tone.value = tone.code;
                        tone.label = tone.name;
                        if (!tone.description && DEFAULT_TONE_LIST_ARRAY.length > index) {
                            tone.description = DEFAULT_TONE_LIST_ARRAY[index].description;
                        }
                        tone.index = index;
                        mappedTones.push(tone);
                        toneHashMap[tone.code] = tone;
                        return null;
                    });
                }

                // Languages
                let languages = optionsData.languages;
                let mappedLanguages = [];
                let languageHashMap = {};
                if (languages && languages.length > 0) {
                    languages.map((language) => {
                        language.id = language.code;
                        language.value = language.code;
                        language.label = language.name;
                        mappedLanguages.push(language);
                        languageHashMap[language.code] = language;
                        return null;
                    });
                }

                setPromptSettingsOptions({
                    models: mappedModels,
                    roles: mappedRoles,
                    tones: mappedTones,
                    languages: mappedLanguages,
                });

                // Fetch saved data
                let data = {
                    "chatbotID": Number(props.match.params.id)
                };
                ChatbotServices.getChatGptSavedPromptSettings(props.match.params.orgID, data, (response) => {  
                    let data = response.dataResult.data;
                    if (data) {
                        let _promptSettings = { ...promptSettings };
                        if (data.personaName) {
                            _promptSettings.personaName = data.personaName;
                        }
                        if (data.modelCode && modelHashMap[data.modelCode]) {
                            _promptSettings.model = modelHashMap[data.modelCode];
                        }
                        else {
                            _promptSettings.model = {
                                id: "gpt-4o-mini",
                                code: "gpt-4o-mini",
                                value: "gpt-4o-mini",
                                name: "GPT-4o mini",
                                label: "GPT-4o mini",
                            };                    
                        }
                        if (data.roleCode && roleHashMap[data.roleCode]) {
                            _promptSettings.role = roleHashMap[data.roleCode];
                        }
                        if (data.toneCode && toneHashMap[data.toneCode]) {
                            _promptSettings.tone = toneHashMap[data.toneCode];
                        }
                        if (data.defaultLanguage && languageHashMap[data.defaultLanguage]) {
                            _promptSettings.defaultLanguage = languageHashMap[data.defaultLanguage];
                        }
                        if (data.supportedLanguages && data.supportedLanguages.length > 0) {
                            _promptSettings.supportedLanguages = [];
                            data.supportedLanguages.map((languageCode) => {
                                if (languageHashMap[languageCode]) {
                                    _promptSettings.supportedLanguages.push(languageHashMap[languageCode]);
                                }
                                return null;
                            });
                        }
                        if (data.greetings && data.greetings.length > 0) {
                            _promptSettings.greetings = data.greetings;
                        }
                        if (data.systemPrompt) {
                            _promptSettings.systemPrompt = data.systemPrompt;
                        }
                        if (typeof data.allowGlobalKnowledge == "boolean") {
                            _promptSettings.allowGlobalKnowledge = data.allowGlobalKnowledge;
                        }
                        setPromptSettings(_promptSettings);
                        setPromptSettingsOrigin(JSON.stringify(_promptSettings));
                        let errorGreetings = [];
                        errorGreetings.length = _promptSettings.greetings.length;
                        setPromptSettingsError({
                            personaName: "",
                            model: "",
                            role: "",
                            tone: "",
                            greetings: errorGreetings,
                            systemPrompt: "",
                        });
                    }
                    if (callback) {
                        callback(response);
                    }
                });
            }
        });
    }

    const validateSavePromptSettings = (save = true) => {
        let _promptSettings = { ...promptSettings };
        let _errorMessageData = { ...promptSettingsError };
        let errorClass = false;

        // if (!_promptSettings.model.value) {
        //     _errorMessageData.model = ERROR_MESSAGE_FIELD_REQUIRED;
        //     if (!errorClass) {
        //         errorClass = "dropdown-model";
        //     }
        // }

        if (!_promptSettings.role.value) {
            _errorMessageData.role = ERROR_MESSAGE_FIELD_REQUIRED;
            if (!errorClass) {
                errorClass = "dropdown-role";
            }
        }

        if (!_promptSettings.tone.value) {
            _errorMessageData.tone = ERROR_MESSAGE_FIELD_REQUIRED;
            if (!errorClass) {
                errorClass = "tone-form-wrapper";
            }
        }

        _promptSettings.greetings.map((value, index) => {
            if (!value) {
                if (_errorMessageData.greetings.length > index) {
                    _errorMessageData.greetings[index] = ERROR_MESSAGE_FIELD_REQUIRED;
                }
                if (!errorClass) {
                    errorClass = `greetings-list-${index}`;
                }
            }
            return null;
        });

        // if (_promptSettings.systemPrompt.length > SYSTEM_PROMPT_MAX_LENGTH) {
        //     _errorMessageData.systemPrompt = ERROR_MESSAGE_CHARACTERS_EXCEED_LIMIT;
        //     if (!errorClass) {
        //         errorClass = "system-prompt-wrapper";
        //     }
        // }

        setPromptSettingsError(_errorMessageData);

        if (errorClass) {
            scrollToClass(`.${errorClass}`);
            return false;
        }
        else if (save) {
            savePromptSettings();
        }
        return true;
    }

    const savePromptSettings = () => {
        setIsLoadingSave(true);
        let supportedLanguageCodes = [];
        if (promptSettings.supportedLanguages && promptSettings.supportedLanguages.length > 0) {
            promptSettings.supportedLanguages.map((language) => {
                supportedLanguageCodes.push(language.code);
                return null;
            });
        }
        let data = {
            "chatbotID": Number(props.match.params.id),
            "modelCode": promptSettings.model.code,
            "personaName": promptSettings.personaName,
            "roleCode": promptSettings.role.code,
            "toneCode": promptSettings.tone.code,
            "defaultLanguage": promptSettings.defaultLanguage.code,
            "supportedLanguages": supportedLanguageCodes,
            "greetings": promptSettings.greetings,
            "systemPrompt": promptSettings.systemPrompt,
            "allowGlobalKnowledge": promptSettings.allowGlobalKnowledge,
        };
        ChatbotServices.saveChatGptPromptSettings(props.match.params.orgID, data, (response) => {
            let dataResult = response.dataResult;
            if (dataResult.error && dataResult.error.message) {
                doToast(dataResult.error.message, 'fail');
                setIsLoadingSubmit(false);
            }
            else if (dataResult.data && dataResult.data.message) {
                doToast(dataResult.data.message, dataResult.data.success ? "" : "fail");
                if (dataResult.data.success) {
                    setPromptSettingsOrigin(JSON.stringify(promptSettings));
                }
            }
            setIsLoadingSave(false);
        });
    }

    const validateSaveHandoverSettings = (save = true) => {
        let _errorMessageData = { ...handoverSettingsError };
        let errorClass = false;

        if (handoverSettings.isEnabled) {
            handoverSettings.criteria.map((value, index) => {
                if (!value.criteria) {
                    if (_errorMessageData && _errorMessageData.criteria && _errorMessageData.criteria.length > index) {
                        _errorMessageData.criteria[index].criteria = "This field is required"
                    }
                    if (!errorClass) {
                        errorClass = `criteria-wrapper-${index}`;
                    }
                }
                if (!value.topic.value) {
                    if (_errorMessageData && _errorMessageData.criteria && _errorMessageData.criteria.length > index) {
                        _errorMessageData.criteria[index].topic = "This field is required"
                    }
                    if (!errorClass) {
                        errorClass = `criteria-wrapper-${index}`;
                    }
                }
                return null;
            });
        }

        setHandoverSettingsError(_errorMessageData);

        if (errorClass) {
            scrollToClass(`.${errorClass}`);
            return false;
        }
        else if (save) {
            saveHandoverSettings();
        }
        return true;
    }

    const getSavedHandoverSettings = (callback) => {
        let data = {
            "chatbotID": Number(props.match.params.id)
        };
        ChatbotServices.getChatGptSavedHandoverSettings(props.match.params.orgID, data, (response) => {
            let handoverSettingsData = response.dataResult.data;
            if (handoverSettingsData) {
                let _handoverSettings = { ...handoverSettings };
                _handoverSettings.isEnabled = handoverSettingsData.isEnabled;
                let handoverSettingsList = [];
                if (handoverSettingsData.items && handoverSettingsData.items.length > 0) {
                    handoverSettingsData.items.map((settings) => {
                        handoverSettingsList.push({
                            criteria: settings.criteria,
                            topic: {
                                value: settings.topicID,
                                label: settings.topicName,
                            },
                        });
                        return null;
                    });
                }
                else {
                    handoverSettingsList.push({
                        criteria: "",
                        topic: {
                            value: "",
                            label: ""
                        }
                    });
                }
                _handoverSettings.criteria = handoverSettingsList;
                setHandoverSettings(_handoverSettings);
                setHandoverSettingsOrigin(JSON.stringify(_handoverSettings));
            }
            if (callback) {
                callback(response);
            }
        });
    }

    const saveHandoverSettings = () => {
        setIsLoadingSave(true);
        let handoverSettingsList = [];
        if (handoverSettings.criteria && handoverSettings.criteria.length > 0) {
            handoverSettings.criteria.map((settings) => {
                if (settings.criteria && settings.topic.value) {
                    handoverSettingsList.push({
                        criteria: settings.criteria,
                        topicID: settings.topic.value !== "" ? settings.topic.value : 0,
                    });
                }
                return null;
            });
        }
        let data = {
            "chatbotID": Number(props.match.params.id),
            "isEnabled": handoverSettings.isEnabled,
            "items": handoverSettingsList,
        };
        ChatbotServices.saveChatGptHandoverSettings(props.match.params.orgID, data, (response) => {
            let dataResult = response.dataResult;
            if (dataResult.error && dataResult.error.message) {
                doToast(dataResult.error.message, 'fail');
                setIsLoadingSubmit(false);
            }
            else if (dataResult.data && dataResult.data.message) {
                doToast(dataResult.data.message, dataResult.data.success ? "" : "fail");
                if (dataResult.data.success) {
                    setHandoverSettingsOrigin(JSON.stringify(handoverSettings));
                }
            }
            setIsLoadingSave(false);
        });
    }

    const validateSubmitChanges = () => {
        if ((sourceTextData.name || sourceTextData.content)) {
            setPendingTab(-2);
            toggleModalDiscardSourceText();
        }
        else if (activeTab === 0) {
            if (validateSaveBrandProfile(false) && JSON.stringify(brandProfile) !== brandProfileOrigin) {
                setIsShowModalSaveAndSubmitBrandProfile(true);
            }
            else if (validateSaveBrandProfile(false)) {
                setIsShowModalSubmitConfirmation(true);
            }
        }
        else if (activeTab === 1) {
            if (validateSavePromptSettings(false) && JSON.stringify(promptSettings) !== promptSettingsOrigin) {
                setIsShowModalSaveAndSubmitPromptSettings(true);
            }
            else if (validateSavePromptSettings(false)) {
                setIsShowModalSubmitConfirmation(true);
            }
        }
        else if (activeTab === 3) {
            if (validateSaveHandoverSettings(false) && JSON.stringify(handoverSettings) !== handoverSettingsOrigin) {
                setIsShowModalSaveAndSubmitHandoverSettings(true);
            }
            else if (validateSaveHandoverSettings(false)) {
                setIsShowModalSubmitConfirmation(true);
            }
        }
        else {
            setIsShowModalSubmitConfirmation(true);
        }
    }

    const submitChanges = () => {
        setIsShowModalSubmitConfirmation(false);
        setIsLoadingSubmit(true);
        let data = {
            "chatbotID": Number(props.match.params.id),
            "versionDescription": HelperDate.formatToString(new Date(), "dd/MM/yyyy HH:mm"),
        };
        ChatbotServices.setChatGptSubmission(props.match.params.orgID, data, (response) => {
            // TODO: SWITCH TAB, LOAD SAVED DATA & SHOW ERROR ACCORDING TO RESPONSE
            let dataResult = response.dataResult;
            if (dataResult.error && dataResult.error.message) {
                doToast(dataResult.error.message, "fail");
                setIsLoadingSubmit(false);
            }
            else if (dataResult.data && dataResult.data.message) {
                doToast(dataResult.data.message, dataResult.data.success ? "" : "fail");
                if (dataResult.data.success) {
                    setSourceTextData({
                        name: "",
                        content: "",
                    });
                    setTimeout(() => {
                        backToChatbotDetails();
                        setIsLoadingSubmit(false);
                    }, 3000);
                }
                else {
                    setIsLoadingSubmit(false);
                }
            }
        })
    }

    const TAB_LIST = {
        brandProfile: {
            id: "brandProfile",
            tab: "Brand Profile",
        },
        chatbotProfile: {
            id: "chatbotProfile",
            tab: "Chatbot Profile",
        },
        knowledgeBase: {
            id: "knowledgeBase",
            tab: "Knowledge Base",
        },
        handoverSettings: {
            id: "handoverSettings",
            tab: "Handover Settings",
        },
    }

    const renderSwitchTabConfirmationModal = (isOpen, toggle, discardChanges, saveChanges) => {
        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                    className={`modal-confirmation modal-save-changes`}
                >
                    <ModalBody>
                        <b className="confirmation-title">Save Changes?</b>
                        <p className="confirmation-description" dangerouslySetInnerHTML={{__html: "You have unsaved changes on this page. Do you want to save your progress?"}} />
                    </ModalBody>

                    <ModalFooter>
                        <ButtonWithLoadingOrIcon
                            text="Discard Changes"
                            className="red-button main-button-48"
                            onClickAction={discardChanges}
                        />
                        <ButtonWithLoadingOrIcon 
                            // icon={{
                            //     type: "svg",
                            //     src: FiSave
                            // }}
                            className="orange-button main-button-48"
                            text="Save Changes"
                            position="right"
                            onClickAction={saveChanges}
                        />
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    const renderSaveAndSubmitConfirmationModal = (isOpen, toggle, saveChanges) => {
        return (
            <div>
                <Modal
                    isOpen={isOpen}
                    toggle={toggle}
                    className={`modal-confirmation modal-save-changes`}
                >
                    <ModalBody>
                        <b className="confirmation-title">Save Changes?</b>
                        <p className="confirmation-description" dangerouslySetInnerHTML={{__html: "You have unsaved changes on this page. Do you want to save your progress?"}} />
                    </ModalBody>

                    <ModalFooter>
                        <ButtonWithLoadingOrIcon
                            text="Cancel"
                            className="no-fill-button main-button-48"
                            onClickAction={toggle}
                        />
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-48"
                            text="Save Changes"
                            onClickAction={saveChanges}
                        />
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    const renderSubmitConfirmationModal = () => {
        return (
            <div>
                <Modal
                    isOpen={isShowModalSubmitConfirmation}
                    toggle={toggleModalSubmitConfirmation}
                    className={`modal-confirmation modal-save-changes`}
                >
                    <ModalBody>
                        <b className="confirmation-title">Submit and Build Chatbot?</b>
                        <p className="confirmation-description" dangerouslySetInnerHTML={{__html: "Changes you have made in this session will be build permanently into chatbot, do you want to save your progress?"}} />
                    </ModalBody>

                    <ModalFooter>
                        <ButtonWithLoadingOrIcon
                            text="Cancel"
                            className="no-fill-button main-button-48"
                            onClickAction={toggleModalSubmitConfirmation}
                        />
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-48"
                            text="Save Changes"
                            onClickAction={submitChanges}
                        />
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

    return (
        <>
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                <BackButton
                    onClick={backToChatbotDetails}
                    text={"Back to Chatbot Details"}
                />
                <div className="chatbot-setup-page-wrapper sectionWrap padding-24">
                    <div className="chatbot-setup-header">
                        <b className="chatbot-setup-header-title">Chatbot Builder</b>
                        {/*
                            location.state.lastUpdated &&
                            <p className="chatbot-setup-header-last">
                                {`Last updated ${HelperDate.formatToString(new Date(location.state.lastUpdated), "dd/MM/yyyy, HH:mm")}`}
                            </p>
                        */}
                        <ButtonWithLoadingOrIcon
                            text="Submit & Build"
                            position="right"
                            icon={{
                                type: "svg",
                                src: FiArrowRight
                            }}
                            className="main-button-40 orange-button"
                            onClickAction={validateSubmitChanges}
                        />
                    </div>

                    <div className="chatbot-tab-wrapper">
                        {Object.keys(TAB_LIST).map((v, i) => {
                            return (
                                <div
                                    className={`chatbot-tab ${activeTab === i ? "chatbot-tab-active" : ""}`}
                                    key={`chatbot-tab-${i}`}
                                    onClick={() => switchTab(i)}
                                >
                                    <b>{TAB_LIST[v].tab}</b>
                                </div>
                            )
                        })}
                    </div>

                    <>
                        {/* {TAB_LIST[Object.keys(TAB_LIST)[activeTab]].components} */}
                        {
                            activeTab === 0
                            ?
                            <IntegrationChatGPTSetupBrandProfile
                                {...props}
                                brandProfileOptions={brandProfileOptions}
                                setBrandProfileOptions={setBrandProfileOptions}
                                brandProfile={brandProfile}
                                setBrandProfile={setBrandProfile}
                                brandProfileError={brandProfileError}
                                setBrandProfileError={setBrandProfileError}
                                brandProfileOrigin={brandProfileOrigin}
                                setBrandProfileOrigin={setBrandProfileOrigin}
                                getBrandProfileData={getBrandProfileData}
                                validateSaveBrandProfile={validateSaveBrandProfile}
                                setPendingTab={setPendingTab}
                            />
                            :
                            activeTab === 1
                            ?
                            <IntegrationChatGPTSetupPromptSetting
                                {...props}
                                promptSettingsOptions={promptSettingsOptions}
                                setPromptSettingsOptions={setPromptSettingsOptions}
                                promptSettings={promptSettings}
                                setPromptSettings={setPromptSettings}
                                isLoadingSave={isLoadingSave}
                                promptSettingsError={promptSettingsError}
                                setPromptSettingsError={setPromptSettingsError}
                                promptSettingsOrigin={promptSettingsOrigin}
                                setPromptSettingsOrigin={setPromptSettingsOrigin}
                                getPromptSettingsData={getPromptSettingsData}
                                validateSavePromptSettings={validateSavePromptSettings}
                                setPendingTab={setPendingTab}
                            />
                            :
                            activeTab === 2
                            ?
                            <IntegrationChatGPTSetupKnowledgeBase 
                                {...props} 
                                knowledgeBase={knowledgeBase}
                                setKnowledgeBase={setKnowledgeBase}
                                knowledgeBaseTab={knowledgeBaseTab}
                                setKnowledgeBaseTab={setKnowledgeBaseTab}
                                sourceTextData={sourceTextData}
                                setSourceTextData={setSourceTextData}
                            />
                            :
                            activeTab === 3
                            ?
                            <IntegrationChatGPTSetupHandoverSettings 
                                {...props} 
                                topicList={topicList}
                                setTopicList={setTopicList}
                                getTopics={getTopics}
                                getSavedHandoverSettings={getSavedHandoverSettings}
                                handoverSettings={handoverSettings}
                                setHandoverSettings={setHandoverSettings}
                                handoverSettingsError={handoverSettingsError}
                                setHandoverSettingsError={setHandoverSettingsError}
                                handoverSettingsOrigin={handoverSettingsOrigin}
                                setHandoverSettingsOrigin={setHandoverSettingsOrigin}
                                validateSaveHandoverSettings={validateSaveHandoverSettings}
                                setPendingTab={setPendingTab}
                            />
                            :
                            <></>
                        }
                    </>
                </div>
            </SectionWrap>

            {
                renderSwitchTabConfirmationModal(
                    isShowModalDiscardBrandProfile,
                    toggleModalDiscardBrandProfile,
                    () => {
                        setIsShowModalDiscardBrandProfile(false);
                        setBrandProfile(JSON.parse(brandProfileOrigin));
                        setBrandProfileError({
                            brandName: "",
                            industry: "",
                            website: "",
                            brandDescription: "",
                        });
                        if (pendingTab >= 0) {
                            setActiveTab(pendingTab);
                        }
                    },
                    () => {
                        setIsShowModalDiscardBrandProfile(false);
                        validateSaveBrandProfile(true);
                    }
                )
            }

            {
                renderSwitchTabConfirmationModal(
                    isShowModalDiscardPromptSettings,
                    toggleModalDiscardPromptSettings,
                    () => {
                        setIsShowModalDiscardPromptSettings(false);
                        let origin = JSON.parse(promptSettingsOrigin);
                        setPromptSettings(origin);
                        let errorGreetings = [];
                        errorGreetings.length = origin.greetings.length;
                        setPromptSettingsError({
                            personaName: "",
                            model: "",
                            role: "",
                            tone: "",
                            greetings: errorGreetings,
                            systemPrompt: "",
                        });
                        if (pendingTab >= 0) {
                            setActiveTab(pendingTab);
                        }
                    },
                    () => {
                        setIsShowModalDiscardPromptSettings(false);
                        validateSavePromptSettings(true);
                    }
                )
            }

            <PopupDiscard
                dataOrigin={JSON.stringify("")}
                dataAfterChange={JSON.stringify("")}
                isOpenDiscard={isShowModalDiscardSourceText}
                toggleDiscard={() => {
                    toggleModalDiscardSourceText();
                    setActiveTab(2);
                    setKnowledgeBaseTab(KNOWLEDGE_SOURCES[1]);
                }}
                discardProgress={() => {
                    setIsShowModalDiscardSourceText(false);
                    if (pendingTab >= 0) {
                        setActiveTab(pendingTab);
                    }
                    else if (pendingTab === -2) {
                        setIsShowModalSubmitConfirmation(true);
                    }
                }}
                discardDesc="You have unsaved text knowledge base. Are you sure you want to discard your progress?"
            />

            {
                renderSwitchTabConfirmationModal(
                    isShowModalDiscardHandoverSettings,
                    toggleModalDiscardHandoverSettings,
                    () => {
                        setIsShowModalDiscardHandoverSettings(false);
                        let origin = JSON.parse(handoverSettingsOrigin);
                        setHandoverSettings(origin);
                        let errorCriteria = [];
                        while (errorCriteria.length < origin.criteria.length) {
                            errorCriteria.push({
                                criteria: "",
                                topic: ""
                            });
                        }
                        setHandoverSettingsError({
                            criteria: errorCriteria
                        });
                        if (pendingTab >= 0) {
                            setActiveTab(pendingTab);
                        }
                    },
                    () => {
                        setIsShowModalDiscardHandoverSettings(false);
                        validateSaveHandoverSettings(true);
                    }
                )
            }

            {
                renderSaveAndSubmitConfirmationModal(
                    isShowModalSaveAndSubmitBrandProfile,
                    toggleModalSaveAndSubmitBrandProfile,
                    () => {
                        setIsShowModalSaveAndSubmitBrandProfile(false);
                        setIsLoadingSubmit(true);
                        if (!validateSaveBrandProfile(true)) {
                            setIsLoadingSubmit(false);
                        }
                    }
                )
            }

            {
                renderSaveAndSubmitConfirmationModal(
                    isShowModalSaveAndSubmitPromptSettings,
                    toggleModalSaveAndSubmitPromptSettings,
                    () => {
                        setIsShowModalSaveAndSubmitPromptSettings(false);
                        setIsLoadingSubmit(true);
                        if (!validateSavePromptSettings(true)) {
                            setIsLoadingSubmit(false);
                        }
                    }
                )
            }

            {
                renderSaveAndSubmitConfirmationModal(
                    isShowModalSaveAndSubmitHandoverSettings,
                    toggleModalSaveAndSubmitHandoverSettings,
                    () => {
                        setIsShowModalSaveAndSubmitHandoverSettings(false);
                        setIsLoadingSubmit(true);
                        if (!validateSaveHandoverSettings(true)) {
                            setIsLoadingSubmit(false);
                        }
                    }
                )
            }

            { renderSubmitConfirmationModal() }

            <PopupLoading
                isOpen={isLoadingSave}
                toggle={toggleModalLoadingSave}
                title="Saving..."
            />

            <PopupLoading
                isOpen={isLoadingSubmit}
                toggle={toggleModalLoadingSubmit}
                title="Saving..."
            />
        </>
    )
}

const mapStateToProps = state => ({
    allowedModule: state.allowedModule
});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationChatGPTSetup2)
