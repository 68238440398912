const INITIAL_STATE = {
  companyID: "",
  accessKey: ""
};

let reduxReducerMiitelKey = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'SET_MIITEL_KEY':
        return action.miitelKey;
      case 'CLEAR_MIITEL_KEY':
        return INITIAL_STATE;
      default:
        return state;
    }
};

export default reduxReducerMiitelKey;