import React, { useState, useEffect } from 'react';
import './IntegrationKataai.scss';
import { Link } from 'react-router-dom';
import KataaiIcon from '../../../assets/img/integration/logo-kataai-side-menu.svg';
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap';
import PopupWarning from "../../reuseableComponent/popupWarning/PopupWarning";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiArrowUpRight, FiPlus } from 'react-icons/fi';
import IntegrationKataaiServices from '../../../services/integration/IntegrationKataaiServices';
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import { checkID, doToast } from '../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";

let IntegrationTaptalk = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoading, setIsLoading] = useState(true);
  let [listData, setListData] = useState([]);
  let [showPopupWarning, setShowPopupWarning] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  let toggleShowWarning = () => {
    setShowPopupWarning(!showPopupWarning);
  }

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getChannels(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getChannels = (orgID) => {
    let _retriveDataError = { ...retriveDataError };
    setIsLoading(true);

    IntegrationKataaiServices.get(orgID, (response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.error.message === "") {
        setListData(response.dataResult.data.channels);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoading(false);
    });
  };

  let showKataai = (id) => {
    let allow = true;
    // let kataaiOrg = process.env.REACT_APP_KATAAI_ORGANIZATION_IDS.split(",");

    // kataaiOrg.map((v) => {
    //   if(allow === false) {
    //     allow = parseInt(id) === parseInt(v);
    // }

    //   return null;
    // })

    return allow;
  }

  useEffect(() => {
    if (showKataai(props.match.params.orgID.split("-")[0]) === false) {
      props.history.push(`/o/${props.match.params.orgID}/home`)
    } else {
      doMount();
    }
  }, []);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (orgID) {
      if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Kata.ai Integration",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: orgID.split("-")[0]
          }
        );
      }
    }
  }, [orgID])

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.message) {
        doToast(props.history.location.state.message);
      }
      window.history.pushState(null, '');
    }
  }, [props.history])

  let goToAdd = () => {
    history.push(`/o/${orgID}/integration/kataai/add`)
  };

  return (
    <div className="integration-outer-wrapper">
      <PopupWarning
        isShow={showPopupWarning}
        toggle={toggleShowWarning}
        text="Maximum number of allowed channels has been reached"
      />

      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        {/* <Integrations activeIntegrationMenu="launcher" parentProps={props} orgIDProps={orgID} /> */}
        {/* tier blocking */}
        {retriveDataError.code === "49901" &&
          <RetrieveDataLoading
            isLoading={isLoading}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
          />
        }
        {/* tier blocking */}

        <div className="sectionWrap integration-main-container integration-kataai-list text-left">
          <>
            <div className="integration-list-header list-header-1-line">
              <img
                src={KataaiIcon}
                alt=""
                className="integration-list-header-icon"
              />

              <div className="integration-list-header-text-wrapper">
                <b>Kata.ai</b>
                {/* <p>
                To integrate with Telegram,
                <a href="/"> please read the documentation.</a>
              </p> */}
              </div>
              {/* 
              <button
                className="no-fill-button integration-list-header-button"
                onClick={goToAdd}
              >
                <img src={IconAdd} alt="" />
                <b>Add Channel</b>
              </button> */}

              {(!isLoading && !retriveDataError.code) &&
                <ButtonWithLoadingOrIcon
                  onClickAction={goToAdd}
                  className="no-fill-button main-button-40 integration-list-header-button"
                  text="Add Channel"
                  icon={{
                    type: "svg",
                    src: FiPlus
                  }}
                  position="left"
                />
              }
            </div>
            {(isLoading || retriveDataError.code) ?
              <RetrieveDataLoading
                isLoading={isLoading}
                errorMessage={retriveDataError.message}
              />
              :
              listData.length > 0 ?
                <div className="integration-list-outer-wrapper">
                  {listData.map((item, index) => {
                    return (
                      <Link
                        key={`livechat-account-${index}`}
                        to={{
                          pathname: `/o/${orgID}/integration/kataai/detail/${item.id}`,
                          state: { detailChannel: item },
                        }}
                        className="link-kataai-account-list"
                      >
                        <div className="integration-list-inner-wrapper">
                          <FiArrowUpRight />
                          <div className="platform-wrapper">
                            <p className="platform-name">
                              <b>{item.channelName}</b>
                            </p>
                          </div>

                          <span>{item.stringID}</span>
                        </div>
                      </Link>
                    );
                  })}
                </div>
                :
                <div className="integration-list-no-data">
                  <b>No Channel</b>
                  <p>
                    To add a channel, simply click the “+ Add Channel” button on the top right.
                  </p>
                </div>
            }
          </>
        </div>
      </SectionWrap>
    </div>
  );
};

let mapStateToProps = state => ({
  mediumData: state.mediumData
});

export default connect(mapStateToProps, null)(IntegrationTaptalk);
