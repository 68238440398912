import iconFb from "../assets/img/user-contact/icon-facebook.svg";
import iconTaptalk from "../assets/img/omnichannel/room-list/icon-taptalk.svg";
import iconLine from "../assets/img/user-contact/icon-line.svg";
import iconTelegram from "../assets/img/user-contact/icon-telegram.svg";
import iconTwitter from "../assets/img/user-contact/icon-twitter.svg";
import iconWhatsappSME from "../assets/img/user-contact/icon-whatsapp.svg";
import iconWhatsappBa from "../assets/img/user-contact/icon-whatsapp-ba.svg";
import iconInstagram from "../assets/img/user-contact/icon-instagram.svg";
import iconGoogle from "../assets/img/user-contact/icon-google.svg";
import iconGoogleReview from "../assets/img/user-contact/icon-google-profile.svg";
import iconTokopedia from "../assets/img/user-contact/icon-tokopedia.svg";
import iconEmail from "../assets//img/integration/logo-email-side-menu.svg";
import iconKataai from "../assets/img/integration/logo-kataai-side-menu.svg";
import iconMiiTel from "../assets/img/integration/logo-miitel-side-menu.svg";

export const TAPLIVE_FAQ_TYPE = {
  qna: {
    val: "qna",
    text: "Q&A"
  },
  talk_to_agent: {
    val: "talk_to_agent",
    text: "Talk to Agent"
  },
  qna_via_api: {
    val: "qna_via_api",
    text: "Q&A via API"
  }
}

export const RECIPIENT_TYPE = {
  users: {
    val: "users",
    text: "Contact"
  },
  user_segment: {
    val: "user_segment",
    text: "Contact Segment"
  }
}

export const TAPLIVE_MEDIUM = {
  facebook: "facebook",
  launcher: "launcher",
  line: "line",
  telegram: "telegram",
  twitter: "twitter",
  whatsapp: "whatsapp",
  whatsappba: "whatsappba",
  instagram: "instagram",
  google_business: "google_business",
  gb_profile: "gb_profile",
  tokopedia: "tokopedia",
  email: "email",
  kataai: "kataai"
};

export const TAPLIVE_MEDIUM_LOGO = {
  facebook: iconFb,
  launcher: iconTaptalk,
  line: iconLine,
  telegram: iconTelegram,
  twitter: iconTwitter,
  whatsapp: iconWhatsappSME,
  whatsappba: iconWhatsappBa,
  instagram: iconInstagram,
  google_business: iconGoogle,
  gb_profile: iconGoogleReview,
  tokopedia: iconTokopedia,
  email: iconEmail,
  kataai: iconKataai,
  miitel: iconMiiTel
}

export const TAPLIVE_MEDIUM_TEXT = {
  launcher: "Live Chat",
  whatsapp: "WhatsApp for SME",
  whatsappba: "WhatsApp Business API",
  facebook: "Facebook Messenger",
  instagram: "Instagram",
  telegram: "Telegram",
  google_business: "Google Business Messages",
  gb_profile: "Google Business Profile",
  twitter: "Twitter",
  line: "Line",
  tokopedia: "Tokopedia",
  email: "Email",
  kataai: "Kata.ai",
  miitel: "MiiTel"
}

export const TAPLIVE_MEDIUM_URL = {
  launcher: "launcher",
  whatsapp: "whatsapp-sme",
  whatsappba: "whatsapp-business-api",
  facebook: "messenger",
  instagram: "instagram",
  telegram: "telegram",
  google_business: "google-business-messages",
  gb_profile: "google-business-profile",
  twitter: "twitter",
  line: "line",
  tokopedia: "tokopedia",
  email: "email",
  kataai: "kataai",
  miitel: "miitel"
}

export const TAPLIVE_STATUS = {
  error: {
    value: "error",
    color: "#E02E2E"
  },
  finished: {
    value: "finished",
    color: "#19C700"
  },
  running: {
    value: "running",
    color: "#F4C12A"
  },
  not_sent: {
    value: "not_sent",
    color: "#FF3F57"
  },
  sent: {
    value: "sent",
    color: "#19C700"
  },
  delivered: {
    value: "delivered",
    color: "#00853E"
  },
  read: {
    value: "read",
    color: "#3BAEFF"
  },
  replied: {
    value: "replied",
    color: "#2085CC"
  },
  scheduled: {
    value: "scheduled",
    color: "#F4C12A"
  },
  pending: {
    value: "pending",
    color: "#FF9E40"
  },
}

export const BLOCKING_REASON = {
  agent_not_assigned: {
    blockingMessage: "You are not assigned to this case",
    blockingReason: "agent_not_assigned",
  },
  case_closed: {
    blockingMessage: "This case has been closed",
    blockingReason: "case_closed",
  },
  channel_not_found: {
    blockingMessage: "channel_not_found",
    blockingReason: "channel_not_found",
  },
  channel_not_set_up: {
    blockingMessage: "channel_not_set_up",
    blockingReason: "channel_not_set_up",
  },
  channel_status_invalid: {
    blockingMessage: "channel_status_invalid",
    blockingReason: "channel_status_invalid",
  },
  channel_disabled: {
    blockingMessage: "channel_disabled",
    blockingReason: "channel_disabled",
  },
  channel_suspended: {
    blockingMessage: "channel_suspended",
    blockingReason: "channel_suspended",
  },
  channel_unknown: {
    blockingMessage: "channel_unknown",
    blockingReason: "channel_unknown",
  },
  internal_error: {
    blockingMessage: "internal_error",
    blockingReason: "internal_error",
  },
  reply_hours_exceeded: {
    blockingMessage: "reply_hours_exceeded",
    blockingReason: "reply_hours_exceeded",
  },
}

// console.log(sizeLimit === 0 ? 104857600 : (sizeLimit > 104857600 ? 104857600 : sizeLimit))

export const WHATSAPP_FILE_SIZE_LIMIT = {
  media: (sizeLimit) => {
    return ((parseInt(sizeLimit) > 16777216) ? 16777216 : parseInt(sizeLimit));
  },
  document: (sizeLimit) => {
    return ((parseInt(sizeLimit) > 104857600) ? 104857600 : parseInt(sizeLimit));
  }
}

export const PRODUCT_PREFIX = "product_";

export const SIDEMENU_MAP = {
  home: [],
  integration: [
    "channel",
    "channel:launcher",
    "channel:whatsapp",
    "channel:whatsappba",
    "channel:facebook",
    "channel:instagram",
    "channel:telegram",
    "channel:google_business",
    "channel:gb_profile",
    "channel:tokopedia",
    "channel:email",
    "channel:kataai",
    "api_key",
    "salestalk",
    // "chatbot",
    "webhooks",
  ],
  chatbot: [],
  operational: [
    "broadcast",
    "contact",
    "contact_segment",
    "import_contact",
  ],
  organization: [
    "member",
    "role",
    "topic",
    "case_label",
    "billing",
    "organization_details"
  ],
  setup: [
    "quick_reply",
    "office_hours",
    "welcome_message",
    "away_message",
    "closing_message",
    "user_rating",
    "assignment_rules",
    "inbox_settings",
    "qna_path",
    "auto_resolve_case",
    "auto_unassign_agent",
    "contact_custom_field"
  ],
  report: [
    "report",
    "report:daily_overview",
    "report:general_overview",
    "report:case_report",
    "report:case_ratings",
    "report:agent_report",
    "report:topic_report",
    "report:message_api_history",
    "report:clock_in_report",
    "report:referrer_utm"
  ],
  inbox: []
}
