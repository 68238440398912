import React from "react";
import "./PopupWarning.scss";
import { Modal, ModalBody } from "reactstrap";
import { FiAlertTriangle } from "react-icons/fi";

let PopupWarning = (props) => {
    return (
        <Modal isOpen={props.isShow} toggle={props.toggle} className={`modal-warning ${props._className}`}>
            <ModalBody>
                {!props.noIcon &&
                    <FiAlertTriangle />
                }

                {props.title &&
                    <b className="title">
                        {props.title}
                    </b>
                }

                <p>
                    {props.text}
                </p>

                <button className="orange-button" onClick={props.toggle}>
                    <b>Dismiss</b>
                </button>
            </ModalBody>
        </Modal>
    )
}

export default PopupWarning;