import React, { useState, useEffect } from 'react';
import './ModalOngoingCase.scss';
import { tapCoreRoomListManager } from "@taptalk.io/web-sdk";
import { connect } from 'react-redux';
import { FiAlertTriangle, FiArrowUpRight } from 'react-icons/fi';
import { setActiveRoom } from '../../../../../redux/actions/reduxActionActiveRoom';
import { setActiveCasePanel } from '../../../../../redux/actions/reduxActionActiveCasePanel';
import { doToast } from '../../../../../helper/HelperGeneral';
import { Modal, ModalBody } from "reactstrap";

let ModalOngoingCase = (props) => {
    let [onGoingCaseData, setOnGoingCaseData] = useState(false);
    let [loadingGetRoom, setLoadingGetRoom] = useState(false);

    useEffect(() => {
        if(props.onGoingCaseDataProps) {
            setOnGoingCaseData(props.onGoingCaseDataProps);
        }
    }, [props.onGoingCaseDataProps])

    let onClickGoToOpenCase = () => {
        setLoadingGetRoom(true);

        tapCoreRoomListManager.getRoomByXcID(onGoingCaseData.tapTalkXCRoomID, {
            onSuccess: (response) => {
                setLoadingGetRoom(false);
                props.onClickRoomListWithTaptalkRoomProps(response.room, onGoingCaseData);
                onCloseModalOngoingCase();
            },
            onError: (errorCode, errorMessage) => {
                setLoadingGetRoom(false);
                console.log(errorCode, errorMessage);
                doToast(errorMessage, "fail")
            }
        })
    }

    let onCloseModalOngoingCase = () => {
        if(!loadingGetRoom) {
            props.toggleModalOngoingCaseProps();
        }
    }
    
    return (
        <Modal className="modal-ongoing-case" isOpen={props.isShowModalOngoingProps} toggle={onCloseModalOngoingCase}>
            <ModalBody> 
                <p className="ongoing-title">
                    <FiAlertTriangle />
                    <b>Recipient Has Ongoing Case </b>
                </p>

                <p className="ongoing-description"> 
                    The selected recipient has an ongoing case. The case will have to be resolved before you can start a new message.
                </p>

                <div className="ongoing-button-wrapper">
                    <button className="no-fill-button button-50" onClick={onCloseModalOngoingCase}>
                        <b>Cancel</b>
                    </button>

                    {!loadingGetRoom ?
                        <button className="orange-button button-50" onClick={onClickGoToOpenCase}>
                            <b>Open Case</b>
                            <FiArrowUpRight />
                        </button>
                        :
                        <button className="orange-button button-50">
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>
                        </button>
                    }
                </div>
            </ModalBody>
        </Modal>
    );
}

const mapDispatchToProps = {
    setActiveRoom,
    setActiveCasePanel
};

export default connect(null, mapDispatchToProps)(ModalOngoingCase);

