import { combineReducers } from "redux";
import sideMenu from "./reduxReducerSideMenu";
import user from "./reduxReducerAuth";
import activeRoom from "./reduxReducerActiveRoom";
import activeCasePanel from "./reduxReducerActiveCasePanel";
import activeOrgID from "./reduxReducerActiveOrgID";
import changeContactInfo from "./reduxReducerChangeContactInfo";
import updateCaseLabel from './reduxReducerCaseLabel';
import quickReplyList from "./reduxReducerQuickReplyList";
import caseSortBy from "./reduxReducerCaseSortBy";
import caseDetailData from "./reduxReducerCaseDetailData";
import countryList from "./reduxReducerCountryList";
import startConversation from "./reduxReducerStartConversation";
import startConversationBa from "./reduxReducerStartConversationBa";
import selectedTier from "./reduxReducerSelectedTier";
import billingWarning from "./reduxReducerBillingWarning";
import allOrganizationDetail from "./reduxReducerAllOrganizationDetail";
import mediumData from "./reduxReducerMedium";
import previewImageOrVideo from "./reduxReducerPreviewImageOrVideo";
import toggleMineCase from "./reduxReducerToggleMineCase";
import clockState from "./reduxReducerClockState";
import replyMessage from "./reduxReducerReplyMessage";
import caseLabelList from './reduxReducerCaseLabelList';
import balanceData from './reduxReducerBalance';
import priceList from './reduxReducerPriceList';
import modalStartConversationBalance from './reduxReducerModalStartConversationBalance';
import globalSearch from './reduxReducerGlobalSearch';
import isWaitMarkAsJunk from './reduxReducerIsWaitMarkAsJunk';
import activeCaseActionHistory from './reduxReducerActiveCaseActionHistory';
import showOtherCase from './reduxReducerShowOtherCase';
import allowReplyOnAway from './reduxReducerAllowReplyOnAway';
import mediumLastRun from './reduxReducerMediumLastRun';
import reloadCaseDetail from './reduxReducerReloadCaseDetail';
import activeHandoverCase from './reduxReducerActiveHandoverCase';
import submitHandoverCase from './reduxReducerSubmitHandoverCase';
import allTopicList from './reduxReducerAllTopics';
import draftMessage from './reduxReducerDraftMessage';
import myTopics from './reduxReducerMyTopics';
import salesTalkInbox from './reduxReducerSalesTalkInbox';
import authDataSalestalk from './reduxReducerAuthSalestalk';
import salesTalkProductDetail from './reduxReducerSalestalkProductDetail';
import salesTalkSelectedProduct from './reduxReducerSalestalkSelectedProduct';
import salesTalkProductFilter from './reduxReducerSalestalkProductFilter';
import salesTalkNotConnect from './reduxReducerSalesTalkNotConnect';
import salesTalkErrorServer from './reduxReducerSalesTalkErrorServer';
import caseListChecked from './reduxReducerCaseListChecked';
import editContactInfo from './reduxReducerEditContactInfo';
import needReplyColorIndicator from './reduxReducerNeedReplyColorIndicator';
import forwardMessage from './reduxReducerForwardMessage';
import reconnectChatRoom from './reduxReducerReconnectChatRoom';
import reconnectRoomList from './reduxReducerReconnectRoomList';
import createGoogle from './reduxReducerCreateGoogle';
import caseFilter from './reduxReducerCaseFilter';
import quickReplyDocument from './reduxReducerQuickReplyDocument';
import allowHandover from './reduxReducerAllowHandover';
import showClosedCase from './reduxReducerShowClosedCase';
import activeInteractiveList from './reduxReducerActiveInteraciveList';
import activeQuickReplyInteractive from './reduxReducerActiveQuickReplyInteractive';
import allTopicListHandover from './reduxReducerAllTopicsHandover';
import emailAttachments from './reduxReducerEmailAttachments';
import inboxConfigsRedux from './reduxReducerInboxConfig';
import membershipData from './reduxReducerMembership';
import closeCaseNewMessageRecipient from './reduxReducerCloseCaseNewMessageRecipient';
import allowedModule from './reduxReducerAllowedModule';
import myAgentData from './reduxReducerMyAgentAccount';
import popupMessageInfo from './reduxReducerPopupMessageInfo';
import activeSharedMedia from './reduxReducerActiveSharedMedia';
import goToChat from './reduxReducerGoToChat';
import miitelKey from './reduxReducerMiitelKey';

const appReducer = combineReducers({
  sideMenu,
  user,
  activeRoom,
  activeCasePanel,
  activeOrgID,
  changeContactInfo,
  quickReplyList,
  caseSortBy,
  caseDetailData,
  caseLabelList,
  updateCaseLabel,
  countryList,
  startConversation,
  startConversationBa,
  selectedTier,
  billingWarning,
  allOrganizationDetail,
  mediumData,
  previewImageOrVideo,
  toggleMineCase,
  clockState,
  replyMessage,
  balanceData,
  priceList,
  modalStartConversationBalance,
  globalSearch,
  isWaitMarkAsJunk,
  activeCaseActionHistory,
  showOtherCase,
  allowReplyOnAway,
  mediumLastRun,
  reloadCaseDetail,
  activeHandoverCase,
  submitHandoverCase,
  allTopicList,
  draftMessage,
  myTopics,
  salesTalkInbox,
  authDataSalestalk,
  salesTalkProductDetail,
  salesTalkSelectedProduct,
  salesTalkProductFilter,
  salesTalkNotConnect,
  salesTalkErrorServer,
  caseListChecked,
  editContactInfo,
  needReplyColorIndicator,
  forwardMessage,
  reconnectChatRoom,
  reconnectRoomList,
  createGoogle,
  caseFilter,
  quickReplyDocument,
  allowHandover,
  showClosedCase,
  activeInteractiveList,
  activeQuickReplyInteractive,
  allTopicListHandover,
  emailAttachments,
  inboxConfigsRedux,
  membershipData,
  closeCaseNewMessageRecipient,
  allowedModule,
  myAgentData,
  popupMessageInfo,
  activeSharedMedia,
  goToChat,
  miitelKey
})

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;