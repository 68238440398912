import React from "react";
import { connect } from 'react-redux';
import "./CaseListComponent.scss";
import OtherAgentIcon from "../../../../assets/img/roomlist/icon-other-agent.svg";
import ChatbotIcon from "../../../../assets/img/roomlist/icon-chatbot-white.svg";
import HelperChat from '../../../../helper/HelperChat';
import HelperDate from '../../../../helper/HelperDate';
import { printRoomListName } from '../../../../helper/HelperGeneral';
import { taptalk } from "@taptalk.io/web-sdk";
import { FiAlertTriangle, FiCircle, FiCheckCircle } from "react-icons/fi";
import { setCaseListChecked } from "../../../../redux/actions/reduxActionCaseListChecked";
import { isObjectTwin } from "../../../../helper/HelperGeneral";

let CaseListComponent = (props) => {
    let {
        // totalHidden
        caseDetail, roomType, message,
        activeRoom, myAgentAccountID, labelCase,
        _containerRef, LabelItem,
        setIsItemHidden, isItemHidden, onClickCase,
        _className, draftMessage, caseListChecked,
        checkedType, allCase, setCaseListChecked,
        needReplyData, needReplyColorIndicator, hideCaseStatus
    } = props;

    let clickCheckboxCase = (_case, e) => {
        let _checked = e.target.checked;
        let roomID = "";
        let roomIDXc = _case.tapTalkXCRoomID;
        // let roomID = _case.tapTalkXCRoomID;

        if(_case.tapTalkRoom && _case.tapTalkRoom.lastMessage && _case.tapTalkRoom.lastMessage.room) {
            roomID = _case.tapTalkRoom.lastMessage.room.roomID;
        }
        
        let _caseListChecked = { ...caseListChecked };

        if (!_checked) {
            delete _caseListChecked[checkedType].data[roomIDXc];
            delete _caseListChecked[checkedType].data[roomID];
        } else {
            _caseListChecked[checkedType].data[roomIDXc] = { ..._case };
        }

        _caseListChecked[checkedType].checkedAll = isObjectTwin(allCase, _caseListChecked[checkedType].data)

        setCaseListChecked(_caseListChecked);
    }

    let printLabel = () => {
        let maxLength = 296;
        let lengthNow = 0;

        return (
            labelCase.map((label, index) => {
                if (labelCase.length > 4) {
                    let labelLength = label.name.length > 13 ? 13 : label.name.length;
                    let labelWidth = (labelLength * 6) + 16 + 2;

                    lengthNow = labelWidth + lengthNow;
                }

                if (maxLength > lengthNow) {
                    return (
                        <LabelItem
                            key={label.id}
                            index={index}
                            label={label}
                            container={_containerRef}
                            isItemHidden={isItemHidden}
                            setIsItemHidden={setIsItemHidden}
                        />
                    )
                }

                return null;
            })
        )
    }

    let checkIsRoomSelected = (data) => {
        let isChecked = false;

        if (caseListChecked[checkedType].data[caseDetail.tapTalkXCRoomID]) {
            isChecked = true;
        }

        if (message && message.lastMessage && message.lastMessage.room && caseListChecked[checkedType].data[message.lastMessage.room.roomID]) {
            isChecked = true;
        }

        return isChecked;
    }

    return (
        // new-case-list-wrapper-active
        // new-case-list-wrapper-reply
        <div
            className={`
                new-case-list-wrapper 
                ${(activeRoom !== null) && (caseDetail.tapTalkXCRoomID === activeRoom.xcRoomID) ?
                    "new-case-list-wrapper-active"
                    :
                    ""
                }

                ${(
                    ((roomType === "other" || roomType === "chatbot" || roomType === "search-global") ?
                        (roomType === "chatbot" ?
                            ((
                                message &&
                                message.lastMessage.user.userRole.code !== "" &&
                                message.lastMessage.user.userRole.code !== "chatbot" &&
                                !caseDetail.isJunk
                            ) ?
                                "new-case-list-wrapper-reply"
                                :
                                ""
                            )
                            :
                            (roomType === "search-global" ?
                                (caseDetail.assigneeType === "chatbot" ?
                                    ((
                                        message &&
                                        message.lastMessage.user.userRole.code !== "" &&
                                        message.lastMessage.user.userRole.code !== "chatbot" &&
                                        !caseDetail.isJunk &&
                                        !caseDetail.isClosed
                                    ) ?
                                        "new-case-list-wrapper-reply"
                                        :
                                        ""
                                    )
                                    :
                                    ((
                                        message &&
                                        message.lastMessage.user.userRole.code !== "" &&
                                        message.lastMessage.user.userRole.code !== "agent" &&
                                        !caseDetail.isJunk &&
                                        !caseDetail.isClosed
                                    ) ?
                                        "new-case-list-wrapper-reply"
                                        :
                                        ""
                                    )
                                )
                                :
                                ((
                                    message &&
                                    message.lastMessage.user.userRole.code !== "" &&
                                    message.lastMessage.user.userRole.code !== "agent" &&
                                    !caseDetail.isJunk
                                ) ?
                                    "new-case-list-wrapper-reply"
                                    :
                                    ""
                                )
                            )
                        )
                        :
                        ((roomType === "mine") ?
                            ((
                                message &&
                                message.lastMessage &&
                                message.lastMessage.user.userID !== taptalk.getTaptalkActiveUser().userID &&
                                message.unreadCount === 0 &&
                                message.lastMessage.user.userRole.code !== "" &&
                                message.lastMessage.user.userRole.code !== "agent" &&
                                !caseDetail.isJunk
                            ) ?
                                "new-case-list-wrapper-reply"
                                :
                                ""
                            )
                            :
                            ""
                        )
                    )
                )}
                ${_className}
            `}
            data-room-id={(message && message.lastMessage && message.lastMessage.room) ? message.lastMessage.room.roomID : caseDetail.tapTalkXCRoomID}
        >
            <div className="click-area-case-list" onClick={() => onClickCase(caseDetail)} />

            <div className="new-case-list-top">
                <p className="omnichannel-name">
                    {HelperChat.generateIconOmnichannel(caseDetail) === "" ?
                        ""
                        :
                        <img src={HelperChat.generateIconOmnichannel(caseDetail)} alt="" className="omnichannel-icon" />
                    }

                    {/* {TAPLIVE_MEDIUM_TEXT[caseDetail.medium]} */}
                    {caseDetail.mediumChannelName}
                </p>

                <div className={`need-reply-or-junk ${!caseListChecked[checkedType].isShow ? "need-reply-or-junk-no-check" : ""} `}>
                    {(caseDetail.isJunk) ?
                        <div className="need-reply-or-junk-badge">
                            <FiAlertTriangle />
                            JUNK
                        </div>
                        :
                        ((roomType === "other" || roomType === "chatbot" || roomType === "search-global") ?
                            (roomType === "chatbot" ?
                                ((
                                    message &&
                                    message.lastMessage.user.userRole.code !== "" &&
                                    message.lastMessage.user.userRole.code !== "chatbot" &&
                                    !caseDetail.isJunk
                                ) &&
                                    <div
                                        className={`need-reply-or-junk-badge ${(activeRoom !== null) && (caseDetail.tapTalkXCRoomID === activeRoom.xcRoomID) ? "white-need-reply-badge" : ""} `}
                                        style={{
                                            background:
                                                needReplyData[caseDetail.tapTalkXCRoomID] ?
                                                    needReplyData[caseDetail.tapTalkXCRoomID].needReplyHexColor
                                                    :
                                                    HelperChat.needReplyDefaultColor(needReplyColorIndicator)
                                        }}
                                    >

                                        NEED REPLY
                                    </div>
                                )
                                :
                                (roomType === "search-global" ?
                                    (caseDetail.assigneeType === "chatbot" ?
                                        ((message &&
                                            message.lastMessage.user.userRole.code !== "" &&
                                            message.lastMessage.user.userRole.code !== "chatbot" &&
                                            !caseDetail.isJunk &&
                                            !caseDetail.isClosed
                                        ) &&
                                            <div
                                                className={`need-reply-or-junk-badge ${(activeRoom !== null) && (message.lastMessage.room.roomID === activeRoom.roomID) ? "white-need-reply-badge" : ""} `}
                                                style={{
                                                    background:
                                                        needReplyData[message.lastMessage.room.roomID] ?
                                                            needReplyData[message.lastMessage.room.roomID].needReplyHexColor
                                                            :
                                                            HelperChat.needReplyDefaultColor(needReplyColorIndicator)
                                                }}
                                            >

                                                NEED REPLY
                                            </div>
                                        )
                                        :
                                        ((
                                            message.lastMessage.user.userRole.code !== "agent" &&
                                            !caseDetail.isJunk &&
                                            !caseDetail.isClosed
                                        ) &&
                                            <div
                                                className={`need-reply-or-junk-badge ${(activeRoom !== null) && (message.lastMessage.room.roomID === activeRoom.roomID) ? "white-need-reply-badge" : ""} `}
                                                style={{
                                                    background:
                                                        needReplyData[message.lastMessage.room.roomID] ?
                                                            needReplyData[message.lastMessage.room.roomID].needReplyHexColor
                                                            :
                                                            HelperChat.needReplyDefaultColor(needReplyColorIndicator)
                                                }}
                                            >

                                                NEED REPLY
                                            </div>
                                        )
                                    )
                                    :
                                    ((
                                        message &&
                                        message.lastMessage.user.userRole.code !== "agent" &&
                                        !caseDetail.isJunk
                                    ) &&
                                        <div
                                            className={`need-reply-or-junk-badge ${(activeRoom !== null) && (message.lastMessage.room.roomID === activeRoom.roomID) ? "white-need-reply-badge" : ""} `}
                                            style={{
                                                background:
                                                    needReplyData[message.lastMessage.room.roomID] ?
                                                        needReplyData[message.lastMessage.room.roomID].needReplyHexColor
                                                        :
                                                        HelperChat.needReplyDefaultColor(needReplyColorIndicator)
                                            }}
                                        >

                                            NEED REPLY
                                        </div>
                                    )
                                )
                            )
                            :
                            (roomType === "mine" ?
                                ((
                                    message &&
                                    message.lastMessage.user.userID !== taptalk.getTaptalkActiveUser().userID &&
                                    // message.unreadCount === 0 &&
                                    message.lastMessage.user.userRole.code !== "agent" &&
                                    !caseDetail.isJunk
                                ) &&
                                    <div
                                        className={`need-reply-or-junk-badge ${(activeRoom !== null) && (message.lastMessage.room.roomID === activeRoom.roomID) ? "white-need-reply-badge" : ""} `}
                                        style={{
                                            background:
                                                needReplyData[message.lastMessage.room.roomID] ?
                                                    needReplyData[message.lastMessage.room.roomID].needReplyHexColor
                                                    :
                                                    HelperChat.needReplyDefaultColor(needReplyColorIndicator)
                                        }}
                                    >
                                        NEED REPLY
                                    </div>
                                )
                                :
                                ""
                            )
                        )

                    }

                    {caseListChecked[checkedType].isShow &&
                        // <div className={`custom-checkbox${ (activeRoom && message.lastMessage.room.roomID === activeRoom.roomID) ? "-white" : "" } `}>
                        <div className={`custom-checkbox${(activeRoom && caseDetail.tapTalkXCRoomID === activeRoom.xcRoomID) ? "-white" : ""} `}>
                            <input
                                type="checkbox"
                                id={`select-${caseDetail.tapTalkXCRoomID} `}
                                checked={checkIsRoomSelected(caseDetail)}
                                onClick={(e) => clickCheckboxCase(caseDetail, e)}
                            />
                            <label htmlFor={`select-${caseDetail.tapTalkXCRoomID} `} />
                        </div>
                    }
                </div>
            </div>
            {/* end of new case list top */}

            <div className="new-case-list-bottom">
                <p className="chat-roomname">
                    <b>
                        {/* {caseDetail.userAlias ?
                            caseDetail.userAlias !== "" ? `${ caseDetail.userAlias } (${ caseDetail.userFullName })` : caseDetail.userFullName
                            :
                            caseDetail.userFullName
                        } */}

                        {printRoomListName(caseDetail, props.inboxConfigsRedux.showContactPhone)}
                    </b>

                    {/* {(
                        roomType === "other" ||
                        roomType === "mine"
                        // roomType === "chatbot" ||
                        // roomType === "search-global"
                    ) ? */}
                    {(message && message.lastMessage.created) ?
                        <span className="chat-timestamp">
                            {HelperDate.isToday(message.lastMessage.created) ?
                                HelperDate.formatToString(new Date(message.lastMessage.created), "HH:mm")
                                :
                                HelperDate.isYerterday(message.lastMessage.created) ?
                                    "Yesterday"
                                    :
                                    HelperDate.formatToString(new Date(message.lastMessage.created), "dd/MM/yyyy")
                            }
                        </span>
                        :
                        <span className="chat-timestamp">
                            {caseDetail.lastMessageTime ?
                                <>
                                    {HelperDate.isToday(caseDetail.lastMessageTime) ?
                                        HelperDate.formatToString(new Date(caseDetail.lastMessageTime), "HH:mm")
                                        :
                                        HelperDate.isYerterday(caseDetail.lastMessageTime) ?
                                            "Yesterday"
                                            :
                                            HelperDate.formatToString(new Date(caseDetail.lastMessageTime), "dd/MM/yyyy")
                                    }
                                </>
                                :
                                ""
                            }
                        </span>
                    }
                </p>

                <p className="chat-case-id">
                    <b>#{caseDetail.stringID}</b>{(caseDetail.topicName !== "" ? " | " : "") + caseDetail.topicName}
                </p>

                {(
                    roomType === "other" ||
                    roomType === "mine" ||
                    // roomType === "chatbot" ||
                    roomType === "search-global"
                )
                    &&
                    <>
                        <p className="chat-last-message">
                            <p
                                dangerouslySetInnerHTML={
                                    {
                                        __html: (!HelperChat.generateLastMessageSpanRoomName(message.lastMessage, caseDetail, props.inboxConfigsRedux.showContactPhone, props.myAgentData).withOutSender ? HelperChat.generateLastMessageSpanRoomName(message.lastMessage, caseDetail, props.inboxConfigsRedux.showContactPhone, props.myAgentData).lastChatFrom : "") + HelperChat.generateLastMessageSpanRoomName(message.lastMessage, caseDetail, props.inboxConfigsRedux.showContactPhone, props.myAgentData).message
                                    }
                                }
                            />

                            {roomType === "mine" &&
                                <div className="message-status">
                                    {(message.lastMessage.user.userID !== taptalk.getTaptalkActiveUser().userID) && (message.unreadCount > 0) && !caseDetail.isJunk &&
                                        // unread badge
                                        <div className={`unread-count-wrapper  ${(activeRoom !== null) && (message.lastMessage.room.roomID === activeRoom.roomID) ? "white-unread-count-wrapper" : ""} `}>
                                            <b>{message.unreadCount > 99 ? "99+" : message.unreadCount}</b>
                                        </div>
                                        // unread badge
                                    }

                                    {(!draftMessage[message.lastMessage.room.roomID] && message.lastMessage.user.userID === taptalk.getTaptalkActiveUser().userID) && !caseDetail.isJunk &&
                                        //message status badge
                                        <img src={HelperChat.renderChatStatus(message.lastMessage, activeRoom)} alt="" />
                                        //message status badge
                                    }
                                </div>
                            }
                        </p>

                        <p className="typing-room-list">
                            <span className="typing-name"></span>is typing<span className="typing-dot-one">.</span><span className="typing-dot-two">.</span><span className="typing-dot-three">.</span>

                            {(message.lastMessage.user.userID !== taptalk.getTaptalkActiveUser().userID) && (message.unreadCount > 0) && !caseDetail.isJunk &&
                                // unread badge
                                <div className={`unread-count-wrapper  ${(activeRoom !== null) && (message.lastMessage.room.roomID === activeRoom.roomID) ? "white-unread-count-wrapper" : ""} `}>
                                    <b>{message.unreadCount > 99 ? "99+" : message.unreadCount}</b>
                                </div>
                                // unread badge
                            }
                        </p>
                    </>
                }

                {labelCase.length > 0 &&
                    // <div className="label-list-wrapper">
                    // <div className="case-tab-label-wrapper">
                    <>
                        <div className="label-container" ref={_containerRef}>
                            {
                                labelCase.length > 0 &&
                                // labelCase.map((label, index) => {
                                //     return (
                                //         <LabelItem
                                //             key={label.id}
                                //             index={index}
                                //             label={label}
                                //             container={_containerRef}
                                //             isItemHidden={isItemHidden}
                                //             setIsItemHidden={setIsItemHidden}
                                //         />
                                //     )
                                // })
                                printLabel()
                            }
                        </div>

                        {/* {totalHidden === 0 ?
                                null
                                :
                                <span className="more-label"><b>+{totalHidden}</b></span>
                            } */}
                    </>
                    //     </div>
                    //  </div>
                }

                {(
                    roomType === "solved" &&
                    (caseDetail.closedTime !== 0 && caseDetail.agentAccountID === 0)
                ) &&
                    ((caseDetail.assigneeType !== "agent" && caseDetail.chatbotName !== "") ?
                        <div className="agent-list-wrapper">
                            <div className="agent-badge unassigned-agent-badge">
                                <img src={caseDetail.assigneeType !== "agent" ? ChatbotIcon : OtherAgentIcon} alt="" />
                                {(caseDetail.assigneeType !== "agent") ? ((caseDetail.chatbotName && caseDetail.chatbotName !== "") ? caseDetail.chatbotName : "Chatbot") : "Unassigned"}
                            </div>
                        </div>
                        :
                        <div className={`agent-list-wrapper`}>
                            <div className="agent-badge-wrapper">
                                <div className="agent-badge unassigned-agent-badge">
                                    <img src={OtherAgentIcon} alt="" />
                                    Unassigned
                                </div>
                            </div>
                        </div>
                    )
                }

                {(
                    (
                        roomType === "other" ||
                        roomType === "solved"
                        // roomType === "search-global"
                    ) &&
                    caseDetail.assigneeType !== "chatbot" &&
                    caseDetail.agentFullName !== ""
                ) &&
                    <div
                        className={`
agent-list-wrapper
abc
                            ${(
                                roomType === "search-global" &&
                                caseDetail.assigneeType === "agent" &&
                                caseDetail.agentAccountID === 0
                            ) ?
                                "force-hide"
                                :
                                ""
                            }
`}
                    >
                        {((caseDetail.agentAccountID !== 0) && (caseDetail.agentAccountID !== myAgentAccountID)) &&
                            <div className="agent-badge-wrapper">
                                <div className="agent-badge other-agent-badge">
                                    <img src={OtherAgentIcon} alt="" />
                                    {caseDetail.agentAlias !== "" ? `${caseDetail.agentFullName} (${caseDetail.agentAlias})` : caseDetail.agentFullName}
                                </div>
                            </div>
                        }

                        {((caseDetail.agentAccountID !== 0) && (caseDetail.agentAccountID === myAgentAccountID)) &&
                            <div className="agent-badge-wrapper">
                                <div className="agent-badge my-agent-badge">
                                    <img src={OtherAgentIcon} alt="" />
                                    Me
                                </div>
                            </div>
                        }
                    </div>
                }

                {(
                    roomType === "chatbot" ||
                    roomType === "search-global"
                ) &&
                    <div className="case-status-wrapper">
                        {(!caseDetail.isClosed && !hideCaseStatus) &&
                            <div className="agent-badge-new-wrapper">
                                <div className="agent-badge-new my-agent-badge-new">
                                    <FiCircle />
                                    Open
                                </div>
                            </div>
                        }

                        {caseDetail.isClosed &&
                            <div className="agent-badge-new-wrapper">
                                <div className="agent-badge-new resolved-agent-badge-new">
                                    <FiCheckCircle />
                                    Resolved
                                </div>
                            </div>
                        }

                        {/* agent badge */}
                        {caseDetail.agentAccountID === 0 &&
                            // <div className="agent-badge-new-wrapper">
                            //     <div className="agent-badge-new unassigned-agent-badge-new">
                            //         <img src={OtherAgentIcon} alt="" />
                            //         Unassigned
                            //     </div>
                            // </div>

                            <div className={`agent-badge unassigned-agent-badge`}>
                                <img src={caseDetail.assigneeType !== "agent" ? ChatbotIcon : OtherAgentIcon} alt="" />
                                {(caseDetail.assigneeType !== "agent") ? ((caseDetail.chatbotName && caseDetail.chatbotName !== "") ? caseDetail.chatbotName : "Chatbot") : "Unassigned"}
                            </div>
                        }

                        {((caseDetail.agentAccountID !== 0) && (caseDetail.agentAccountID !== myAgentAccountID)) &&
                            <div className="agent-badge-wrapper">
                                <div className="agent-badge other-agent-badge">
                                    <img src={OtherAgentIcon} alt="" />
                                    {caseDetail.agentAlias !== "" ? `${caseDetail.agentFullName} (${caseDetail.agentAlias})` : caseDetail.agentFullName}
                                </div>
                            </div>
                        }

                        {((caseDetail.agentAccountID !== 0) && (caseDetail.agentAccountID === myAgentAccountID)) &&
                            <div className="agent-badge-wrapper">
                                <div className="agent-badge my-agent-badge">
                                    <img src={OtherAgentIcon} alt="" />
                                    Me
                                </div>
                            </div>
                        }
                        {/* agent badge */}
                    </div>
                }
            </div>
            {/* end of new case list bottom */}
        </div >
    )
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    draftMessage: state.draftMessage,
    caseListChecked: state.caseListChecked,
    needReplyColorIndicator: state.needReplyColorIndicator,
    inboxConfigsRedux: state.inboxConfigsRedux,
    myAgentData: state.myAgentData
});

const mapDispatchToProps = {
    setCaseListChecked
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseListComponent);

