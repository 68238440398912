import React, { useState, useEffect } from "react";
import "./OrganizationBilling.scss";
import { FiInfo } from "react-icons/fi";
// import { MdExpandMore } from "react-icons/md";
// import { MdExpandLess } from "react-icons/md";
// import { MdInfoOutline } from "react-icons/md";
import NewOrganizationHeader from "../newOrganizationHeader/NewOrganizationHeader";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceProfile from "../../../services/profile/ServiceProfile";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import HelperAuth from "../../../helper/HelperAuth";
import { doToast, scrollToClass } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { setParentMenuBlockActive } from '../../../redux/actions/reduxActionSideMenu';
import { setAuthActive } from '../../../redux/actions/reduxActionAuth';
import iconAt from '../../../assets/img/icon-at.svg';
import iconGlobeOrange from '../../../assets/img/icon-globe-orange.svg';
import Select from "react-select";
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";

const iconDropOrange =
    "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
const iconDropGrey =
    "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

let OrganizationBilling = (props) => {
    let [isWaitForResponse, setIsWaitForResponse] = useState(false);
    let [isShowCreateForm, setIsShowCreateForm] = useState(false);
    // let [countyDropdownShow, setCountyDropdownShow] = useState(false);
    // let [tooltipShow, setTooltipShow] = useState(false);
    let [valOrganization, setValOrganization] = useState({
        name: "",
        timeZone: "",
        countryID: "",
        regionName: "",
        cityName: "",
        fullAddress: "",
        postalCode: "",
    });
    let [isShowGoBackButton, setIsShowGoBackButton] = useState(false);
    let [finishGetProfile, setFinishGetProfile] = useState(false);
    let [timezoneList, setTimezoneList] = useState([]);
    let [countryList, setCountryList] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [errorMessageData, setErrorMessageData] = useState({
        name: "",
        timeZone: "",
        countryID: ""
    })

    let back = () => {
        props.setParentMenuBlockActive({
            activeMenuBlock: "home",
            submenuList: null
        })

        props.history.push(`/home`)
    }

    useEffect(() => {
        if (HelperAuth.userWasLoggedIn()) {
            getProfile();
            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
            if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.init(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY);
                mixpanel.identify(myAgentData.account.id);
                mixpanel.track(
                    "[Page] Create Organization",
                    {
                        userFullName: myAgentData.account.fullName,
                        userID: myAgentData.account.id,
                    }
                );
            }
        }
    }, [])

    useEffect(() => {
        if (finishGetProfile) {
            getTimezone()
        }
    }, [finishGetProfile])

    useEffect(() => {
        if (props.countryList) {
            let newCountryList = [];

            props.countryList.map((value) => {
                let data = {
                    value: "",
                    label: ""
                }

                data.value = value.id;
                data.label = value.officialName;

                newCountryList.push(data);

                return null;
            })

            setCountryList(newCountryList)
        }
    }, [props.countryList])

    // let toggleCountryDropdown = () => {
    //     setCountyDropdownShow(!countyDropdownShow);
    // }

    // let toggleTooltip = () => {
    //     setTooltipShow(!tooltipShow);
    // }

    let getUserData = () => {
        return HelperCustomEncryptor.doDecrypt(props.user.data);
    }

    let handleChange = (e) => {
        let _valOrganization = { ...valOrganization };
        let _errorMessageData = { ...errorMessageData };
        _errorMessageData[e.target.id] = "";
        _valOrganization[e.target.id] = e.target.value;
        setErrorMessageData(_errorMessageData);
        setValOrganization(_valOrganization);
    }

    let onClickCreateOrganization = (e) => {
        e.preventDefault();

        let _errorMessageData = { ...errorMessageData };
        let errorCount = 0;
        let scrollToError = "";

        if (valOrganization.name === "") {
            _errorMessageData.name = "This field is required"
            errorCount = errorCount + 1;

            if (scrollToError === "") { scrollToError = `input-name` }
        }

        if (valOrganization.name.length > 200) {
            _errorMessageData.name = "Characters exceed limit"
            errorCount = errorCount + 1;

            if (scrollToError === "") { scrollToError = `input-name` }
        }

        if (valOrganization.timeZone === "") {
            _errorMessageData.timeZone = "Please select timezone"
            errorCount = errorCount + 1;

            if (scrollToError === "") { scrollToError = `input-timezone` }
        }

        if (valOrganization.countryID === "") {
            _errorMessageData.countryID = "Please select country"
            errorCount = errorCount + 1;

            if (scrollToError === "") { scrollToError = `input-country` }
        }

        if (errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${scrollToError}`);
        } else {
            setIsWaitForResponse(true);

            ServiceOrganization.postNewOrganization(valOrganization, (response) => {
                if (response.dataResult.error.code === "") {
                    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                            "[Action] Create Organization",
                            {
                                userFullName: myAgentData.account.fullName,
                                userID: myAgentData.account.id,
                            }
                        );
                    }

                    window.location.href = window.location.origin + `/o/${response.dataResult.data.organization.id}-${response.dataResult.data.organization.createdTime}/select-tier`;
                } else {
                    setIsWaitForResponse(false);
                    doToast(response.dataResult.error.message, 'fail');
                }
            })
        }

    }

    let getProfile = () => {
        let _retriveDataError = { ...retriveDataError };

        ServiceProfile.postGetProfile((response) => {
            let dataResult = response.dataResult;
            let _user = { ...HelperCustomEncryptor.doDecrypt(props.user.data) };

            if (response.dataResult.error.message === "") {
                _user.account = response.dataResult.data.account;
                props.setAuthActive({
                    data: HelperCustomEncryptor.doEncrypt(_user)
                });
                setFinishGetProfile(true);
                let data = response.dataResult.data;

                if (data.memberships.length > 0) {
                    setIsShowGoBackButton(true);
                }

                if (!data.canCreateOrganization && data.memberships.length > 0) {
                    props.history.push(`/home`)
                }
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }
        })
    }

    let getTimezone = () => {
        let _retriveDataError = { ...retriveDataError };

        ServiceOrganization.postGetTimezone((response) => {
            let dataResult = response.dataResult;

            if (response.dataResult.error.message === "") {
                let data = response.dataResult.data;
                let newTimezoneList = [];

                data.timeZones.map((value) => {
                    let data = {
                        value: "",
                        label: ""
                    }

                    data.value = value.name;
                    value.utcOffset = value.utcOffset[0] !== "-" ? `+${value.utcOffset}` : value.utcOffset;
                    value.name = value.name.replace(/_/g, " ");
                    data.label = `${value.utcOffset} ${value.name} (${value.abbrev})`;

                    newTimezoneList.push(data);

                    return null;
                })

                setTimezoneList(newTimezoneList);
            } else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }
        })
    }

    let onChangeSelect = (id, e) => {
        let _valOrganization = { ...valOrganization };
        let _errorMessageData = { ...errorMessageData };
        _valOrganization[id] = e.value;
        _errorMessageData[id] = "";
        setErrorMessageData(_errorMessageData);
        setValOrganization(_valOrganization);
    }

        // let validateForm = () => {
        //     let _valOrganization = {...valOrganization};

        //     return (
        //         _valOrganization.name.length < 1 ||
        //         _valOrganization.timeZone === "" || 
        //         _valOrganization.countryID === ""
        //     )
        // }

        let styleSelect = () => ({
            indicatorsContainer: provide => ({
                ...provide,
                height: "48px",
            }),
            valueContainer: provide => ({
                ...provide,
                height: "100%"
            }),
            clearIndicator: () => ({
                // display: "none"
            }),
            dropdownIndicator: (provided, { isFocused }) => ({
                ...provided,
                background: isFocused ? iconDropOrange : iconDropGrey,
                transition: "0.25s ease-in-out",
                color: "transparent !important",
                width: "24px",
                height: "24px",
                // transform: isFocused ? "rotate(-180deg)" : ""
            }),
            container: (provided) => ({
                ...provided,
                width: "450px"
            }),
            control: (provide, { isFocused }) => ({
                ...provide,
                padding: "0 5px",
                // height: "48px",
                borderRadius: "8px",
                border: isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important",
                boxShadow: "none !important",
                // marginBottom: "24px",
                cursor: "pointer"
            }),
            indicatorSeparator: provide => ({ ...provide, display: "none" }),
            menu: provide => ({
                ...provide,
                borderRadius: "8px",
                boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
                overflow: "hidden"
            }),
            option: (provide, state) => {
                const { isSelected } = state;
                const checked = {
                    color: "#ff7e00",
                    content: '"✔"',
                    position: "absolute",
                    left: "0",
                    top: "50%",
                    transform: "translate(100%, -50%)"
                };
                return {
                    ...provide,
                    position: "relative",
                    background: "#fff",
                    padding: "12px 15px 12px 35px",
                    cursor: "pointer",
                    color: isSelected ? "#ff7e00" : "#121212",
                    ":hover": { background: "#ffe7d0" },
                    ":before": isSelected ? checked : null
                };
            }
        })

        return (
            <React.Fragment>
                <ToastContainer className="ToastContainer" />

                <NewOrganizationHeader />

                <div id="page-billing-organization">
                    <SectionWrap
                        {...props}
                        orgID={props.match.params.orgID}
                        _className="section-width-100 pos-relative"
                        barVerifyClass="margin-top-0"
                    >
                        {retriveDataError.code ?
                            <RetrieveDataLoading
                                isLoading={false}
                                errorMessage={retriveDataError.message}
                            />
                            :
                            <React.Fragment>
                                {!isShowCreateForm ?
                                    <div className="create-organization-outer-wrapper">
                                        <div className="create-organization-head create-organization-head-top">
                                            <p className="create-organization-head-title">
                                                <b>Hello, {getUserData().account.fullName}</b>
                                            </p>
                                        </div>

                                        <div className="create-organization-body">
                                            <div className="create-organization-body-1">
                                                <div className="create-organization-body-1-inner">
                                                    <div className="create-organization-body-1-icon-wrapper">
                                                        <img src={iconAt} className="create-organization-body-1-icon" alt="" />
                                                    </div>
                                                    <div className="create-organization-body-2">
                                                        <p className="create-organization-body-2-title">
                                                            <b>ACCOUNT</b>
                                                        </p>

                                                        <p className="create-organization-body-2-desc">
                                                            <b>{getUserData().account.email}</b>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <ButtonWithLoadingOrIcon
                                                className="no-fill-button main-button-40"
                                                text="Create Organization"
                                                icon={{
                                                    type: "img",
                                                    src: iconGlobeOrange
                                                }}
                                                position="left"
                                                onClickAction={() => setIsShowCreateForm(true)}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="create-organization-outer-wrapper">
                                        <form onSubmit={onClickCreateOrganization}>
                                            {isShowGoBackButton &&
                                                <BackButton text="Back to Home" onClick={back} />
                                            }

                                            <div id="organization-billing-header">
                                                <div className="create-organization-head">
                                                    <p className="create-organization-head-title">
                                                        <b>Create Organization</b>
                                                    </p>

                                                    <div className="create-organization-head-button">
                                                        {!isWaitForResponse ?
                                                            <>
                                                                <ButtonWithLoadingOrIcon
                                                                    className="no-fill-button main-button-40"
                                                                    text="Cancel"
                                                                    onClickAction={() => {
                                                                        setIsShowCreateForm(false);
                                                                        setValOrganization({
                                                                            name: "",
                                                                            timeZone: "",
                                                                            countryID: "",
                                                                            regionName: "",
                                                                            cityName: "",
                                                                            fullAddress: "",
                                                                            postalCode: ""
                                                                        })
                                                                    }}
                                                                />

                                                                <ButtonWithLoadingOrIcon
                                                                    // isDisabled={validateForm()}
                                                                    className="orange-button main-button-40"
                                                                    text="Create Organization"
                                                                />
                                                            </>
                                                            :
                                                            <>
                                                                <ButtonWithLoadingOrIcon
                                                                    className="no-fill-button main-button-40"
                                                                    text="Cancel"
                                                                    isDisabled
                                                                />

                                                                <ButtonWithLoadingOrIcon
                                                                    isLoading
                                                                    loadingColor="gray"
                                                                    isDisabled
                                                                    className="orange-button main-button-40"
                                                                    text="Create Organization"
                                                                    position="left"
                                                                />
                                                            </>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-content-wrapper">
                                                    <label>
                                                        Organization Name
                                                        <span className={`input-counter ${errorMessageData.name === "Characters exceed limit" ? "red-text" : ""}`}>
                                                            {valOrganization.name.length}/200
                                                        </span>
                                                    </label>

                                                    <input type="text"
                                                        placeholder="Input organization name here"
                                                        id="name"
                                                        onChange={(e) => handleChange(e)}
                                                        value={valOrganization.name}
                                                        disabled={isWaitForResponse}
                                                        className={`input-name ${errorMessageData.name !== "" ? "border-red" : ""}`}
                                                    />
                                                    {errorMessageData.name !== "" &&
                                                        <ErrorOrInfoComp
                                                            text={errorMessageData.name}
                                                            icon={<FiInfo />}
                                                            _className="red-text"
                                                        />
                                                    }
                                                </div>

                                                <label>Timezone</label>
                                                <div className="custom-select2-wrapper">
                                                    <Select
                                                        options={timezoneList}
                                                        placeholder="Select Timezone"
                                                        onChange={(e) => onChangeSelect("timeZone", e)}
                                                        styles={styleSelect()}
                                                        isDisabled={isWaitForResponse}
                                                        className={errorMessageData.timeZone !== "" ? "border-red" : ""}
                                                        classNamePrefix="react-select"
                                                        blurInputOnSelect={true}
                                                    />
                                                </div>
                                                {errorMessageData.timeZone !== "" &&
                                                    <ErrorOrInfoComp
                                                        text={errorMessageData.timeZone}
                                                        icon={<FiInfo />}
                                                        _className="red-text"
                                                    />
                                                }

                                                <label>Country</label>
                                                <div className="custom-select2-wrapper">
                                                    <Select
                                                        options={countryList}
                                                        placeholder="Select Country"
                                                        onChange={(e) => onChangeSelect("countryID", e)}
                                                        styles={styleSelect()}
                                                        isDisabled={isWaitForResponse}
                                                        className={errorMessageData.countryID !== "" ? "border-red" : ""}
                                                        classNamePrefix="react-select"
                                                        blurInputOnSelect={true}
                                                    />
                                                </div>
                                                {errorMessageData.countryID !== "" &&
                                                    <ErrorOrInfoComp
                                                        text={errorMessageData.countryID}
                                                        icon={<FiInfo />}
                                                        _className="red-text"
                                                    />
                                                }
                                            </div>

                                            {/* <b>Payment Settings</b>

                                        <label>Name On Card</label>
                                        <input type="text" 
                                            placeholder="Insert name on card"
                                        />

                                        <label>Card Number</label>
                                        <input type="password" autoComplete="false"
                                            placeholder="1234 4567 8910"
                                        />

                                        <div className="payment-div-50"> 
                                            <label>Expiry</label>
                                            <input type="password" autoComplete="false" 
                                                placeholder="mm/yy"
                                            />
                                        </div>

                                        <div className="payment-div-50"> 
                                            <label>
                                                CVV/CVC 
                                                <MdInfoOutline onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} />

                                                <div className="custom-tooltip" style={{display: tooltipShow ? "block" : "none"}}>
                                                    CVV/CVC is a 3 digit number that is 
                                                    located at the back of your card.
                                                </div>
                                            </label>
                                            <input type="password" autoComplete="false" 
                                                placeholder="3 digits"
                                            />
                                        </div>

                                        <label>County</label>
                                        <div className="country-dropdown-wrapper" onClick={toggleCountryDropdown}>
                                            <div className="dropdown-value-wrapper">
                                                Indonesia

                                                <div className="arrow-down-wrapper">
                                                    {countyDropdownShow ? 
                                                        <MdExpandLess />
                                                        :
                                                        <MdExpandMore />
                                                    }
                                                </div>
                                            </div>

                                            <div className="option-list-wrapper" style={{display: countyDropdownShow ? "inline-block" : "none"}}>
                                                <ul>
                                                    <li>India</li>
                                                    <li>Indonesia</li>
                                                    <li>India</li>
                                                    <li>Indonesia</li>
                                                    <li>India</li>
                                                    <li>Indonesia</li>
                                                </ul>
                                            </div>
                                        </div>

                                        <label>
                                            Address
                                            <span className="span-optional">Optional</span>
                                        </label>
                                        <textarea rows="4" /> */}
                                            {/* <div className="payment-setting-foot">
                                            {!isWaitForResponse ?
                                                <ButtonWithLoadingOrIcon 
                                                    // isDisabled={validateForm()}
                                                    className="orange-button main-button-48"
                                                    text="Create Organization"
                                                />
                                                :
                                                <ButtonWithLoadingOrIcon 
                                                    isLoading
                                                    loadingColor="gray"
                                                    isDisabled
                                                    className="orange-button main-button-48"
                                                    text="Create Organization"
                                                    position="left"
                                                />
                                            }
                                        </div> */}
                                        </form>
                                    </div>
                                }
                            </React.Fragment>
                        }
                    </SectionWrap>
                </div>
            </React.Fragment>
        );
    }

    const mapDispatchToProps = {
        setParentMenuBlockActive,
        setAuthActive
    };

    const mapStateToProps = state => ({
        countryList: state.countryList,
        user: state.user
    });

    export default connect(mapStateToProps, mapDispatchToProps)(OrganizationBilling)
